import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import Layout from 'components/Layout/Layout';
import {
    ADD_DOCUMENT,
    ADD_GRANT,
    EDIT_DOCUMENT,
    HOME_PAGE,
    LOGIN_PAGE,
    REGISTER_PAGE,
    RESET_NEW_PASSWORD,
    RESET_PASSWORD,
    SETTINGS_PAGE,
} from 'consts/routes';
import {
    LoginPage,
    RegisterPage,
    ResetPasswordNewPasswordPage,
    ResetPasswordPage,
} from 'pages/AuthStack';
import { DocumentsListPage } from 'pages/HomeStack';
import { SettingsPage } from 'pages/SettingsStack';
import { auth } from './configs/firebase';
import { AnonymousRoute, PrivateRoute } from './components';
import { Permission } from './utils/interfaces/permissions';
import { actions as authActions } from './redux/slices/auth';
import { useDispatch } from './redux/store';
import AddDocumentPage from './pages/HomeStack/AddDocumentPage/AddDocumentPage';
import EditDocumentPage from './pages/HomeStack/EditDocumentPage/EditDocumentPage';
import AddGrantPage from './pages/HomeStack/AddGrantPage/AddGrantPage';
import { Institution } from 'utils/interfaces/institution';

const App: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        onAuthStateChanged(auth, (userFirebase) => {
            if (userFirebase) {
                dispatch(authActions.getUser())
                    .unwrap()
                    .catch((error: any) => console.error(error));
            } else {
                dispatch(authActions.clearGetUser());
            }
        });
    }, []);

    return (
        <div className="App">
            <Routes>
                <Route element={<Layout />}>
                    <Route
                        path={LOGIN_PAGE}
                        element={<AnonymousRoute fallback={HOME_PAGE} component={<LoginPage />} />}
                    />
                    <Route
                        path={REGISTER_PAGE}
                        element={
                            <AnonymousRoute fallback={HOME_PAGE} component={<RegisterPage />} />
                        }
                    />
                    <Route
                        path={RESET_PASSWORD}
                        element={
                            <AnonymousRoute
                                fallback={HOME_PAGE}
                                component={<ResetPasswordPage />}
                            />
                        }
                    />
                    <Route
                        path={RESET_NEW_PASSWORD}
                        element={
                            <AnonymousRoute
                                fallback={HOME_PAGE}
                                component={<ResetPasswordNewPasswordPage />}
                            />
                        }
                    />
                    <Route
                        path={HOME_PAGE}
                        element={
                            <PrivateRoute
                                permission={Permission.DOCUMENT_VIEW}
                                fallback={LOGIN_PAGE}
                                component={<DocumentsListPage />}
                            />
                        }
                    />
                    <Route
                        path={SETTINGS_PAGE}
                        element={
                            <PrivateRoute
                                permission={Permission.SETTINGS_VIEW}
                                fallback={LOGIN_PAGE}
                                component={<SettingsPage />}
                            />
                        }
                    />
                    <Route
                        path={ADD_DOCUMENT}
                        element={
                            <PrivateRoute
                                permission={Permission.ADD_DOCUMENT}
                                fallback={LOGIN_PAGE}
                                component={<AddDocumentPage />}
                            />
                        }
                    />
                    <Route
                        path={EDIT_DOCUMENT(':institutionId' as Institution['id'], ':id')}
                        element={
                            <PrivateRoute
                                permission={Permission.UPDATE_DOCUMENT}
                                fallback={LOGIN_PAGE}
                                component={<EditDocumentPage />}
                            />
                        }
                    />
                    <Route
                        path={ADD_GRANT}
                        element={
                            <PrivateRoute
                                permission={Permission.ADD_DOCUMENT}
                                fallback={LOGIN_PAGE}
                                component={<AddGrantPage />}
                            />
                        }
                    />
                </Route>
            </Routes>
        </div>
    );
};

export default App;
