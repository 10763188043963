import React from 'react';
import { useAppTranslation } from 'utils/hooks/translation';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SETTINGS_PAGE } from 'consts/routes';
import {
    StyledFirstAppButton,
    StyledMainStack,
    StyledMessageTypography,
    StyledModal,
    StyledSecondAppButton,
    StyledTitleTypography,
} from './RequestPaymentDialog.styled';

export type RequestPaymentDialogProps = {
    open?: boolean;
    onClose?: () => void;
};

export const RequestPaymentDialog = ({ open = true, onClose }: RequestPaymentDialogProps) => {
    const { t } = useAppTranslation('common');
    const navigate = useNavigate();

    const onCancel = () => {
        onClose?.();
    };

    const onConfirm = () => {
        navigate(SETTINGS_PAGE);
        onClose?.();
    };

    return (
        <StyledModal open={open} onClose={onClose}>
            <StyledMainStack>
                <StyledTitleTypography whiteSpace="pre-line" variant="h4">
                    {t('restriction.functionAvailableAfterAcquiringALicense')}
                </StyledTitleTypography>
                <StyledMessageTypography>
                    {t('restriction.enjoyLimitlessAccessAfterAcquiring')}
                </StyledMessageTypography>
                <Stack flexDirection="row" width="100%" justifyContent="space-between">
                    <StyledFirstAppButton onClick={onCancel} variant="outlined">
                        {t('button.cancel')}
                    </StyledFirstAppButton>
                    <StyledSecondAppButton onClick={onConfirm}>
                        {t('button.acquire')}
                    </StyledSecondAppButton>
                </Stack>
            </StyledMainStack>
        </StyledModal>
    );
};
