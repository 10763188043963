import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { DOCUMENTS_LIST_SALE_DATE_FORMAT } from 'utils/functions/dates';

type Props = {
    name: string;
    label: string;
    disabled?: boolean;
    error: boolean;
    helperText: string;
} & Pick<React.ComponentProps<typeof Controller>, 'rules' | 'shouldUnregister' | 'defaultValue'>;
const Datepicker = ({
    name,
    label,
    rules,
    defaultValue = null,
    shouldUnregister,
    disabled,
    error,
    helperText,
}: Props) => {
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            shouldUnregister={shouldUnregister}
            name={name}
            defaultValue={defaultValue}
            rules={rules}
            render={({ field }) => (
                <DesktopDatePicker
                    label={label}
                    inputFormat={DOCUMENTS_LIST_SALE_DATE_FORMAT}
                    disabled={disabled}
                    {...field}
                    renderInput={(props) => (
                        <TextField {...props} error={error && !disabled} helperText={helperText} />
                    )}
                />
            )}
        />
    );
};

export { Datepicker };
