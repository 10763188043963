import React from 'react';
import { Alert, AlertColor, AlertTitle, Snackbar, SnackbarProps } from '@mui/material';

interface AppSnackbarProps extends SnackbarProps {
    onClose?: () => void;
    autoHideDuration?: number;
    type?: AlertColor;
    message?: string;
    title?: string;
}

const AppSnackbar = ({
    onClose,
    autoHideDuration = 3000,
    type = 'info',
    message,
    title,
    ...props
}: AppSnackbarProps) => (
    <Snackbar
        autoHideDuration={autoHideDuration}
        onClose={onClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        {...props}
    >
        <Alert onClose={onClose} severity={type} sx={{ width: '100%' }}>
            <AlertTitle>{title}</AlertTitle>
            {message}
        </Alert>
    </Snackbar>
);

export { AppSnackbar };
