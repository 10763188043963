import { PayloadAction } from '@reduxjs/toolkit';
import { GrantErrorPayload } from 'services/HTTPService/grants/errors';
import { ResponseWithError } from 'services/HTTPService/types';
import type { SettlementState } from '../state';

export const initGrantRequest = (state: SettlementState) => {
    state.grantRequestState = { loading: true, error: undefined };
};

export const clearGrantRequest = (state: SettlementState) => {
    state.grantRequestState = { loading: false };
};

export const grantRequestSuccess = (state: SettlementState) => {
    state.grantRequestState = { loading: false, success: true, error: undefined };
};

export const grantRequestError = (
    state: SettlementState,
    action: PayloadAction<ResponseWithError<GrantErrorPayload>>,
) => {
    state.grantRequestState = { loading: false, success: false, error: action.payload };
};
