import { PayloadAction } from '@reduxjs/toolkit';
import { mapDtoToInstitution } from 'redux/slices/settlement/mappers/institution';
import { SignIn } from 'services/HTTPService/auth/responses';
import { UserDetailsType } from 'utils/interfaces/auth';
import { AuthState } from '../state';

export const initGetUser = (state: AuthState) => {
    state.getUserState = { loading: true };
};

export const clearGetUser = (state: AuthState) => {
    state.getUserState = { loading: false };
};

export const getUserSuccess = (state: AuthState, action: PayloadAction<SignIn>) => {
    const {
        payload: user,
        payload: { assignedInstitutions },
    } = action;
    const institutions = assignedInstitutions.map(mapDtoToInstitution);

    state.getUserState = { loading: false, success: true };
    state.user = { ...user, assignedInstitutions: institutions };
};

export const getUserError = (state: AuthState) => {
    state.getUserState = { loading: false, success: false };
};

export const setUser = (state: AuthState, action: PayloadAction<UserDetailsType>) => {
    state.user = action.payload;
};
