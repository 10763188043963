import React, { useCallback, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'utils/hooks';
import {
    ADD_DOCUMENT,
    ADD_GRANT,
    HOME_PAGE,
    LOGIN_PAGE,
    REGISTER_PAGE,
    SETTINGS_PAGE_WITH_DEFAULT_TAB,
} from 'consts/routes';

import AppNavbar from './AppNavbar/AppNavbar';
import AuthNavbar from './AuthNavbar/AuthNavbar';

const LayoutNavbar = () => {
    const { logOut, user } = useAuth();
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

    const isMobile = useMediaQuery('(max-width:1100px)');

    const openNavMenu = (event: React.MouseEvent<HTMLElement>) =>
        setAnchorElNav(event.currentTarget);
    const closeNavMenu = () => setAnchorElNav(null);

    const logoutButtonOnClick = () => logOut();

    const goToHomePage = useCallback(() => navigate(HOME_PAGE), [navigate]);
    const goToSettingsPage = useCallback(
        () => navigate(SETTINGS_PAGE_WITH_DEFAULT_TAB),
        [navigate],
    );

    const onLoginButtonClick = useCallback(() => {
        isMobile && closeNavMenu();
        navigate(LOGIN_PAGE);
    }, [isMobile, navigate]);

    const onRegisterButtonClick = useCallback(() => {
        isMobile && closeNavMenu();
        navigate(REGISTER_PAGE);
    }, [isMobile, navigate]);

    const onAddGrantButtonClick = () => navigate(ADD_GRANT);
    const onSendDocumentButtonClick = () => navigate(ADD_DOCUMENT);

    const onAboutProductButtonClick = () => {
        isMobile && closeNavMenu();
    };
    const onPaymentsButtonClick = () => {
        isMobile && closeNavMenu();
    };
    const onContactButtonClick = () => {
        isMobile && closeNavMenu();
    };

    return user ? (
        <AppNavbar
            goToHomePage={goToHomePage}
            goToSettingsPage={goToSettingsPage}
            onAddGrantButtonClick={onAddGrantButtonClick}
            onSendDocumentButtonClick={onSendDocumentButtonClick}
            logoutButtonOnClick={logoutButtonOnClick}
        />
    ) : (
        <AuthNavbar
            onAboutProductButtonClick={onAboutProductButtonClick}
            onPaymentsButtonClick={onPaymentsButtonClick}
            onContactButtonClick={onContactButtonClick}
            onLoginButtonClick={onLoginButtonClick}
            onRegisterButtonClick={onRegisterButtonClick}
            isMobile={isMobile}
            anchorElNav={anchorElNav}
            openNavMenu={openNavMenu}
            closeNavMenu={closeNavMenu}
        />
    );
};

export default LayoutNavbar;
