import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { Menu, MenuItem, Typography } from '@mui/material';
import { ELFLogo } from 'assets/icons';
import { useAppTranslation } from 'utils/hooks/translation';
import {
    LANDING_PAGE_CONTACT,
    LANDING_PAGE_HERO,
    LANDING_PAGE_PAYMENTS,
    LANDING_URL,
} from 'consts/links';
import {
    Logo,
    MobileAuthNavbarRow,
    MobileLogoIconContainer,
    MobileMenuButton,
} from '../../LayoutNavbarStyled';

type MobileAuthNavbarProps = {
    onAboutProductButtonClick?: () => void;
    onPaymentsButtonClick?: () => void;
    onContactButtonClick?: () => void;
    onLoginButtonClick?: () => void;
    onRegisterButtonClick?: () => void;
    openNavMenu: (event: React.MouseEvent<HTMLElement>) => void;
    anchorElNav: null | HTMLElement;
    closeNavMenu: () => void;
};
const MobileAuthNavbar = ({
    onAboutProductButtonClick,
    onPaymentsButtonClick,
    onContactButtonClick,
    onLoginButtonClick,
    onRegisterButtonClick,
    openNavMenu,
    anchorElNav,
    closeNavMenu,
}: MobileAuthNavbarProps) => {
    const { t: commonT } = useAppTranslation('common');
    const commonLayoutT = (itemName: string) => commonT(`layout.${itemName}`);

    return (
        <MobileAuthNavbarRow>
            <a href={LANDING_URL}>
                <MobileLogoIconContainer>
                    <Logo>
                        <img src={ELFLogo} alt="logo" />
                    </Logo>
                </MobileLogoIconContainer>
            </a>

            <MobileMenuButton size="large" color="inherit" onClick={openNavMenu}>
                <MenuIcon />
            </MobileMenuButton>

            <Menu
                anchorEl={anchorElNav}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElNav)}
                onClose={closeNavMenu}
                componentsProps={{
                    backdrop: { style: { backgroundColor: 'transparent' } },
                }}
            >
                <MenuItem
                    component="a"
                    href={LANDING_PAGE_HERO}
                    onClick={onAboutProductButtonClick}
                >
                    <Typography textAlign="center">{commonLayoutT('aboutProduct')}</Typography>
                </MenuItem>
                <MenuItem
                    component="a"
                    href={LANDING_PAGE_PAYMENTS}
                    onClick={onPaymentsButtonClick}
                >
                    <Typography textAlign="center">{commonLayoutT('payments')}</Typography>
                </MenuItem>
                <MenuItem component="a" href={LANDING_PAGE_CONTACT} onClick={onContactButtonClick}>
                    <Typography textAlign="center">{commonLayoutT('contact')}</Typography>
                </MenuItem>
                <MenuItem onClick={onLoginButtonClick}>
                    <Typography textAlign="center">{commonLayoutT('login')}</Typography>
                </MenuItem>
                <MenuItem onClick={onRegisterButtonClick}>
                    <Typography textAlign="center">{commonLayoutT('register')}</Typography>
                </MenuItem>
            </Menu>
        </MobileAuthNavbarRow>
    );
};
export default MobileAuthNavbar;
