import React from 'react';
import { Stack, styled } from '@mui/material';

const StyledBox = styled(Stack)`
    gap: 35px;
    background-color: ${({ theme }) => `${theme.palette.primary.light}`};
    padding: 30px 21px;
    width: 100%;
`;

type Props = {
    children: React.ReactNode;
};

const Container = ({ children }: Props) => <StyledBox>{children}</StyledBox>;

export { Container };
