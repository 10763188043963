import React, { useEffect, useState } from 'react';
import { FormControl, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FormProvider, useForm } from 'react-hook-form';
import { BackdropAppLoader, Input, Modal, PasswordInput, Select } from 'components/common';
import { useAppTranslation } from 'utils/hooks/translation';
import { useDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import { emailValidatorWithMessage, passwordValidator } from 'utils/functions/validators';
import { getInstitutionsSelectOptionsFromInstitutionsList } from 'utils/functions/options';
import { selectors as settingsSelectors } from 'redux/slices/settings';
import { actions as usersActions, selectors as usersSelectors } from 'redux/slices/users';
import { UserDetailsType } from 'utils/interfaces/auth';
import { UserModalVariant, UserPostData } from 'utils/interfaces/users';
import { StyledActionAppButton, StyledLineStack } from './UserModal.styled';
import ConfirmModal from 'components/common/ConfirmModal/ConfirmModal';
import { useSnackBar } from 'contexts/SnackbarContext/SnackbarContext';

type UserModalProps = {
    isOpen: boolean;
    onClose: () => void;
    type: UserModalVariant;
    userId?: UserDetailsType['id'];
};

const UserModal = ({ isOpen, onClose, type, userId }: UserModalProps) => {
    const dispatch = useDispatch();
    const { showSnackBar } = useSnackBar();
    const addUserState = useSelector(usersSelectors.selectAddUserState);
    const editUserState = useSelector(usersSelectors.selectEditUserState);
    const getSelectedUserState = useSelector(usersSelectors.selectGetSelectedUserState);
    const fullInstitutionList = useSelector(settingsSelectors.selectFullInstitutionsList);
    const selectedUser = useSelector(usersSelectors.selectSelectedUser);

    const [isDeleteUserConfirmModalOpen, setIsDeleteUserConfirmModalOpen] = useState(false);

    const institutionsWithoutValidLicence = fullInstitutionList
        ?.filter((institution) => institution?.licence?.licenceStatus !== 'VALID')
        .map((institution) => institution.id);

    const institutionsOptions = fullInstitutionList
        ? getInstitutionsSelectOptionsFromInstitutionsList(
              fullInstitutionList,
              institutionsWithoutValidLicence,
          )
        : [];

    const { t: settingsT } = useAppTranslation('settings');
    const { t: commonT } = useAppTranslation('common');

    const userFormT = (itemName: string) => settingsT(`userForm.${itemName}`);
    const usersListT = (itemName: string) => settingsT(`usersList.${itemName}`);

    const closeDeleteUserConfirmModal = () => setIsDeleteUserConfirmModalOpen(false);

    const form = useForm<UserPostData>({ defaultValues: { institutionIds: [] } });
    const {
        handleSubmit,
        formState: { errors },
        reset,
    } = form;

    const onCloseWithFormReset = () => {
        onClose();
        reset();
    };

    const onConfirmDeleteUserClick = () => {
        if (userId) {
            dispatch(usersActions.deleteUser(userId));
            closeDeleteUserConfirmModal();
            onClose();
        } else {
            dispatch(usersActions.deleteUserError('No userId'));
        }
    };

    const onDeleteUserButtonClick = () => {
        setIsDeleteUserConfirmModalOpen(true);
    };

    const getTitle = () => {
        switch (type) {
            case 'add':
                return userFormT('addUser');
            default:
                return userFormT('editUser');
        }
    };
    useEffect(() => {
        if (type === 'edit' && isOpen && userId) {
            dispatch(usersActions.getSelectedUser(userId));
        }
    }, [userId, isOpen]);

    useEffect(() => {
        if (type === 'edit' && isOpen && getSelectedUserState.success && selectedUser) {
            const selectedUserInstitutionIdsIncludedInOptions = selectedUser.assignedInstitutions
                ?.map((institution) => institution.id)
                .filter((institutionId) =>
                    institutionsOptions.some((option) => option.value === institutionId),
                );
            reset({
                ...selectedUser,
                institutionIds: selectedUserInstitutionIdsIncludedInOptions || [],
            });
            dispatch(usersActions.clearGetSelectedUser());
        }
    }, [getSelectedUserState, selectedUser, isOpen, type]);

    useEffect(() => {
        if (addUserState.success) {
            showSnackBar(
                commonT('snackbar.success'),
                userFormT('successfullyAddedUser'),
                'success',
            );
            dispatch(usersActions.clearAddUser());
        } else if (addUserState.error) {
            showSnackBar(
                commonT('snackbar.errorOccured'),
                userFormT('errors.couldNotAddUser'),
                'error',
            );
            dispatch(usersActions.clearAddUser());
        }
    }, [addUserState]);

    useEffect(() => {
        if (editUserState.success) {
            showSnackBar(
                commonT('snackbar.success'),
                userFormT('successfullyEdittedUser'),
                'success',
            );
            dispatch(usersActions.clearEditUser());
        } else if (editUserState.error) {
            showSnackBar(
                commonT('snackbar.errorOccured'),
                userFormT('errors.couldNotEditUser'),
                'error',
            );
            dispatch(usersActions.clearEditUser());
        }
    }, [editUserState]);

    const onSubmit = (data: UserPostData) => {
        if (type === 'add') {
            dispatch(usersActions.addUser(data))
                .unwrap()
                .then(() => onCloseWithFormReset());
        } else if (type === 'edit' && !!userId) {
            dispatch(
                usersActions.editUser({
                    firstName: data.firstName,
                    institutionIds: data.institutionIds,
                    lastName: data.lastName,
                    id: userId,
                }),
            )
                .unwrap()
                .then(() => onCloseWithFormReset());
        }
    };
    return (
        <Modal
            open={isOpen}
            maxWidth="910px"
            body={
                <Stack flexDirection="column" width="100%">
                    <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
                        <Typography variant="h5" color="secondary">
                            {getTitle()}
                        </Typography>
                        <IconButton color="primary" onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>

                    <FormProvider {...form}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <StyledLineStack marginTop="23px" marginBottom="34px" />
                            <FormControl fullWidth>
                                <Stack gap="35px" width="100%">
                                    <Select
                                        label={userFormT('institutionsLabel')}
                                        rules={{
                                            required: commonT('form.required'),
                                        }}
                                        name="institutionIds"
                                        options={institutionsOptions}
                                        fullWidth
                                        multiple
                                        error={!!errors?.institutionIds}
                                        helperText={errors?.institutionIds?.message || ''}
                                    />
                                    <Input
                                        label={userFormT('firstNameLabel')}
                                        rules={{ required: commonT('form.required') }}
                                        name="firstName"
                                        fullWidth
                                        error={!!errors?.firstName}
                                        helperText={errors?.firstName?.message || ''}
                                    />
                                    <Input
                                        label={userFormT('lastNameLabel')}
                                        rules={{ required: commonT('form.required') }}
                                        name="lastName"
                                        fullWidth
                                        error={!!errors?.lastName}
                                        helperText={errors?.lastName?.message || ''}
                                    />
                                    <Input
                                        label={userFormT('emailLabel')}
                                        rules={{
                                            validate: {
                                                emailValidator: emailValidatorWithMessage(
                                                    userFormT('errors.emailValidation'),
                                                ),
                                            },
                                        }}
                                        name="email"
                                        fullWidth
                                        error={!!errors?.email}
                                        helperText={errors?.email?.message || ''}
                                        disabled={type === 'edit'}
                                    />
                                    {type === 'add' && (
                                        <PasswordInput
                                            rules={{
                                                validate: {
                                                    passwordValidator: passwordValidator(
                                                        userFormT('errors.passwordLength'),
                                                    ),
                                                },
                                            }}
                                            label={userFormT('passwordLabel')}
                                            name="password"
                                            fullWidth
                                            error={!!errors.password}
                                            helperText={errors?.password?.message || ''}
                                        />
                                    )}
                                </Stack>
                            </FormControl>
                            <StyledLineStack marginTop="34px" marginBottom="17px" />
                            <Stack flexDirection="row" width="100%" justifyContent="space-between">
                                <StyledActionAppButton type="submit">
                                    {userFormT('save')}
                                </StyledActionAppButton>
                                {type === 'edit' && (
                                    <StyledActionAppButton
                                        variant="outlined"
                                        onClick={onDeleteUserButtonClick}
                                    >
                                        {userFormT('deleteUser')}
                                    </StyledActionAppButton>
                                )}
                            </Stack>
                        </form>
                    </FormProvider>
                    <ConfirmModal
                        open={isDeleteUserConfirmModalOpen}
                        onClose={closeDeleteUserConfirmModal}
                        title={usersListT('areYouSureYouWantToDeleteUser')}
                        firstButtonLabel={usersListT('cancel')}
                        secondButtonLabel={usersListT('yesDelete')}
                        onFirstButtonClick={closeDeleteUserConfirmModal}
                        onSecondButtonClick={onConfirmDeleteUserClick}
                    />
                    <BackdropAppLoader
                        open={
                            addUserState.loading ||
                            editUserState.loading ||
                            getSelectedUserState.loading
                        }
                    />
                </Stack>
            }
            actionButtons={<div />}
        />
    );
};

export default UserModal;
