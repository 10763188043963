import { Expense, ExpenseType } from 'utils/interfaces/document';

export const getTotalExpensesFromExpenseDtos = (expenseDtos: any) =>
    expenseDtos
        ? Object.values<Expense>(expenseDtos).reduce(
              (prev, curr) =>
                  Number.isNaN(curr.grossAmount) ? prev : prev + Number(curr.grossAmount),
              0,
          )
        : 0;

export const sortExpensesByName = (expense1: ExpenseType, expense2: ExpenseType) =>
    expense1.name.localeCompare(expense2.name);
