import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Container, FormControl, Stack, Typography, styled } from '@mui/material';
import { useDispatch } from 'redux/store';
import { useAppTranslation } from 'utils/hooks/translation';
import { useAuth } from 'utils/hooks';
import Layout from 'components/Layout/Layout';
import { AppButton, FormError, PasswordInput, BackdropAppLoader } from 'components';
import { passwordValidator } from 'utils/functions/validators';
import { actions, selectors } from 'redux/slices/auth';
import { useErrorTranslation } from 'utils/functions/error';
import { useSnackBar } from 'contexts/SnackbarContext/SnackbarContext';

const StyledContainer = styled(Container)`
    margin-top: 80px;
    margin-bottom: 100px;
`;

const ResetPasswordPage = () => {
    const { changePassword, backendError } = useAuth();
    const { showSnackBar } = useSnackBar();
    const { t: authT } = useAppTranslation('auth');
    const { t: commonT } = useAppTranslation('common');
    const resetPasswordState = useSelector(selectors.selectResetPasswordState);
    const { translateError } = useErrorTranslation('auth:resetNewPasswordPage');
    const dispatch = useDispatch();

    const form = useForm();
    const {
        handleSubmit,
        formState: { errors },
        watch,
    } = form;
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (resetPasswordState.success) {
            showSnackBar(
                commonT('snackbar.success'),
                authT('resetNewPasswordPage.success'),
                'success',
            );
            dispatch(actions.clearResetPassword());
        }
    }, [resetPasswordState]);

    const onChangePassword = (data: any) => {
        changePassword({ ...data, oobCode: searchParams.get('oobCode') });
    };

    const watchPassword = watch('password');

    return (
        <StyledContainer>
            <BackdropAppLoader open={resetPasswordState.loading} />
            <Stack alignItems="center">
                <Typography variant="h2" gutterBottom>
                    {authT('resetNewPasswordPage.title')}
                </Typography>
                <Typography
                    variant="h5"
                    marginBottom="16px"
                    textAlign="center"
                    whiteSpace="pre-line"
                    color={(theme) => theme.palette.text.secondary}
                >
                    {authT('resetNewPasswordPage.subtitle')}
                </Typography>
                <FormProvider {...form}>
                    <FormControl fullWidth>
                        <form onSubmit={handleSubmit(onChangePassword)}>
                            <Stack gap="19px" marginTop="34px" marginBottom="20px">
                                <Layout.Container>
                                    <PasswordInput
                                        rules={{
                                            validate: {
                                                passwordValidator: passwordValidator(
                                                    authT(
                                                        'resetNewPasswordPage.errors.passwordLength',
                                                    ),
                                                ),
                                            },
                                        }}
                                        label={authT('resetNewPasswordPage.password')}
                                        name="password"
                                        fullWidth
                                        error={!!errors.password}
                                        helperText={(errors?.password?.message || '') as string}
                                    />
                                    <Stack direction="row" gap="8px" marginTop="-18px">
                                        <CheckCircleIcon
                                            fontSize="small"
                                            color={
                                                passwordValidator()(watchPassword || '')
                                                    ? 'primary'
                                                    : 'disabled'
                                            }
                                        />
                                        <Typography variant="body2">
                                            {authT('resetNewPasswordPage.eightCharacters')}
                                        </Typography>
                                    </Stack>
                                    <FormError error={translateError(backendError as string)} />
                                </Layout.Container>
                                <AppButton type="submit" fullWidth>
                                    {commonT('button.save')}
                                </AppButton>
                            </Stack>
                        </form>
                    </FormControl>
                </FormProvider>
            </Stack>
        </StyledContainer>
    );
};

export default ResetPasswordPage;
