import { createAsyncThunk } from '@reduxjs/toolkit';
import { logAxiosError } from 'utils/errors';
import { actions } from '../index';
import { GenerateReportDto, ReportType } from 'utils/interfaces/report';
import HTTPService from 'services/HTTPService/HTTPService';
import { instantDownloadFileFromBlob } from 'utils/functions/download';
import { csvStringToBlob, pdfStringToBlob } from 'utils/functions/converters/csvStringToBlob';
import { RootState } from 'redux/store';

const generateReport = createAsyncThunk<
    void,
    GenerateReportDto & { type: ReportType },
    { state: RootState }
>('reports/generateReport', async (payload, thunkApi) => {
    try {
        const { type, ...report } = payload;
        thunkApi.dispatch(actions.initGenerateReport());

        const currentState = thunkApi.getState();
        const institutionName = currentState.auth.user?.assignedInstitutions?.find(
            (institution) => institution.id === report.institutionId,
        )?.name;

        const { data } = await HTTPService.generateReport(report, type);

        instantDownloadFileFromBlob(
            type === 'csv' ? csvStringToBlob(data) : pdfStringToBlob(data),
            `report_${institutionName}_${report.startDate}-${report.endDate}.${type}`,
        );

        thunkApi.dispatch(actions.generateReportSuccess());
    } catch (error: any) {
        logAxiosError(error);
        thunkApi.dispatch(actions.generateReportError(error.response?.data || 'unknown error'));
        throw error;
    }
});

export { generateReport };
