import { styled, Typography } from '@mui/material';

export const StyledTableCellTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
    letterSpacing: '-0.4px',
    lineHeight: '22px',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'DM Sans',
    marginRight: '13px',
}));

export const StyledTableCellNameTypography = styled(StyledTableCellTypography)({
    fontWeight: 700,
    marginLeft: '20px',
    marginRight: '5px',
});
