import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { getTodayDate } from 'utils/functions/dates';
import * as reducers from './reducers';
import { AppState } from './state';

type Reducers = typeof reducers;

export const appSliceName = 'app';

const initialState: AppState = {
    date: getTodayDate(),
};

const slice = createSlice<AppState, Reducers & { reset: () => AppState }>({
    name: appSliceName,
    initialState,
    reducers: { ...reducers, reset: () => initialState },
});

export const { actions, reducer } = slice;

export const selectState = (state: RootState) => state[appSliceName];
