import { createSelector } from '@reduxjs/toolkit';
import { selectState } from '../slice';
import { UsersState } from '../state';

const selectAddUserState = createSelector(selectState, (state: UsersState) => state.addUserState);
const selectEditUserState = createSelector(selectState, (state: UsersState) => state.editUserState);
const selectDeleteUserState = createSelector(
    selectState,
    (state: UsersState) => state.deleteUserState,
);

const selectDeleteUserFromInstitutionState = createSelector(
    selectState,
    (state: UsersState) => state.deleteUserFromInstitutionState,
);

export {
    selectAddUserState,
    selectEditUserState,
    selectDeleteUserState,
    selectDeleteUserFromInstitutionState,
};
