import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete as MuiAutocomplete, TextField } from '@mui/material';
import { SelectOptions } from 'utils/interfaces/common';

type Props = {
    name: string;
    label: React.ReactNode;
    options: SelectOptions;
    rules?: any;
    error?: boolean;
    helperText?: string;
} & Pick<React.ComponentProps<typeof Controller>, 'rules' | 'shouldUnregister' | 'defaultValue'>;
const Autocomplete: React.FC<Props> = ({
    name,
    label,
    options,
    rules,
    shouldUnregister,
    defaultValue,
}) => {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            shouldUnregister={shouldUnregister}
            render={({ field: { value, onChange, ...fieldProps }, fieldState }) => (
                <MuiAutocomplete
                    value={value || ''}
                    {...fieldProps}
                    freeSolo
                    onChange={(event, data) => onChange(data || '')}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            onChange={(event) => onChange(event.target.value)}
                            label={label}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                        />
                    )}
                    options={options.map((option) => option.name)}
                />
            )}
        />
    );
};

export { Autocomplete };
