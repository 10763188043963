import { Modal, Stack, styled, Typography } from '@mui/material';
import { AppButton } from 'components/common';
import { colors } from 'consts/colors';

export const StyledModal = styled(Modal)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const StyledMainStack = styled(Stack)(() => ({
    backgroundColor: 'white',
    maxWidth: '600px',
    borderRadius: '4px',
    padding: '32px',
    alignItems: 'center',
}));

export const StyledTitleTypography = styled(Typography)(() => ({
    color: colors.secondary,
    marginBottom: '16px',
    textAlign: 'center',
    width: '320px',
}));

export const StyledMessageTypography = styled(Typography)(() => ({
    color: colors.text3,
    fontFamily: 'DM Sans',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    marginBottom: '46px',
}));

export const StyledSecondAppButton = styled(AppButton)(() => ({
    flex: 1,
    marginLeft: '8px',
}));

export const StyledFirstAppButton = styled(AppButton)(() => ({
    flex: 1,
    marginRight: '8px',
}));
