import { PayloadAction } from '@reduxjs/toolkit';
import { SettlementState } from '../state';

export const initDeleteDocument = (state: SettlementState) => {
    state.deleteDocumentState = { loading: true };
};

export const clearDeleteDocument = (state: SettlementState) => {
    state.deleteDocumentState = { loading: false };
};

export const deleteDocumentSuccess = (state: SettlementState) => {
    state.deleteDocumentState = { loading: false, success: true };
};

export const deleteDocumentError = (state: SettlementState, action: PayloadAction<string>) => {
    state.deleteDocumentState = { loading: false, success: false, error: action.payload };
};
