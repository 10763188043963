import React, { useEffect, useState } from 'react';
import {
    CircularProgress,
    Container,
    IconButton,
    MenuItem,
    Select,
    Stack,
    styled,
    Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { getHexColorWithOpacity } from 'utils/functions/colors';
import { useDispatch } from 'redux/store';
import ValueCard from 'components/ValueCard/ValueCard';
import { MONTHS } from 'consts/date';
import { useAppTranslation } from 'utils/hooks/translation';
import { useSelector } from 'react-redux';
import {
    actions as settlementActions,
    actions,
    selectors as settlementSelectors,
} from 'redux/slices/settlement';
import { selectors as userSelectors } from 'redux/slices/auth';
import { actions as appActions, selectors as appSelectors } from 'redux/slices/app';
import { getYearsArrayForSelect } from 'utils/functions/dates';
import HTTPService from 'services/HTTPService/HTTPService';
import { BudgetSummaryDto } from 'utils/interfaces/budget';
import {
    selectDocumentsCostsList,
    selectDocumentsCostsListTotalElements,
} from 'redux/slices/settlement/selectors';
import DocumentsCostsListTable from 'components/DocumentsList/DocumentsCostsListTable/DocumentsCostsListTable';
import DocumentsCategoriesFilters from 'components/DocumentsList/DocumentsCategoriesFilter/DocumentsCategoriesFilter';
import { SortData } from 'utils/interfaces/common';

const DEFAULT_EXPENSE_TYPE_VALUE = '';

const StyledMainStack = styled(Stack)(({ theme }) => ({
    backgroundColor: getHexColorWithOpacity(theme.palette.text.secondary, '0D'),
    marginTop: '8px',
    paddingTop: '34px',
    paddingBottom: '34px',
    paddingLeft: '24px',
    paddingRight: '24px',
}));

const StyledSelect = styled(Select)(({ theme }) => ({
    backgroundColor: 'none',
    fontFamily: 'DM Sans',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
    color: theme.palette.primary.main,
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
    },
}));

const ExpenseTypeSelect = styled(StyledSelect)`
    max-width: 300px;

    &:has(button),
    &:has(.MuiCircularProgress-root) {
        margin-right: 10px;

        .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
            padding-right: 0;
        }
    }
`;

const yearsOptions = getYearsArrayForSelect();

const rowsPerPageOptions = [10, 25, 50];

const DocumentsListPage = () => {
    const dispatch = useDispatch();
    const { t: settlementT } = useAppTranslation('settlement');
    const docsT = (itemName: string) => settlementT(`documentsList.${itemName}`);

    const selectedInstitution = useSelector(settlementSelectors.selectSelectedInstitution);
    const user = useSelector(userSelectors.selectUser);
    const userInstitutions = user?.assignedInstitutions || [];
    const [budgetSummary, setBudgetSummary] = useState<BudgetSummaryDto | null>(null);
    const documentsCostsList = useSelector(selectDocumentsCostsList);
    const documentsCostsListTotalElements = useSelector(selectDocumentsCostsListTotalElements);
    const { loading: isGetExpenseTypeListLoading } = useSelector(
        settlementSelectors.selectGetExpenseTypeListState,
    );
    const allExpenseTypeList = useSelector(settlementSelectors.selectAllExpenseTypeList);
    const selectedExpenseType = useSelector(settlementSelectors.selectSelectedExpenseType);

    const selectedDate = useSelector(appSelectors.selectDate);

    const selectedDocumentsCostsFilters = useSelector(
        settlementSelectors.selectDocumentsCostsFilters,
    );

    const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageOptions[0]);
    const [page, setPage] = useState<number>(0);
    const [sortData, setSortData] = useState<SortData>({ columnKey: 'saleDate', direction: 'asc' });

    const onRowsPerPageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(Number(e.target.value));
        setPage(0);
    };

    const onPageChange = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const onInstitutionSelectChange = (event: any) => {
        dispatch(settlementActions.setSelectedInstitution(event.target.value));
    };
    const onMonthSelectChange = (event: any) => {
        dispatch(appActions.setMonth(event.target.value));
    };
    const onYearSelectChange = (event: any) => {
        dispatch(appActions.setYear(event.target.value));
    };

    const onSortClick = (columnKey: string) => {
        const isAsc = sortData?.columnKey === columnKey && sortData.direction === 'asc';
        setSortData({ columnKey, direction: isAsc ? 'desc' : 'asc' });
    };

    const onExpenseTypeSelectChange = (event: any) => {
        dispatch(settlementActions.setSelectedExpenseType(event.target.value));
    };

    const onExpenseTypeSelectClear = () => {
        dispatch(settlementActions.setSelectedExpenseType(DEFAULT_EXPENSE_TYPE_VALUE));
    };

    const renderExpenseTypeValue = (selectedExpenseTypeName: unknown) => {
        if (!selectedExpenseTypeName) {
            return docsT('selectExpenseType');
        }
        return allExpenseTypeList?.find((expenseType) => expenseType === selectedExpenseTypeName);
    };

    useEffect(() => {
        if (!selectedInstitution) {
            dispatch(settlementActions.setSelectedInstitution(userInstitutions[0].id));
        }
    }, []);

    useEffect(() => {
        if (!allExpenseTypeList && selectedInstitution) {
            dispatch(actions.getAllExpenseTypeList(selectedInstitution));
        }
    }, [dispatch, allExpenseTypeList, selectedInstitution]);

    useEffect(() => {
        if (selectedInstitution) {
            const { subcategories: selectedSubcategories, value: selectedCategory } =
                selectedDocumentsCostsFilters.grantCategory;
            dispatch(
                settlementActions.fetchDocumentsCostsList({
                    institutionId: selectedInstitution,
                    month: parseInt(selectedDate.month, 10),
                    year: parseInt(selectedDate.year, 10),
                    expenseType: selectedExpenseType || undefined,
                    categories:
                        selectedSubcategories.length > 0
                            ? selectedSubcategories
                            : [selectedCategory],
                    page: page,
                    size: rowsPerPage,
                    sortData: sortData,
                }),
            );
        }
    }, [
        dispatch,
        selectedInstitution,
        selectedDate,
        page,
        rowsPerPage,
        selectedDocumentsCostsFilters,
        sortData,
        selectedExpenseType,
    ]);

    useEffect(() => {
        if (selectedInstitution) {
            const { subcategories: selectedSubcategories, value: selectedCategory } =
                selectedDocumentsCostsFilters.grantCategory;

            HTTPService.getBudgetSummary(selectedInstitution, {
                month: selectedDate.month,
                year: selectedDate.year,
                categories:
                    selectedSubcategories.length > 0 ? selectedSubcategories : [selectedCategory],
            }).then((response) => {
                setBudgetSummary(response.data);
            });
        }
    }, [dispatch, selectedInstitution, selectedDate, documentsCostsList]);

    return (
        <Container>
            <StyledMainStack>
                <Typography variant="h2">{docsT('documents')}</Typography>
                <Stack
                    flexDirection="row"
                    marginTop="24px"
                    marginBottom="24px"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <span>
                        <StyledSelect
                            value={selectedInstitution || ''}
                            onChange={onInstitutionSelectChange}
                            defaultValue={selectedInstitution}
                        >
                            {userInstitutions.map((institution) => (
                                <MenuItem value={institution.id} key={institution.id}>
                                    {institution.name}
                                </MenuItem>
                            ))}
                        </StyledSelect>
                        <StyledSelect value={selectedDate?.month} onChange={onMonthSelectChange}>
                            {MONTHS.map((month) => (
                                <MenuItem value={month.value} key={month.value}>
                                    {month.name}
                                </MenuItem>
                            ))}
                        </StyledSelect>
                        <StyledSelect value={selectedDate?.year} onChange={onYearSelectChange}>
                            {yearsOptions.map((year) => (
                                <MenuItem value={year.value} key={year.value}>
                                    {year.name}
                                </MenuItem>
                            ))}
                        </StyledSelect>
                    </span>
                    <Stack direction="row" alignItems="center">
                        <ExpenseTypeSelect
                            value={selectedExpenseType || DEFAULT_EXPENSE_TYPE_VALUE}
                            onChange={onExpenseTypeSelectChange}
                            renderValue={renderExpenseTypeValue}
                            displayEmpty
                            open={isGetExpenseTypeListLoading ? false : undefined}
                            IconComponent={
                                isGetExpenseTypeListLoading || selectedExpenseType
                                    ? // eslint-disable-next-line react/no-unstable-nested-components
                                      () => {
                                          if (isGetExpenseTypeListLoading) {
                                              return <CircularProgress size={20} />;
                                          }
                                          return (
                                              <IconButton
                                                  size="small"
                                                  onClick={onExpenseTypeSelectClear}
                                              >
                                                  <ClearIcon />
                                              </IconButton>
                                          );
                                      }
                                    : undefined
                            }
                        >
                            <MenuItem disabled>{docsT('selectExpenseType')}</MenuItem>
                            {allExpenseTypeList?.map((expenseType) => (
                                <MenuItem value={expenseType} key={expenseType}>
                                    {expenseType}
                                </MenuItem>
                            ))}
                        </ExpenseTypeSelect>
                        <DocumentsCategoriesFilters />
                    </Stack>
                </Stack>

                <Stack
                    flexDirection="row"
                    marginBottom="12px"
                    width="100%"
                    justifyContent="space-between"
                    gap="16px"
                >
                    <ValueCard label={docsT('granted')} value={budgetSummary?.totalGrantIncome} />
                    <ValueCard label={docsT('settled')} value={budgetSummary?.totalPaidCosts} />
                    <ValueCard
                        label={docsT('notSettled')}
                        value={budgetSummary?.totalUnpaidCosts}
                    />
                    <ValueCard label={docsT('remained')} value={budgetSummary?.remainingBudget} />
                </Stack>
                <DocumentsCostsListTable
                    rowsPerPageOptions={rowsPerPageOptions}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={onRowsPerPageChange}
                    onPageChange={onPageChange}
                    page={page}
                    count={documentsCostsListTotalElements}
                    sortData={sortData}
                    onSortClick={onSortClick}
                />
            </StyledMainStack>
        </Container>
    );
};

export default DocumentsListPage;
