import React from 'react';
import { ArrowBackIosNew } from '@mui/icons-material';
import { styled } from '@mui/material';
import { colors } from 'consts/colors';
import { AppButton, AppButtonProps } from '../AppButton';

interface BackButtonProps extends AppButtonProps {
    label?: string;
}
const StyledAppButton = styled(AppButton)({
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    textDecorationLine: 'underline',
    textDecorationColor: colors.mainText,
    color: colors.mainText,
    paddingRight: '10px',
    ':hover': {
        textDecorationLine: 'underline',
    },
});

const StyledArrowBackIosNew = styled(ArrowBackIosNew)({
    paddingTop: '5px',
    paddingBottom: '5px',
    marginRight: '10px',
});

const BackButton = ({ label, ...props }: BackButtonProps) => (
    <StyledAppButton variant="inline" {...props}>
        <StyledArrowBackIosNew />
        {label}
    </StyledAppButton>
);

export { BackButton };
