import { createSelector } from '@reduxjs/toolkit';
import { selectState } from '../slice';
import { SettlementState } from '../state';

const selectSelectedExpenseType = createSelector(
    selectState,
    (state: SettlementState) => state.selectedExpenseType,
);

export { selectSelectedExpenseType };
