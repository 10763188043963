import React from 'react';
import { Box, Modal as MuiModal, Stack, styled, Typography } from '@mui/material';
import { useAppTranslation } from 'utils/hooks/translation';
import { AppButton } from '../AppButton';
import { colors } from 'consts/colors';

const StyledModalBox = styled(Box)((props) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: props.theme.palette.background.paper,
    padding: '48px 32px',
    borderRadius: '4px',
}));

const Actions = styled(Stack)`
    width: 100%;
`;

type Props = {
    actionButtons?: React.ReactNode;
    title?: string;
    description?: string;
    understand?: string;
    open: boolean;
    onClose?: () => void;
    onClick?: () => void;
    maxWidth?: string;
    body?: React.ReactNode;
} & Omit<React.ComponentProps<typeof MuiModal>, 'children'>;

const Modal = ({
    description,
    open,
    title,
    understand,
    actionButtons,
    onClose,
    onClick,
    maxWidth = '400px',
    body,
}: Props) => {
    const { t } = useAppTranslation('common');
    return (
        <MuiModal open={open}>
            <StyledModalBox maxWidth={maxWidth} width="100%">
                <Stack alignItems="center">
                    {title && (
                        <Typography variant="h4" color={colors.secondary} marginBottom="16px">
                            {title}
                        </Typography>
                    )}
                    {description && (
                        <Typography textAlign="center" marginBottom="46px">
                            {description}
                        </Typography>
                    )}
                    {body}
                    <Actions direction="row" justifyContent="space-between" gap="20px">
                        {actionButtons ?? (
                            <>
                                <AppButton fullWidth variant="outlined" onClick={onClose}>
                                    {t('button.cancel')}
                                </AppButton>
                                <AppButton fullWidth variant="contained" onClick={onClick}>
                                    {understand || t('button.understand')}
                                </AppButton>
                            </>
                        )}
                    </Actions>
                </Stack>
            </StyledModalBox>
        </MuiModal>
    );
};

export { Modal };
