import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddressIcon, ELFLogoWhite, EmailIcon, PhoneIcon } from 'assets/icons';
import { contact } from 'consts/contact';
import { HOME_PAGE } from 'consts/routes';
import { useAppTranslation } from 'utils/hooks/translation';
import {
    LANDING_PAGE_HERO,
    LANDING_PAGE_PAYMENTS,
    PRIVACY_POLICY,
    TERMS_AND_CONDITIONS,
} from 'consts/links';
import {
    AddressColumn,
    IndentColumnText,
    CompanyColumnLink,
    ContactColumnIcon,
    ContactColumnRow,
    ContactColumnText,
    FooterColumn,
    FooterColumnHeader,
    FooterColumnLink,
    FooterContainer,
    FooterLogoIconContainer,
    Logo,
} from './layoutFooterStyled';

const LayoutFooter = () => {
    const { t: commonT } = useAppTranslation('common');

    const commonLayoutT = (itemName: string) => commonT(`layout.${itemName}`);

    const navigate = useNavigate();

    const goToHomePage = useCallback(() => navigate(HOME_PAGE), [navigate]);

    const onPrivacyPolicyLinkClick = () => {
        window.open(PRIVACY_POLICY, '_blank', 'noopener,noreferrer');
    };
    const onTermsOfConditionsLinkClick = () => {
        window.open(TERMS_AND_CONDITIONS, '_blank', 'noopener,noreferrer');
    };

    return (
        <FooterContainer>
            <FooterLogoIconContainer onClick={goToHomePage}>
                <Logo>
                    <img src={ELFLogoWhite} alt="logo" />
                </Logo>
            </FooterLogoIconContainer>

            <FooterColumn>
                <FooterColumnHeader variant="h5">{commonLayoutT('product')}</FooterColumnHeader>
                <FooterColumnLink variant="h5" underline="hover" href={LANDING_PAGE_PAYMENTS}>
                    {commonLayoutT('subscription')}
                </FooterColumnLink>
                <FooterColumnLink variant="h5" underline="hover" href={LANDING_PAGE_HERO}>
                    {commonLayoutT('aboutProduct')}
                </FooterColumnLink>
            </FooterColumn>

            <FooterColumn>
                <FooterColumnHeader variant="h5">{commonLayoutT('company')}</FooterColumnHeader>
                <CompanyColumnLink
                    variant="h5"
                    underline="hover"
                    onClick={onPrivacyPolicyLinkClick}
                >
                    {commonLayoutT('privacyPolicy')}
                </CompanyColumnLink>
                <CompanyColumnLink
                    variant="h5"
                    underline="hover"
                    onClick={onTermsOfConditionsLinkClick}
                >
                    {commonLayoutT('termsOfConditions')}
                </CompanyColumnLink>
            </FooterColumn>

            <FooterColumn>
                <FooterColumnHeader variant="h5">{commonLayoutT('contact')}</FooterColumnHeader>
                <ContactColumnRow>
                    <ContactColumnIcon>
                        <img src={AddressIcon} alt="" />
                    </ContactColumnIcon>
                    <AddressColumn>
                        <IndentColumnText variant="h5">{contact.COMPANY_NAME}</IndentColumnText>
                        <IndentColumnText variant="h5">{contact.ADDRESS}</IndentColumnText>
                        <IndentColumnText variant="h5">{`NIP: ${contact.NIP}`}</IndentColumnText>
                    </AddressColumn>
                </ContactColumnRow>
                <ContactColumnRow>
                    <ContactColumnIcon>
                        <img src={PhoneIcon} alt="" />
                    </ContactColumnIcon>
                    <AddressColumn>
                        <IndentColumnText variant="h5">{contact.PHONE}</IndentColumnText>
                        {contact.ADDITIONAL_PHONES.map((phone) => (
                            <IndentColumnText variant="h5" key={phone}>
                                {phone}
                            </IndentColumnText>
                        ))}
                    </AddressColumn>
                </ContactColumnRow>
                <ContactColumnRow>
                    <ContactColumnIcon>
                        <img src={EmailIcon} alt="" />
                    </ContactColumnIcon>
                    <ContactColumnText variant="h5">{contact.EMAIL}</ContactColumnText>
                </ContactColumnRow>
            </FooterColumn>
        </FooterContainer>
    );
};

export default LayoutFooter;
