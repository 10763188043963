import { styled, Typography } from '@mui/material';
import { colors } from 'consts/colors';

export const StyledValueAndStatusTableCellTypography = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'styledColor',
})((props: { styledColor?: string }) => ({
    color: props.styledColor || colors.green1,
    letterSpacing: '-0.4px',
    lineHeight: '22px',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'DM Sans',
}));
