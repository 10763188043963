import { PayloadAction } from '@reduxjs/toolkit';
import { SettlementState } from '../state';

export const initAddInstitutionLicense = (state: SettlementState) => {
    state.addInstitutionLicenseState = { loading: true };
};

export const addInstitutionLicenseSuccess = (state: SettlementState) => {
    state.addInstitutionLicenseState = { loading: false, success: true };
};

export const addInstitutionLicenseError = (
    state: SettlementState,
    action: PayloadAction<string>,
) => {
    state.addInstitutionLicenseState = { loading: false, success: false, error: action.payload };
};
