import { PayloadAction } from '@reduxjs/toolkit';
import { SettlementState } from '../state';

export const initDeleteExpenseType = (state: SettlementState) => {
    state.deleteExpenseTypeState = { loading: true };
};

export const clearDeleteExpenseType = (state: SettlementState) => {
    state.deleteExpenseTypeState = { loading: false };
};

export const deleteExpenseTypeSuccess = (state: SettlementState) => {
    state.deleteExpenseTypeState = { loading: false, success: true };
};

export const deleteExpenseTypeError = (state: SettlementState, action: PayloadAction<string>) => {
    state.deleteExpenseTypeState = { loading: false, success: false, error: action.payload };
};
