import { Stack, styled } from '@mui/material';
import { AppButton } from 'components/common';
import { colors } from 'consts/colors';

export const StyledLineStack = styled(Stack)({
    height: '2px',
    width: '100%',
    backgroundColor: colors.lightGray,
});

export const StyledActionAppButton = styled(AppButton)({
    paddingRight: '32px',
    paddingLeft: '32px',
});
