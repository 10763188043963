import { Stack, styled, Tabs } from '@mui/material';
import { getHexColorWithOpacity } from 'utils/functions/colors';

export const StyledMainStack = styled(Stack)(({ theme }) => ({
    backgroundColor: getHexColorWithOpacity(theme.palette.text.secondary, '0D'),
    borderRadius: '16px 16px 0 0',
    marginTop: '8px',
    paddingTop: '34px',
    paddingBottom: '34px',
    paddingLeft: '24px',
    paddingRight: '24px',
}));

export const StyledTabs = styled(Tabs)({
    marginTop: '24px',
    marginBottom: '29px',
});
