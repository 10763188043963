import { createAsyncThunk } from '@reduxjs/toolkit';
import { actions as appActions } from '../slice';
import { actions as authActions } from 'redux/slices/auth';
import { actions as reportsActions } from 'redux/slices/reports';
import { actions as settingsActions } from 'redux/slices/settings';
import { actions as settlementActions } from 'redux/slices/settlement';
import { actions as usersActions } from 'redux/slices/users';

const resetWholeStore = createAsyncThunk('app/resetStore', async (payload, thunkApi) => {
    thunkApi.dispatch(appActions.reset());
    thunkApi.dispatch(authActions.reset());
    thunkApi.dispatch(reportsActions.reset());
    thunkApi.dispatch(settingsActions.reset());
    thunkApi.dispatch(settlementActions.reset());
    thunkApi.dispatch(usersActions.reset());
});

export { resetWholeStore };
