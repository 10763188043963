import { createAsyncThunk } from '@reduxjs/toolkit';
import { logAxiosError } from 'utils/errors';
import HTTPService from 'services/HTTPService/HTTPService';
import { DocType, DocTypeFromForm, DocumentId } from 'utils/interfaces/document';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { RootState } from 'redux/store';
import { GetDocumentsCostsParameters } from 'services/HTTPService/documents/parameters';
import { Institution } from 'utils/interfaces/institution';
import { actions } from '../index';
import { mapDocCostDtoToDocCost, mapDocumentToDto, mapDtoToDocument } from '../mappers/documents';

dayjs.extend(customParseFormat);

const addDocument = createAsyncThunk(
    'document/add',
    async (
        { institutionId, ...payload }: DocTypeFromForm & { institutionId: Institution['id'] },
        thunkApi,
    ) => {
        try {
            thunkApi.dispatch(actions.initAddDocument());

            const parseDataToDto = mapDocumentToDto(payload);

            await HTTPService.createDocument(institutionId, parseDataToDto);
            thunkApi.dispatch(actions.addDocumentSuccess());
        } catch (error: any) {
            logAxiosError(error);
            thunkApi.dispatch(actions.addDocumentError(error.response?.data || 'unknown error'));
            throw error;
        }
    },
);

const fetchDocumentsCostsList = createAsyncThunk(
    'documents/fetchDocumentsCostsList',
    async (
        {
            institutionId,
            ...options
        }: GetDocumentsCostsParameters & { institutionId: Institution['id'] },
        thunkApi,
    ) => {
        try {
            thunkApi.dispatch(actions.initDocumentsCostsListFetching());
            const {
                data: { content: fetchedDocumentsCostsList, totalElements },
            } = await HTTPService.getDocumentsCostsList(institutionId, options);

            const documentsCostsList = fetchedDocumentsCostsList.map(mapDocCostDtoToDocCost);

            thunkApi.dispatch(actions.setDocumentsCostsList(documentsCostsList));
            thunkApi.dispatch(actions.setDocumentsCostsListTotalElements(totalElements));

            thunkApi.dispatch(actions.documentsCostsListFetchingSuccess());
        } catch (error: any) {
            logAxiosError(error);
            thunkApi.dispatch(
                actions.documentsCostsListFetchingError(error.response?.data || 'unknown error'),
            );
            throw error;
        }
    },
);

const deleteDocument = createAsyncThunk(
    'document/delete',
    async (
        { institutionId, documentId }: { institutionId: Institution['id']; documentId: DocumentId },
        thunkApi,
    ) => {
        const currentState = thunkApi.getState() as RootState;
        try {
            thunkApi.dispatch(actions.initDeleteDocument());
            await HTTPService.deleteDocument(institutionId, documentId);

            thunkApi.dispatch(
                actions.setDocumentsCostsList(
                    currentState.settlement.documentsCostsList?.filter(
                        (documentCost) => documentCost.documentId !== documentId,
                    ),
                ),
            );

            thunkApi.dispatch(actions.deleteDocumentSuccess());
        } catch (error: any) {
            logAxiosError(error);
            thunkApi.dispatch(actions.deleteDocumentError(error.response?.data || 'unknown error'));
            throw error;
        }
    },
);

const getDocument = createAsyncThunk(
    'document/get',
    async (payload: { institutionId: Institution['id']; documentId: DocType['id'] }, thunkApi) => {
        try {
            thunkApi.dispatch(actions.initGetDocument());
            const { data } = await HTTPService.getDocument(
                payload.institutionId,
                payload.documentId,
            );
            const document = mapDtoToDocument(data);

            thunkApi.dispatch(actions.getDocumentSuccess(document));
        } catch (error: any) {
            logAxiosError(error);
            thunkApi.dispatch(actions.getDocumentError(error.response?.data || 'unknown error'));
            throw error;
        }
    },
);

const editDocument = createAsyncThunk(
    'document/edit',
    async (
        {
            institutionId,
            documentId,
            ...document
        }: DocTypeFromForm & { documentId: DocType['id']; institutionId: Institution['id'] },
        thunkApi,
    ) => {
        try {
            thunkApi.dispatch(actions.initUpdateDocument());
            const documentDto = mapDocumentToDto(document);
            await HTTPService.updateDocument({
                institutionId,
                documentId,
                documentData: documentDto,
            });
            thunkApi.dispatch(actions.updateDocumentSuccess());
        } catch (error: any) {
            logAxiosError(error);
            thunkApi.dispatch(actions.updateDocumentError(error.response?.data || 'unknown error'));
            throw error;
        }
    },
);

export { addDocument, getDocument, editDocument, fetchDocumentsCostsList, deleteDocument };
