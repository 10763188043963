import { PayloadAction } from '@reduxjs/toolkit';
import type { Institution } from 'utils/interfaces/institution';
import { SettlementState } from '../state';

export const setSelectedInstitution = (
    state: SettlementState,
    action: PayloadAction<Institution['id']>,
) => {
    state.selectedInstitution = action.payload;
};
