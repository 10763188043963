import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import InputMask from 'react-input-mask';

type InputProps = {
    name: string;
    mask: string;
} & Pick<React.ComponentProps<typeof Controller>, 'rules' | 'shouldUnregister' | 'defaultValue'> &
    React.ComponentProps<typeof TextField>;

const MaskInput: React.FC<InputProps> = ({
    name,
    shouldUnregister,
    defaultValue = '',
    rules,
    mask,
    ...inputProps
}) => {
    const { control } = useFormContext();
    return (
        <Controller
            name={name}
            control={control}
            shouldUnregister={shouldUnregister}
            rules={rules}
            defaultValue={defaultValue}
            render={(fieldProps) => (
                <InputMask mask={mask} {...fieldProps.field}>
                    {/* @ts-ignore*/}
                    {(props) => <TextField {...props} {...inputProps} />}
                </InputMask>
            )}
        />
    );
};

export { MaskInput };
