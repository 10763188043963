import { createSelector } from '@reduxjs/toolkit';
import { selectState } from '../slice';
import { SettlementState } from '../state';

const selectSelectedInstitution = createSelector(
    selectState,
    (state: SettlementState) => state.selectedInstitution,
);

export { selectSelectedInstitution };
