enum Permission {
    'DOCUMENT_VIEW' = 1,
    'SETTINGS_VIEW',
    'ADD_DOCUMENT',
    'ADD_GRANT',
    'UPDATE_DOCUMENT',
}

enum Role {
    'OWNER' = 'OWNER',
    'EMPLOYEE' = 'EMPLOYEE',
    'ANONYMOUS' = 'ANONYMOUS',
}

export { Permission, Role };
