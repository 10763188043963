import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { Container, FormControl, Stack, Typography, styled } from '@mui/material';
import { actions, selectors } from 'redux/slices/settlement';
import { useDispatch } from 'redux/store';
import { useAppTranslation } from 'utils/hooks/translation';
import { BackButton, InvoiceForm, BackdropAppLoader } from 'components';
import { HOME_PAGE } from 'consts/routes';
import { useErrorTranslation } from 'utils/functions/error';
import { Institution } from 'utils/interfaces/institution';
import { DocType, DocTypeFromForm } from 'utils/interfaces/document';
import dayjs from 'dayjs';
import { createInstituteHash } from 'utils/functions/string';
import { useSnackBar } from 'contexts/SnackbarContext/SnackbarContext';

const StyledContainer = styled(Container)`
    margin-top: 50px;
    margin-bottom: 80px;
`;

const StyledStack = styled(Stack)`
    max-width: 630px;
    width: 100%;
`;

const StyledBackButton = styled(BackButton)`
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
`;

const EditDocumentPage = () => {
    const { t: commonT } = useAppTranslation('common');
    const { t: settlementT } = useAppTranslation('settlement');
    const dispatch = useDispatch();
    const { showSnackBar } = useSnackBar();
    const document = useSelector(selectors.selectDocument);
    const getDocumentState = useSelector(selectors.selectGetDocumentState);
    const updateDocumentState = useSelector(selectors.selectUpdateDocumentState);
    const selectedInstitution = useSelector(selectors.selectSelectedInstitution);
    const loading = getDocumentState.loading || updateDocumentState.loading;
    const { institutionId, id } = useParams<{
        institutionId: Institution['id'];
        id: DocType['id'];
    }>();
    const navigate = useNavigate();
    const form = useForm<DocTypeFromForm>();
    const { translateError } = useErrorTranslation('settlement:addDocument');
    const {
        handleSubmit,
        formState: { errors },
        watch,
        unregister,
        setValue,
    } = form;

    useEffect(() => {
        institutionId && dispatch(actions.setSelectedInstitution(institutionId));
    }, [institutionId]);

    useEffect(() => {
        id &&
            selectedInstitution &&
            dispatch(actions.getDocument({ institutionId: selectedInstitution, documentId: id }));
    }, [id, selectedInstitution, dispatch]);

    useEffect(() => {
        form.reset({
            ...document,
            expenseDtos: document?.expenseDtos.reduce(
                (a, v) => ({ ...a, [createInstituteHash()]: v }),
                {},
            ),
            paymentDate: document?.paymentDate ? dayjs(document.paymentDate) : undefined,
            saleDate: document?.saleDate ? dayjs(document.saleDate) : undefined,
        });
    }, [document]);

    useEffect(() => {
        if (updateDocumentState.success) {
            showSnackBar(
                commonT('snackbar.success'),
                settlementT('editDocument.success'),
                'success',
            );
            dispatch(actions.clearAddDocument());
        } else if (updateDocumentState.error) {
            showSnackBar(
                commonT('snackbar.errorOccured'),
                translateError(updateDocumentState.error) || '',
                'error',
            );
            dispatch(actions.clearAddDocument());
        }
    }, [updateDocumentState]);

    useEffect(() => {
        if (getDocumentState.error) {
            showSnackBar(
                commonT('snackbar.errorOccured'),
                translateError(getDocumentState.error) || '',
                'error',
            );
            dispatch(actions.clearGetDocument());
        }
    }, [getDocumentState]);

    const onEditDocument = (data: DocTypeFromForm) => {
        selectedInstitution &&
            id &&
            dispatch(
                actions.editDocument({
                    ...data,
                    institutionId: selectedInstitution,
                    documentId: id,
                }),
            )
                .unwrap()
                .then(() => navigate(HOME_PAGE));
    };

    const goBack = () => navigate(HOME_PAGE);

    const documentStatus = watch('documentStatus');

    return (
        <StyledContainer>
            <BackdropAppLoader open={loading} />
            <Stack alignItems="center">
                <StyledStack position="relative">
                    <StyledBackButton onClick={goBack} label={commonT('button.back')} />
                    <Typography variant="h2" textAlign="center">
                        {settlementT('editDocument.title')}
                    </Typography>
                </StyledStack>
                <FormProvider {...form}>
                    <FormControl fullWidth>
                        <form onSubmit={handleSubmit(onEditDocument)}>
                            <InvoiceForm
                                goBack={goBack}
                                errors={errors}
                                documentStatus={documentStatus}
                                unregister={unregister}
                                editMode
                                setValue={setValue}
                            />
                        </form>
                    </FormControl>
                </FormProvider>
            </Stack>
        </StyledContainer>
    );
};

export default EditDocumentPage;
