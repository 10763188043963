import { Box, RadioGroup, styled, Typography } from '@mui/material';
import { AppButton } from 'components';

export const CheckboxesContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.table.background,
    padding: '16px 12px',
    minWidth: '260px',
    boxSizing: 'border-box',
    boxShadow: '6px 21px 36px rgba(35, 35, 35, 0.06)',
    borderRadius: '4px',
    position: 'absolute',
}));

export const Container = styled(Box)({
    position: 'relative',
});

export const Label = styled(Typography)(() => ({
    fontSize: '14px',
}));

export const AppButtonStyled = styled(AppButton)(() => ({
    margin: '0',
}));

export const RadioSectionLabel = styled(Typography)(() => ({
    fontSize: '12px',
    fontWeight: '700',
}));

export const RadioSectionContainer = styled(Box)({
    marginBottom: '5px',
});

export const CheckboxSectionNested = styled(RadioGroup)({
    marginLeft: '20px',
});
