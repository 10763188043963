import { createAsyncThunk } from '@reduxjs/toolkit';
import { logAxiosError } from 'utils/errors';
import HTTPService from 'services/HTTPService/HTTPService';
import type { Grant, GrantFormData } from 'utils/interfaces/grant';
import { actions } from '../index';
import { mapGrantToDto } from '../mappers/grants';

const addGrant = createAsyncThunk(
    'grant/add',
    async ({ instituteId, ...dataFromForm }: GrantFormData, thunkApi) => {
        try {
            const grant = mapGrantToDto(dataFromForm);

            thunkApi.dispatch(actions.initGrantRequest());
            await HTTPService.addGrant(instituteId, grant);
            thunkApi.dispatch(actions.grantRequestSuccess());
        } catch (error: any) {
            logAxiosError(error);
            thunkApi.dispatch(actions.grantRequestError(error.response?.data || 'unknown error'));
            throw error;
        }
    },
);

const updateGrant = createAsyncThunk(
    'grant/update',
    async (
        { instituteId, grantId, ...dataFromForm }: GrantFormData & { grantId: Grant['id'] },
        thunkApi,
    ) => {
        try {
            const grant = mapGrantToDto(dataFromForm);

            thunkApi.dispatch(actions.initGrantRequest());
            await HTTPService.updateGrant(instituteId, { ...grant, grantId });
            thunkApi.dispatch(actions.grantRequestSuccess());
        } catch (error: any) {
            logAxiosError(error);
            thunkApi.dispatch(actions.grantRequestError(error.response?.data || 'unknown error'));
            throw error;
        }
    },
);

export { addGrant, updateGrant };
