import { createAsyncThunk } from '@reduxjs/toolkit';
import { logAxiosError } from 'utils/errors';
import { RootState } from 'redux/store';
import HTTPService from 'services/HTTPService/HTTPService';
import type { Institution, InstitutionFormData } from 'utils/interfaces/institution';
import { actions } from '../index';
import { actions as settingsActions } from '../../settings/index';
import { actions as authActions } from '../../auth/index';
import { mapDtoToInstitution } from '../mappers/institution';

const getInstitutionList = createAsyncThunk('institutes/getAll', async (payload, thunkApi) => {
    try {
        thunkApi.dispatch(actions.initGetInstitutionList());
        const { data } = await HTTPService.getInstituteList();

        const institutes = data.map(mapDtoToInstitution);

        thunkApi.dispatch(actions.getInstitutionListSuccess(institutes));
    } catch (error: any) {
        logAxiosError(error);
        thunkApi.dispatch(actions.getInstitutionListError(error.response?.data || 'unknown error'));
        throw error;
    }
});

const addInstitution = createAsyncThunk<void, InstitutionFormData, { state: RootState }>(
    'institutions/add',
    async (payload, thunkApi) => {
        try {
            thunkApi.dispatch(actions.initAddInstitution());

            const { data } = await HTTPService.createInstitution([payload]);
            const newInstitutions = data.map(mapDtoToInstitution);
            const currentState = thunkApi.getState();
            const institutionListFromState = currentState.settlement.institutionList || [];

            thunkApi.dispatch(
                actions.setInstitutionList([...institutionListFromState, ...newInstitutions]),
            );

            currentState.auth.user?.assignedInstitutions &&
                thunkApi.dispatch(
                    authActions.setUser({
                        ...currentState.auth.user,
                        assignedInstitutions: [
                            ...currentState.auth.user.assignedInstitutions,
                            ...newInstitutions,
                        ],
                    }),
                );

            thunkApi.dispatch(settingsActions.getFullInstitutionsList());

            thunkApi.dispatch(actions.addInstitutionSuccess());
        } catch (error: any) {
            logAxiosError(error);
            thunkApi.dispatch(actions.addInstitutionError(error.response?.data || 'unknown error'));
            throw error;
        }
    },
);

const editInstitution = createAsyncThunk<void, Institution, { state: RootState }>(
    'institutions/edit',
    async (payload: Institution, thunkApi) => {
        try {
            thunkApi.dispatch(actions.initEditInstitution());

            const currentState = thunkApi.getState();

            const { data } = await HTTPService.editInstitution(
                {
                    name: payload.name,
                    address: payload.address,
                },
                payload.id,
            );

            const newInstitution = mapDtoToInstitution(data);

            currentState.settlement.institutionList &&
                thunkApi.dispatch(
                    actions.setInstitutionList(
                        currentState.settlement.institutionList.map((institution) =>
                            institution.id === newInstitution.id ? newInstitution : institution,
                        ),
                    ),
                );

            currentState.auth.user?.assignedInstitutions &&
                thunkApi.dispatch(
                    authActions.setUser({
                        ...currentState.auth.user,
                        assignedInstitutions: currentState.auth.user.assignedInstitutions.map(
                            (institution) =>
                                institution.id === newInstitution.id ? newInstitution : institution,
                        ),
                    }),
                );

            thunkApi.dispatch(settingsActions.getFullInstitutionsList());

            thunkApi.dispatch(actions.editInstitutionSuccess());
        } catch (error: any) {
            logAxiosError(error);
            thunkApi.dispatch(
                actions.editInstitutionError(error.response?.data || 'unknown error'),
            );
            throw error;
        }
    },
);

const deleteInstitution = createAsyncThunk<
    void,
    { institutionId: Institution['id'] },
    { state: RootState }
>('institutions/delete', async (payload, thunkApi) => {
    try {
        thunkApi.dispatch(actions.initDeleteInstitution());
        const currentState = thunkApi.getState();

        await HTTPService.deleteInstitution(payload.institutionId);

        currentState.settlement.institutionList &&
            thunkApi.dispatch(
                actions.setInstitutionList(
                    currentState.settlement.institutionList.filter(
                        (institution) => institution.id !== payload.institutionId,
                    ),
                ),
            );

        currentState.auth.user?.assignedInstitutions &&
            thunkApi.dispatch(
                authActions.setUser({
                    ...currentState.auth.user,
                    assignedInstitutions: currentState.auth.user.assignedInstitutions.filter(
                        (institution) => institution.id !== payload.institutionId,
                    ),
                }),
            );

        thunkApi.dispatch(settingsActions.getFullInstitutionsList());

        thunkApi.dispatch(actions.deleteInstitutionSuccess());
    } catch (error: any) {
        logAxiosError(error);
        thunkApi.dispatch(
            actions.deleteInstitutionError(error.response?.data?.code || 'unknown error'),
        );
        throw error;
    }
});

export { getInstitutionList, addInstitution, editInstitution, deleteInstitution };
