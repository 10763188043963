import { styled, TableSortLabel } from '@mui/material';
import TableCellMui from '@mui/material/TableCell';
import TableContainerMui from '@mui/material/TableContainer';
import TableHeadMui from '@mui/material/TableHead';

export const StyledTableHead = styled(TableHeadMui)(({ theme }) => ({
    borderRadius: '16px',
    height: '44px',
    th: {
        fontWeight: 700,
        fontSize: '12px',
        lineHeight: '20px',
        textTransform: 'uppercase',
        color: theme.palette.table.title,
        backgroundColor: '#eee',
        padding: '12px 16px',
        letterSpacing: '1px',
        marginBottom: '14px',
    },

    'th:first-of-type': {
        borderRadius: '16px 0  0 16px',
    },
    'th:last-of-type': {
        borderRadius: '0 16px 16px 0',
    },
}));

export const StyledTableCell = styled(TableCellMui)(({ theme }) => ({
    border: 0,
    color: theme.palette.table.contrastText,
    letterSpacing: '-0.4px',
    lineHeight: '22px',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'DM Sans',
    padding: '9px 16px',
}));

export const StyledTableContainer = styled(TableContainerMui)(({ theme }) => ({
    padding: '16px',
    backgroundColor: theme.palette.table.background,
    borderRadius: '16px 16px 0 0',
}));

export const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
    '&.Mui-active .MuiTableSortLabel-icon': {
        color: theme.palette.primary.main,
    },
}));
