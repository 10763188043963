import React, { useState } from 'react';
import { MoreHoriz } from '@mui/icons-material';
import { Menu, Typography } from '@mui/material';
import { useAppTranslation } from 'utils/hooks/translation';
import ConfirmModal from 'components/common/ConfirmModal/ConfirmModal';
import { actions as settlementActions } from 'redux/slices/settlement';
import { useDispatch } from 'redux/store';
import { StyledMenuItem, StyledMoreIconButton } from './ExpenseTypeListMoreMenu.styled';
import ExpenseTypeModal from '../../ExpenseTypeModal/ExpenseTypeModal';
import { ExpenseType } from '../../../utils/interfaces/document';

type ExpenseTypeListMoreMenuProps = {
    expenseType: ExpenseType;
};

const ExpenseTypeListMoreMenu = ({ expenseType }: ExpenseTypeListMoreMenuProps) => {
    const dispatch = useDispatch();

    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [isEditExpenseTypeModalOpen, setIsEditExpenseTypeModalOpen] = useState(false);
    const [isDeleteExpenseTypeConfirmModalOpen, setIsDeleteExpenseTypeConfirmModalOpen] =
        useState(false);

    const { t: settingsT } = useAppTranslation('settings');
    const expenseListT = (itemName: string) => settingsT(`expenseList.${itemName}`);

    const openNavMenu = (event: React.MouseEvent<HTMLElement>) =>
        setAnchorElNav(event.currentTarget);
    const closeNavMenu = () => setAnchorElNav(null);

    const closeEditExpenseTypeModal = () => {
        setIsEditExpenseTypeModalOpen(false);
    };

    const closeDeleteExpenseTypeConfirmModal = () => setIsDeleteExpenseTypeConfirmModalOpen(false);

    const onConfirmDeleteExpenseTypeClick = () => {
        dispatch(settlementActions.deleteExpenseType({ expenseTypeId: expenseType.id }));
        closeDeleteExpenseTypeConfirmModal();
    };

    const onEditClick = () => {
        setIsEditExpenseTypeModalOpen(true);
        closeNavMenu();
    };

    const onDeleteClick = () => {
        setIsDeleteExpenseTypeConfirmModalOpen(true);
        closeNavMenu();
    };

    return (
        <>
            <StyledMoreIconButton onClick={openNavMenu}>
                <MoreHoriz />
            </StyledMoreIconButton>
            <Menu
                anchorEl={anchorElNav}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={!!anchorElNav}
                onClose={closeNavMenu}
                componentsProps={{
                    backdrop: { style: { backgroundColor: 'transparent' } },
                }}
            >
                <StyledMenuItem onClick={onEditClick}>
                    <Typography textAlign="left">{expenseListT('edit')}</Typography>
                </StyledMenuItem>
                <StyledMenuItem onClick={onDeleteClick}>
                    <Typography textAlign="left">{expenseListT('delete')}</Typography>
                </StyledMenuItem>
            </Menu>
            <ExpenseTypeModal
                isOpen={isEditExpenseTypeModalOpen}
                onClose={closeEditExpenseTypeModal}
                type="edit"
                expenseType={expenseType}
            />
            <ConfirmModal
                open={isDeleteExpenseTypeConfirmModalOpen}
                onClose={closeDeleteExpenseTypeConfirmModal}
                title={expenseListT('areYouSureYouWantToDelete')}
                firstButtonLabel={expenseListT('cancel')}
                secondButtonLabel={expenseListT('yesDelete')}
                onFirstButtonClick={closeDeleteExpenseTypeConfirmModal}
                onSecondButtonClick={onConfirmDeleteExpenseTypeClick}
            />
        </>
    );
};

export default ExpenseTypeListMoreMenu;
