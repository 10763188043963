import React from 'react';
import ReactDOM from 'react-dom/client';
import 'index.css';
import 'configs/i18nConfig';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import App from 'App';
import { store } from 'redux/store';
import reportWebVitals from 'reportWebVitals';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import appTheme from 'utils/theme/appTheme';
import { SnackBarProvider } from 'contexts/SnackbarContext/SnackbarContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={appTheme}>
                <BrowserRouter>
                    <StyledEngineProvider injectFirst>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <SnackBarProvider>
                                <App />
                            </SnackBarProvider>
                        </LocalizationProvider>
                    </StyledEngineProvider>
                </BrowserRouter>
            </ThemeProvider>
        </Provider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
