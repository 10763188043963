import { PayloadAction } from '@reduxjs/toolkit';
import { FullInstitution } from 'utils/interfaces/institution';
import { SettingsState } from '../state';

export const initGetFullInstitutionsList = (state: SettingsState) => {
    state.getFullInstitutionsListState = { loading: true };
};

export const clearGetFullInstitutionsList = (state: SettingsState) => {
    state.getFullInstitutionsListState = { loading: false };
};

export const getFullInstitutionsListSuccess = (
    state: SettingsState,
    action: PayloadAction<FullInstitution[]>,
) => {
    state.fullInstitutionsList = action.payload;
    state.getFullInstitutionsListState = { loading: false, success: true };
};

export const getFullInstitutionsListError = (
    state: SettingsState,
    action: PayloadAction<string>,
) => {
    state.getFullInstitutionsListState = { loading: false, success: false, error: action.payload };
};
