import React from 'react';
import { Stack } from '@mui/material';
import {
    InstitutionNameTypography,
    StyledTableCell,
    StyledTableCellTypography,
    StyledTableHead,
} from './UsersListTable.styled';
import UserTableMoreMenu from '../UserTableMoreMenu/UserTableMoreMenu';
import { StyledTableContainer } from '../../common/CollapsableTable/CollapsableTable.styled';
import { Table } from '../../common';
import { UserWithInstitutions } from '../../../utils/interfaces/auth';
import objectHash from 'object-hash';

type UsersListTableProps = {
    usersWithInstitutions: UserWithInstitutions[];
    loading?: boolean;
};

const UsersListTable = ({ usersWithInstitutions, loading = false }: UsersListTableProps) => (
    <Table
        template={[
            {
                title: 'Imię i nazwisko',
                key: 'fullName',
                render: (renderData) => (
                    <StyledTableCellTypography>{`${renderData.firstName} ${renderData.lastName}`}</StyledTableCellTypography>
                ),
            },
            {
                title: 'Placówki',
                key: 'institutions',
                render: (renderData: UserWithInstitutions) => (
                    <Stack>
                        {renderData.assignedInstitutionNames.map((institutionName) => (
                            <InstitutionNameTypography
                                key={objectHash({ user: renderData, institution: institutionName })}
                            >
                                {institutionName}
                            </InstitutionNameTypography>
                        ))}
                    </Stack>
                ),
            },
            {
                title: 'Email',
                key: 'email',
            },

            {
                title: '',
                key: 'action',
                render: (renderData) => (
                    <Stack flex={1} flexDirection="row" justifyContent="flex-end">
                        <UserTableMoreMenu user={renderData} />
                    </Stack>
                ),
            },
        ]}
        data={usersWithInstitutions}
        customTableHeadComponent={StyledTableHead}
        customTableContainerComponent={StyledTableContainer}
        customTableCellComponent={StyledTableCell}
        loading={loading}
    />
);

export default UsersListTable;
