import { createAsyncThunk } from '@reduxjs/toolkit';
import { logAxiosError } from 'utils/errors';
import { RootState } from 'redux/store';
import HTTPService from 'services/HTTPService/HTTPService';
import type { ExpenseTypeFromData, ExpenseType } from '../../../../utils/interfaces/document';
import { actions } from '../index';
import { DUPLICATE_EXPENSE_TYPE_NAME } from '../../../../consts/errors';
import { sortExpensesByName } from '../../../../utils/functions/documents';
import { InstitutionId } from '../../../../utils/interfaces/institution';

const getExpenseTypeList = createAsyncThunk('expenseTypes/getAll', async (payload, thunkApi) => {
    try {
        thunkApi.dispatch(actions.initGetExpenseTypeList());
        const { data: expenseTypes } = await HTTPService.getExpenseTypeList();
        expenseTypes.sort(sortExpensesByName);

        thunkApi.dispatch(actions.getExpenseTypeListSuccess(expenseTypes));
    } catch (error: any) {
        logAxiosError(error);
        thunkApi.dispatch(actions.getExpenseTypeListError(error.response?.data || 'unknown error'));
        throw error;
    }
});
const getAllExpenseTypeList = createAsyncThunk<void, InstitutionId>(
    'allExpenseTypes/getAll',
    async (payload: InstitutionId, thunkApi) => {
        try {
            thunkApi.dispatch(actions.initGetAllExpenseTypeList());
            const { data: expenseTypes } = await HTTPService.getAllExpenseTypeList(payload);

            thunkApi.dispatch(actions.getAllExpenseTypeListSuccess(expenseTypes));
        } catch (error: any) {
            logAxiosError(error);
            thunkApi.dispatch(
                actions.getExpenseTypeListError(error.response?.data || 'unknown error'),
            );
            throw error;
        }
    },
);

const addExpenseType = createAsyncThunk<void, ExpenseTypeFromData, { state: RootState }>(
    'expenseTypes/add',
    async (payload: ExpenseTypeFromData, thunkApi) => {
        try {
            thunkApi.dispatch(actions.initAddExpenseType());

            const { data: newExpenseType } = await HTTPService.createExpenseType(payload);
            const currentState = thunkApi.getState();
            const expenseTypeListFromState = currentState.settlement.expenseTypeList || [];

            thunkApi.dispatch(
                actions.setExpenseTypeList(
                    [...expenseTypeListFromState, newExpenseType].sort(sortExpensesByName),
                ),
            );

            thunkApi.dispatch(actions.addExpenseTypeSuccess());
        } catch (error: any) {
            logAxiosError(error);
            thunkApi.dispatch(
                actions.addExpenseTypeError(
                    error.response.status === 409
                        ? DUPLICATE_EXPENSE_TYPE_NAME
                        : error.response?.data || 'unknown error',
                ),
            );
            throw error;
        }
    },
);

const editExpenseType = createAsyncThunk<void, ExpenseType, { state: RootState }>(
    'expenseTypes/edit',
    async (payload: ExpenseType, thunkApi) => {
        try {
            thunkApi.dispatch(actions.initEditExpenseType());

            const currentState = thunkApi.getState();

            const { data: newExpenseType } = await HTTPService.editExpenseType(
                {
                    name: payload.name,
                },
                payload.id,
            );

            currentState.settlement.expenseTypeList &&
                thunkApi.dispatch(
                    actions.setExpenseTypeList(
                        currentState.settlement.expenseTypeList
                            .map((expenseType) =>
                                expenseType.id === newExpenseType.id ? newExpenseType : expenseType,
                            )
                            .sort(sortExpensesByName),
                    ),
                );

            thunkApi.dispatch(actions.editExpenseTypeSuccess());
        } catch (error: any) {
            logAxiosError(error);
            thunkApi.dispatch(
                actions.editExpenseTypeError(
                    error.response.status === 409
                        ? DUPLICATE_EXPENSE_TYPE_NAME
                        : error.response?.data || 'unknown error',
                ),
            );
            throw error;
        }
    },
);

const deleteExpenseType = createAsyncThunk<
    void,
    { expenseTypeId: ExpenseType['id'] },
    { state: RootState }
>('expenseTypes/delete', async (payload, thunkApi) => {
    try {
        thunkApi.dispatch(actions.initDeleteExpenseType());
        const currentState = thunkApi.getState();

        await HTTPService.deleteExpenseType(payload.expenseTypeId);

        currentState.settlement.expenseTypeList &&
            thunkApi.dispatch(
                actions.setExpenseTypeList(
                    currentState.settlement.expenseTypeList.filter(
                        (expenseType) => expenseType.id !== payload.expenseTypeId,
                    ),
                ),
            );

        thunkApi.dispatch(actions.deleteExpenseTypeSuccess());
    } catch (error: any) {
        logAxiosError(error);
        thunkApi.dispatch(actions.deleteExpenseTypeError(error.response?.data || 'unknown error'));
        throw error;
    }
});

export {
    getExpenseTypeList,
    addExpenseType,
    editExpenseType,
    deleteExpenseType,
    getAllExpenseTypeList,
};
