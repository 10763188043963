import { PayloadAction } from '@reduxjs/toolkit';
import { UserDetailsType } from 'utils/interfaces/auth';
import { UsersState } from '../state';

export const initGetSelectedUser = (state: UsersState) => {
    state.getSelectedUserState = { loading: true };
};

export const clearGetSelectedUser = (state: UsersState) => {
    state.getSelectedUserState = { loading: false };
};

export const getSelectedUserSuccess = (
    state: UsersState,
    action: PayloadAction<UserDetailsType>,
) => {
    state.selectedUser = action.payload;
    state.getSelectedUserState = { loading: false, success: true };
};

export const getSelectedUserError = (state: UsersState, action: PayloadAction<string>) => {
    state.getSelectedUserState = { loading: false, success: false, error: action.payload };
};
