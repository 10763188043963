import { UserDetailsType, UserWithInstitutions } from '../interfaces/auth';

export const mapUserDetailsTypeToUserWithInstitution = (
    userDetailsType: UserDetailsType,
): UserWithInstitutions => ({
    id: userDetailsType.id,
    email: userDetailsType.email,
    firstName: userDetailsType.firstName || '',
    lastName: userDetailsType.lastName || '',
    assignedInstitutionNames:
        userDetailsType.assignedInstitutions?.map((institution) => institution.name) || [],
});
