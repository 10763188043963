import React, { ReactNode, useState } from 'react';
import { Collapse, IconButton, Stack, TableBody } from '@mui/material';
import { Table } from 'components';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Column } from '../common';
import {
    StyledInnerTableContainer,
    StyledMainTableContainer,
    StyledNameTypography,
    UsersTabHeader,
    StyledTableCell,
    UsersTableContainer,
    StyledInnerTableCell,
} from './CollapsableInstitutionTable.styled';
import { FullInstitution, Institution } from '../../utils/interfaces/institution';
import { UserDetailsType } from '../../utils/interfaces/auth';
import { useAppTranslation } from '../../utils/hooks/translation';

type RowProps = {
    renderSubtitle: (data: FullInstitution) => ReactNode;
    data: FullInstitution;
    template: Column<UserDetailsType, Institution['id']>[];
    noDataPlaceholder?: ReactNode;
};

type CollapsableTableProps = {
    renderSubtitle: (data: FullInstitution) => ReactNode;
    innerTemplate: Column<UserDetailsType, Institution['id']>[];
    data: FullInstitution[];
    loading?: boolean;
    innerNoDataPlaceholder?: ReactNode;
};

const Row = ({ renderSubtitle, data, template, noDataPlaceholder }: RowProps) => {
    const [open, setOpen] = useState(false);
    const { t: settingsT } = useAppTranslation('settings');
    const institutionsListT = (itemName: string) => settingsT(`institutionsList.${itemName}`);

    return (
        <>
            <Stack flex={1} flexDirection="row" justifyContent="space-between" alignItems="center">
                <StyledNameTypography>{data.name}</StyledNameTypography>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen((currentOpen) => !currentOpen)}
                >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </Stack>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Stack
                    flex={1}
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    {renderSubtitle(data)}
                </Stack>
                <UsersTableContainer>
                    <UsersTabHeader variant="subtitle2">
                        {data.assignedUsers?.length
                            ? institutionsListT('usersSubtitle')
                            : institutionsListT('noUsersSubtitle')}
                    </UsersTabHeader>
                    <Table
                        template={template}
                        data={data.assignedUsers || []}
                        tableData={data.id}
                        headless
                        customTableCellComponent={StyledInnerTableCell}
                        customTableBodyComponent={TableBody}
                        customTableContainerComponent={StyledInnerTableContainer}
                        noDataPlaceholder={noDataPlaceholder}
                    />
                </UsersTableContainer>
            </Collapse>
        </>
    );
};

const CollapsableInstitutionTable = ({
    renderSubtitle,
    innerTemplate,
    data,
    loading,
    innerNoDataPlaceholder,
}: CollapsableTableProps) => (
    <Table
        template={[
            {
                title: '',
                key: 'name',
                render: (renderData) => (
                    <Row
                        renderSubtitle={renderSubtitle}
                        data={renderData}
                        template={innerTemplate}
                        noDataPlaceholder={innerNoDataPlaceholder}
                    />
                ),
            },
        ]}
        data={data}
        headless
        customTableContainerComponent={StyledMainTableContainer}
        customTableCellComponent={StyledTableCell}
        loading={loading}
    />
);

export default CollapsableInstitutionTable;
