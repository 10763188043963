import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { REGISTER_PAGE, RESET_PASSWORD } from 'consts/routes';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import {
    Container,
    FormControl,
    Stack,
    styled,
    Typography,
    useMediaQuery,
    useTheme,
    Link as LinkMui,
} from '@mui/material';
import { useAppTranslation } from 'utils/hooks/translation';
import { useAuth } from 'utils/hooks';
import Layout from 'components/Layout/Layout';
import { AppButton, Input, PasswordInput, BackdropAppLoader } from 'components';
import { SignInBg } from 'assets';
import { useErrorTranslation } from 'utils/functions/error';
import { FormError } from 'components/common/FormError/FormError';
import { useSelector } from 'react-redux';
import { selectors } from 'redux/slices/auth';

const SignInBgImgStyled = styled('img')({
    objectFit: 'cover',
    height: 'calc(100vh - 115px)',
});

const LoginPage = () => {
    const { signIn, backendError } = useAuth();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { t: authT } = useAppTranslation('auth');
    const { t: commonT } = useAppTranslation('common');
    const { translateError } = useErrorTranslation('auth:loginPage');
    const signInState = useSelector(selectors.selectSignInState);
    const form = useForm();
    const {
        handleSubmit,
        formState: { errors },
    } = form;

    const onSignInClick = (data: any) => {
        signIn(data);
    };

    return (
        <Stack direction="row">
            <BackdropAppLoader open={signInState.loading} />
            {!smallScreen && <SignInBgImgStyled src={SignInBg} />}
            <Container>
                <Stack alignItems="center" marginTop="120px">
                    <Typography variant="h2" gutterBottom>
                        {authT('loginPage.title')}
                    </Typography>
                    <Typography marginBottom="12px">
                        <Trans i18nKey="loginPage.subTitle">
                            Nie masz konta?{' '}
                            <LinkMui component={Link} underline="none" to={REGISTER_PAGE}>
                                Zarejestruj się
                            </LinkMui>
                        </Trans>
                    </Typography>
                    <Typography variant="h5" marginBottom="16px">
                        {authT('loginPage.welcomeBack')}
                    </Typography>
                    <FormProvider {...form}>
                        <FormControl fullWidth>
                            <form onSubmit={handleSubmit(onSignInClick)}>
                                <Stack gap="19px" marginTop="34px" marginBottom="20px">
                                    <Layout.Container>
                                        <Input
                                            rules={{ required: commonT('form.required') }}
                                            label={authT('loginPage.email')}
                                            name="email"
                                            type="email"
                                            fullWidth
                                            error={!!errors.email || !!backendError?.length}
                                            helperText={(errors?.email?.message || '') as string}
                                        />
                                        <PasswordInput
                                            rules={{
                                                required: commonT('form.required'),
                                            }}
                                            label={authT('loginPage.password')}
                                            name="password"
                                            fullWidth
                                            error={!!errors.password || !!backendError?.length}
                                            helperText={(errors?.password?.message || '') as string}
                                        />
                                        <FormError error={translateError(backendError as string)} />
                                    </Layout.Container>
                                    <AppButton type="submit" fullWidth>
                                        {authT('loginPage.button')}
                                    </AppButton>
                                    <Typography textAlign="center">
                                        <Trans i18nKey="auth:loginPage.changePassword">
                                            Nie pamiętasz hasła?{' '}
                                            <LinkMui
                                                component={Link}
                                                underline="none"
                                                to={RESET_PASSWORD}
                                            >
                                                Zmień hasło
                                            </LinkMui>
                                        </Trans>
                                    </Typography>
                                </Stack>
                            </form>
                        </FormControl>
                    </FormProvider>
                </Stack>
            </Container>
        </Stack>
    );
};

export default LoginPage;
