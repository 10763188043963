import React from 'react';
import { Stack } from '@mui/material';
import { FullInstitution, Institution } from 'utils/interfaces/institution';
import {
    StyledTableCellNameTypography,
    StyledTableCellTypography,
} from './InstitutionsListTable.styled';
import CollapsableInstitutionTable from '../../CollapsableInstitutionRow/CollapsableInstitutionTable';
import InstitutionUserTableMoreMenu from '../InstitutionUserTableMoreMenu/InstitutionUserTableMoreMenu';
import InstitutionTableMoreMenu from '../InstitutionTableMoreMenu/InstitutionTableMoreMenu';

type InstitutionsListTableProps = {
    institutions: FullInstitution[] | null;
    loading?: boolean;
};

const InstitutionsListTable = ({ institutions, loading = false }: InstitutionsListTableProps) => (
    <CollapsableInstitutionTable
        renderSubtitle={(renderData) => (
            <Stack flex={1} flexDirection="row" alignItems="center">
                <StyledTableCellTypography>{renderData.address.zipCode}</StyledTableCellTypography>
                <StyledTableCellTypography>{renderData.address.city}</StyledTableCellTypography>
                <StyledTableCellTypography>{renderData.address.street}</StyledTableCellTypography>
                <StyledTableCellNameTypography>REGON:</StyledTableCellNameTypography>
                <StyledTableCellTypography>{renderData.regon}</StyledTableCellTypography>
                <StyledTableCellNameTypography>RSPO:</StyledTableCellNameTypography>
                <StyledTableCellTypography>{renderData.rspo}</StyledTableCellTypography>
                <InstitutionTableMoreMenu institutionId={renderData.id} />
            </Stack>
        )}
        innerTemplate={[
            {
                width: '40%',
                title: '',
                key: 'name',
                render: (renderData) => (
                    <StyledTableCellTypography>{`${renderData.firstName} ${renderData.lastName}`}</StyledTableCellTypography>
                ),
            },
            {
                width: '40%',
                title: '',
                key: 'email',
                render: (renderData) => (
                    <StyledTableCellTypography>{`${renderData.email}`}</StyledTableCellTypography>
                ),
            },
            {
                title: '',
                key: 'action',
                render: (renderData, tableData) => (
                    <Stack flex={1} flexDirection="row" justifyContent="flex-end">
                        <InstitutionUserTableMoreMenu
                            userId={renderData.id}
                            institutionId={tableData as Institution['id']}
                        />
                    </Stack>
                ),
            },
        ]}
        data={institutions || []}
        loading={loading}
    />
);

export default InstitutionsListTable;
