import { useDispatch as useDispatchRedux } from 'react-redux';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import * as auth from './slices/auth';
import * as settlement from './slices/settlement';
import * as app from './slices/app';
import * as reports from './slices/reports';
import * as users from './slices/users';
import * as settings from './slices/settings';

const combinedReducers = combineReducers({
    [auth.authSliceName]: auth.reducer,
    [settlement.settlementSliceName]: settlement.reducer,
    [app.appSliceName]: app.reducer,
    [reports.reportsSliceName]: reports.reducer,
    [users.usersSliceName]: users.reducer,
    [settings.settingsSliceName]: settings.reducer,
});

const store = configureStore({
    reducer: combinedReducers,
});

// @ts-ignore
type AppDispatch = ReturnType<typeof store>['dispatch'];

type RootState = ReturnType<typeof store.getState>;

const useDispatch = () => useDispatchRedux<AppDispatch>();

export { store, useDispatch };
export type { RootState };
