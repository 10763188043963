import { styled, Typography } from '@mui/material';
import TableHeadMui from '@mui/material/TableHead';
import TableCellMui from '@mui/material/TableCell';

export const StyledTableCellTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
    letterSpacing: '-0.4px',
    lineHeight: '22px',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'DM Sans',
}));

export const StyledTableHead = styled(TableHeadMui)(({ theme }) => ({
    borderRadius: '16px',
    th: {
        fontWeight: 700,
        fontSize: '12px',
        lineHeight: '20px',
        textTransform: 'uppercase',
        color: theme.palette.table.title,
        padding: '12px 16px 8px 16px',
        letterSpacing: '1px',
    },

    'th:first-of-type': {
        borderRadius: '16px 0  0 16px',
    },
    'th:last-of-type': {
        borderRadius: '0 16px 16px 0',
    },
}));

export const InstitutionNameTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.table.contrastText,
    letterSpacing: '-0.4px',
    lineHeight: '22px',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'DM Sans',
}));

export const StyledTableCell = styled(TableCellMui)(({ theme }) => ({
    verticalAlign: 'top',
    marginBottom: 'auto',
    border: 0,
    color: theme.palette.table.contrastText,
    letterSpacing: '-0.4px',
    lineHeight: '22px',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'DM Sans',
    padding: '14px 16px',
}));
