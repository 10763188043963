import { PayloadAction } from '@reduxjs/toolkit';
import { UsersState } from '../state';

export const initEditUser = (state: UsersState) => {
    state.editUserState = { loading: true };
};

export const clearEditUser = (state: UsersState) => {
    state.editUserState = { loading: false };
};

export const editUserSuccess = (state: UsersState) => {
    state.editUserState = { loading: false, success: true };
};

export const editUserError = (state: UsersState, action: PayloadAction<string>) => {
    state.editUserState = { loading: false, success: false, error: action.payload };
};
