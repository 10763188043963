import React, { useEffect, useState } from 'react';
import { Container, Tab, Typography } from '@mui/material';
import { useAppTranslation } from 'utils/hooks/translation';
import { actions as settingsActions } from 'redux/slices/settings';
import { useDispatch } from 'redux/store';
import PaymentsTab from './Tabs/PaymentsTab/PaymentsTab';
import InstitutionsTab from './Tabs/InstitutionsTab/InstitutionsTab';
import UsersTab from './Tabs/UsersTab/UsersTab';
import { StyledMainStack, StyledTabs } from './SettingsPage.styled';
import { useLocation, useNavigate } from 'react-router-dom';
import ExpenseTypesTab from './Tabs/ExpenseTypesTab/ExpenseTypesTab';
import { actions as settlementActions } from '../../../redux/slices/settlement';

const TABS = [
    { value: 1, name: 'payments', component: <PaymentsTab /> },
    { value: 2, name: 'institutions', component: <InstitutionsTab /> },
    { value: 3, name: 'users', component: <UsersTab /> },
    { value: 4, name: 'expenses', component: <ExpenseTypesTab /> },
];

const getInitialActiveTab = (path: string) => {
    const activeTabName = path.split('/')[2];
    const activeTab = TABS.find((tab) => tab.name === activeTabName);

    return activeTab?.value || 1;
};

const SettingsPage = () => {
    const { t: settingsT } = useAppTranslation('settings');
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState(getInitialActiveTab(location.pathname));

    useEffect(() => {
        dispatch(settingsActions.getFullInstitutionsList());
        dispatch(settlementActions.getExpenseTypeList());

        if (location.pathname === '/settings') {
            navigate('/settings/payments', { replace: true });
        }
    }, []);

    const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
        navigate(TABS[newValue - 1].name, { replace: true });
    };

    return (
        <Container>
            <StyledMainStack>
                <Typography variant="h2">{settingsT('settings')}</Typography>
                <StyledTabs value={selectedTab} onChange={handleTabChange}>
                    {TABS.map((tab) => (
                        <Tab
                            label={settingsT(`tabs.${tab.name}`)}
                            value={tab.value}
                            key={tab.value}
                        />
                    ))}
                </StyledTabs>
                {selectedTab && TABS.find((tab) => tab.value === selectedTab)?.component}
            </StyledMainStack>
        </Container>
    );
};

export default SettingsPage;
