import React from 'react';
import { useAppTranslation } from 'utils/hooks/translation';
import { StyledContainerStack, StyledTotalExpensesTypography } from './TotalExpensesCard.styled';

type TotalExpensesCardProps = {
    totalExpenses?: number;
};

const TotalExpensesCard = ({ totalExpenses }: TotalExpensesCardProps) => {
    const { t: settlementT } = useAppTranslation('settlement');

    return (
        <StyledContainerStack>
            <StyledTotalExpensesTypography>{`${settlementT('addDocument.totalExpenses')}: ${
                totalExpenses && totalExpenses > 0 ? `${totalExpenses.toFixed(2)} zł` : '-'
            }`}</StyledTotalExpensesTypography>
        </StyledContainerStack>
    );
};
export default TotalExpensesCard;
