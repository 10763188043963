import { Typography, styled } from '@mui/material';
import { AppButton } from 'components';

export const StyledPaymentsTabContainer = styled('div')`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 16px 32px;
    background-color: #fff;
    border-radius: 16px 16px 0 0;
`;

export const StyledPaymentsColumnTitle = styled(Typography)`
    font-family: 'DM Sans';
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    width: 50%;
    color: ${({ theme }) => theme.palette.text.grey100};
`;

export const StyledTitleRow = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 12px 0;
`;

export const StyledTwoColumnsRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '64px',
    margin: '6px 0',
});

export const StyledPill = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 50%;
    border-color: ${({ theme }) => theme.palette.table.border};
    border-width: 1px;
    padding: 17px 20px 17px 24px;
    border-style: solid;

    &:first-of-type {
        border-radius: 6px 0 0 6px;
    }

    &:last-of-type {
        border-radius: 0 6px 6px 0;
        border-left-width: 0;
    }
`;

export const StyledValueTypography = styled(Typography)`
    font-family: 'DM Sans';
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.palette.text.grey100};
`;

export const StyledDescriptionTypography = styled(Typography)`
    font-family: 'DM Sans';
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: ${({ theme }) => theme.palette.text.contentBody};
`;

export const StyledInfoTypography = styled(Typography)`
    font-family: 'DM Sans';
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: ${({ theme }) => theme.palette.text.contentBody};
    margin: 12px 0 24px 0;
`;

export const StyledBuyButton = styled(AppButton)`
    padding: 8.5px 37.5px;
    background-color: ${({ theme }) => theme.palette.primary.main};
`;
