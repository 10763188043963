import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { currentEnvName } from 'configs/currentEnviroment';
import { getAuth } from 'firebase/auth';

const URLs = {
    devLocal: 'http://localhost:8080/', //'https://dev.example.softwarepartner.pl/',
    devRemote: 'https://api.dev.dotacje.softwarepartner.pl/',
    uat: 'https://api.uat.dotacje.softwarepartner.pl/',
    prod: 'https://api.elfedu.pl/',
};

export const baseURL = URLs[currentEnvName];

class AxiosInstance {
    private axios = axios.create({ baseURL });

    constructor() {
        this.axios.interceptors.request.use(this.getConfigWithAuthorization, Promise.reject);
    }

    getConfigWithAuthorization = async (config: AxiosRequestConfig) => {
        const headers = { ...config.headers } || {};
        try {
            const auth = getAuth();
            const idTokenJwt = await auth.currentUser?.getIdToken();
            if (idTokenJwt) {
                headers.Authorization = `Bearer ${idTokenJwt}`;
            }
        } catch (error) {
            return config;
        }
        return { ...config, headers };
    };

    public get = <T = any>(...params: [string, AxiosRequestConfig?]): Promise<AxiosResponse<T>> =>
        this.axios.get<T>(...params);

    public post = <T = any>(
        ...params: [string, any?, AxiosRequestConfig?]
    ): Promise<AxiosResponse<T>> => this.axios.post<T>(...params);

    public delete = <T = any>(
        ...params: [string, AxiosRequestConfig?]
    ): Promise<AxiosResponse<T>> => this.axios.delete<T>(...params);

    public patch = <T = any>(
        ...params: [string, any?, AxiosRequestConfig?]
    ): Promise<AxiosResponse<T>> => this.axios.patch<T>(...params);

    public put = <T = any>(
        ...params: [string, any?, AxiosRequestConfig?]
    ): Promise<AxiosResponse<T>> => this.axios.put<T>(...params);
}

export default AxiosInstance;
