import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NO_ACCESS_ROUTE } from 'consts/routes';
import { useAccessManager, useAuth } from 'utils/hooks';
import { selectors as authSelectors } from 'redux/slices/auth';
import { Permission } from 'utils/interfaces/permissions';
import { BackdropAppLoader } from '..';

type Props = {
    permission: Permission;
    fallback: string;
    component: React.ReactNode;
};

// Route that is accessible once you are logged-in user
const PrivateRoute = ({ permission, fallback, component }: Props) => {
    const { user } = useAuth();
    const { canAccess } = useAccessManager();
    const userState = useSelector(authSelectors.selectGetUserState);

    const userCanAccess = canAccess(permission);

    if (!userState.loading && !user && !userCanAccess) {
        return <Navigate to={fallback ?? NO_ACCESS_ROUTE} />;
    }

    return (
        <>
            {userCanAccess && !userState.loading && component}
            <BackdropAppLoader open={userState.loading} />
        </>
    );
};

export { PrivateRoute };
