import { PayloadAction } from '@reduxjs/toolkit';
import type { SettlementState } from '../state';

export const initAddDocument = (state: SettlementState) => {
    state.addDocumentState = { loading: true };
};

export const clearAddDocument = (state: SettlementState) => {
    state.addDocumentState = { loading: false };
};

export const addDocumentSuccess = (state: SettlementState) => {
    state.addDocumentState = { loading: false, success: true };
};

export const addDocumentError = (state: SettlementState, action: PayloadAction<string>) => {
    state.addDocumentState = { loading: false, success: false, error: action.payload };
};
