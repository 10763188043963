import { PayloadAction } from '@reduxjs/toolkit';
import { Institution } from 'utils/interfaces/institution';
import { SettlementState } from '../state';

export const initGetInstitutionList = (state: SettlementState) => {
    state.getInstitutionListState = { loading: true };
};

export const clearGetInstitutionList = (state: SettlementState) => {
    state.getInstitutionListState = { loading: false };
};

export const getInstitutionListSuccess = (
    state: SettlementState,
    action: PayloadAction<Institution[]>,
) => {
    state.getInstitutionListState = { loading: false, success: true };
    state.institutionList = action.payload;
};

export const getInstitutionListError = (state: SettlementState, action: PayloadAction<string>) => {
    state.getInstitutionListState = { loading: false, success: false, error: action.payload };
};

export const setInstitutionList = (
    state: SettlementState,
    action: PayloadAction<Institution[]>,
) => {
    state.institutionList = action.payload;
};
