import React, { Dispatch, SetStateAction } from 'react';
import { Box, Stack, Typography, styled } from '@mui/material';
import { useAppTranslation } from 'utils/hooks/translation';

const StyledStep = styled(Box)`
    height: 5px;
    border-radius: 7px;
    background-color: ${({ theme }) => theme.palette.primary.light};
    width: 100%;
`;

const StyledActiveStep = styled(StyledStep)`
    background-color: ${({ theme }) => theme.palette.primary.main};
    cursor: pointer;
`;

type Props = {
    step: number;
    allSteps: number;
    changeStep: Dispatch<SetStateAction<2 | 1>>;
};

const RegisterStepper = ({ step, allSteps, changeStep }: Props) => {
    const { t } = useAppTranslation('auth');
    return (
        <Stack>
            <Typography>{t('registerPage.steps', { amount: step, allSteps: allSteps })}</Typography>
            <Stack direction="row" gap="19px">
                {Array.from({ length: allSteps }).map((stepNumber, index) =>
                    step > index ? (
                        <StyledActiveStep
                            key={`step-${index}`}
                            onClick={() => changeStep((index + 1) as 1 | 2)}
                        />
                    ) : (
                        <StyledStep key={`step-${index}`} />
                    ),
                )}
            </Stack>
        </Stack>
    );
};

export { RegisterStepper };
