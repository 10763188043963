import React from 'react';
import { Button, ButtonProps } from '@mui/material';

export interface AppButtonProps extends ButtonProps {
    variant?: 'text' | 'contained' | 'outlined' | 'inline';
    disabled?: boolean;
}
const AppButton = ({ variant = 'contained', ...props }: AppButtonProps) => (
    <Button variant={variant} {...props} />
);

export { AppButton };
