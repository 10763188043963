import { UsersState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';

export const initDeleteUserFromInstitution = (state: UsersState) => {
    state.deleteUserFromInstitutionState = { loading: true };
};

export const clearDeleteUserFromInstitution = (state: UsersState) => {
    state.deleteUserFromInstitutionState = { loading: false };
};

export const deleteUserFromInstitutionSuccess = (state: UsersState) => {
    state.deleteUserFromInstitutionState = { loading: false, success: true };
};

export const deleteUserFromInstitutionError = (
    state: UsersState,
    action: PayloadAction<string>,
) => {
    state.deleteUserFromInstitutionState = {
        loading: false,
        success: false,
        error: action.payload,
    };
};
