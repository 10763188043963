import { Institution } from 'utils/interfaces/institution';

export const LOGIN_PAGE = '/login';
export const REGISTER_PAGE = '/register';
export const HOME_PAGE = '/';
export const ADD_DOCUMENT = '/add-document';
export const EDIT_DOCUMENT = (institutionId: Institution['id'], id: string) =>
    `/edit-document/${institutionId}/${id}`;
export const ADD_GRANT = '/add-grant';
export const SETTINGS_PAGE = '/settings/*';
export const SETTINGS_PAGE_WITH_DEFAULT_TAB = '/settings/payments';
export const RESET_PASSWORD = '/reset-password';
export const RESET_NEW_PASSWORD = '/reset-password/new-password';
export const NO_ACCESS_ROUTE = LOGIN_PAGE;
