import { Permission, Role } from 'utils/interfaces/permissions';

const accessMap: Record<Role, Permission[]> = {
    [Role.OWNER]: [
        Permission.DOCUMENT_VIEW,
        Permission.SETTINGS_VIEW,
        Permission.ADD_DOCUMENT,
        Permission.ADD_GRANT,
        Permission.UPDATE_DOCUMENT,
    ],
    [Role.EMPLOYEE]: [
        Permission.DOCUMENT_VIEW,
        Permission.ADD_DOCUMENT,
        Permission.ADD_GRANT,
        Permission.UPDATE_DOCUMENT,
    ],
    [Role.ANONYMOUS]: [],
};

export { accessMap };
