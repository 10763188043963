import { Institution } from 'utils/interfaces/institution';
import { ReportGrantCategory, ReportGrantEducationalSubcategory } from 'utils/interfaces/report';

const voivodeshipsOptions = [
    { name: 'Dolnośląskie', value: 'dolnoslaskie' },
    { name: 'Kujawsko-pomorskie', value: 'kujawskopomorskie' },
    { name: 'Lubelskie', value: 'lubelskie' },
    { name: 'Lubuskie', value: 'lubuskie' },
    { name: 'Łódzkie', value: 'lodzkie' },
    { name: 'Małopolskie', value: 'malopolskie' },
    { name: 'Mazowieckie', value: 'mazowieckie' },
    { name: 'Opolskie', value: 'opolskie' },
    { name: 'Podkarpackie', value: 'podkarpackie' },
    { name: 'Podlaskie', value: 'podlaskie' },
    { name: 'Pomorskie', value: 'pomorskie' },
    { name: 'Śląskie', value: 'slaskie' },
    { name: 'Świętokrzyskie', value: 'swietokrzyskie' },
    { name: 'Warmińsko-mazurskie', value: 'warminskomazurskie' },
    { name: 'Wielkopolskie', value: 'wielkopolskie' },
    { name: 'Zachodniopomorskie', value: 'zachodniopomorskie' },
];

const grantCategoryOptions = [
    { name: 'Wczesne Wspomaganie Rozwoju (WWR)', value: 'EARLY_DEVELOPMENT_SUPPORT' },
    { name: 'Dotacja oświatowa', value: 'FOR_EDUCATION' },
];

const reportCategoryOptions: {
    name: string;
    value: ReportGrantCategory;
    subCategories?: { name: string; value: ReportGrantEducationalSubcategory }[];
}[] = [
    {
        name: 'Dotacja oświatowa',
        value: 'EDUCATIONAL',
        subCategories: [
            { name: 'dotacja podstawowa', value: 'EDUCATIONAL_HEALTHY_CHILDREN' },
            { name: 'kształcenie specjalne', value: 'EDUCATIONAL_DISABLED_CHILDREN' },
        ],
    },

    { name: 'Wczesne Wspomaganie Rozwoju (WWR)', value: 'EARLY_DEVELOPMENT_SUPPORT' },
];

const expenseCategoryOptions = [
    { name: 'Wczesne Wspomaganie Rozwoju (WWR)', value: 'EARLY_DEVELOPMENT_SUPPORT' },
    { name: 'Dotacja oświatowa', value: 'EDUCATIONAL' },
];

const getInstitutionsSelectOptionsFromInstitutionsList = (
    institutionList: Institution[],
    disabledInstitutions?: Institution['id'][],
) =>
    institutionList.map((institution) => ({
        value: institution.id,
        name: institution.name,
        disabled: !!disabledInstitutions?.find((disabledId) => disabledId === institution.id),
    }));

const findVoivodeshipsOption = (name: string) =>
    voivodeshipsOptions.find((item) => item.name.toLowerCase() === name.toLowerCase()) ||
    voivodeshipsOptions[0];

export {
    expenseCategoryOptions,
    voivodeshipsOptions,
    grantCategoryOptions,
    reportCategoryOptions,
    getInstitutionsSelectOptionsFromInstitutionsList,
    findVoivodeshipsOption,
};
