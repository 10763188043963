import { UserDetailsType } from '../interfaces/auth';
import { InstitutionId } from '../interfaces/institution';
import { Role } from '../interfaces/permissions';

const emailValidator = (value: string) =>
    /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value);

const emailValidatorWithMessage = (message?: string) => (value: string) =>
    emailValidator(value) || message || false;

const numberValidator = (value: string) => /^[0-9]*$/.test(value);

const noNumbersValidator = (message?: string) => (value: string) =>
    /^([^0-9]*)$/.test(value) || message || false;

const regonValidator = (message?: string) => (value: string) => {
    const controlSum =
        ((Number(value[0]) * 8 +
            Number(value[1]) * 9 +
            Number(value[2]) * 2 +
            Number(value[3]) * 3 +
            Number(value[4]) * 4 +
            Number(value[5]) * 5 +
            Number(value[6]) * 6 +
            Number(value[7]) * 7) %
            11) %
        10;

    if (value.length === 9) {
        return controlSum === Number(value[8]) || message || false;
    } else if (value.length === 14) {
        const secondControlSum =
            ((Number(value[0]) * 2 +
                Number(value[1]) * 4 +
                Number(value[2]) * 8 +
                Number(value[3]) * 5 +
                // fourth value is multiplied by 0
                Number(value[5]) * 9 +
                Number(value[6]) * 7 +
                Number(value[7]) * 3 +
                Number(value[8]) * 6 +
                Number(value[9]) +
                Number(value[10]) * 2 +
                Number(value[11]) * 4 +
                Number(value[12]) * 8) %
                11) %
            10;

        return (
            (controlSum === Number(value[8]) && secondControlSum === Number(value[13])) ||
            message ||
            false
        );
    }
    return message || false;
};

const nipValidator =
    (message?: string) =>
    (value: string, optional = false) =>
        (optional && !value) || (value.length === 10 && numberValidator(value)) || message || false;

const rspoValidator = (message?: string) => (value: string) =>
    (value.length > 3 && numberValidator(value)) || message || false;

const passwordValidator = (message?: string) => (value: string) =>
    value.length > 7 || message || false;

const zipCodeValidator = (message?: string) => (value: string) =>
    /^\d{2}-\d{3}$/.test(value) || message || false;

const maxExpenseValidator = (maxValue: number) => (value: string) =>
    Number(value) <= maxValue || false;

const isNotGreaterThanNumberValidator = (maxValue: number, message?: string) => (value: string) =>
    Number(value) <= maxValue || message || false;

const maxFilesSizeValidator = (message?: string) => (size: number) => (value: FileList) => {
    const FileArray = (value && Array.from(value)) || [];
    return !FileArray.find((file) => file.size > size) || message || false;
};

const maxFilesAmountValidator = (message?: string) => (amount: number) => (value: FileList) => {
    const FileArray = (value && Array.from(value)) || [];
    return FileArray.length <= amount || message || false;
};

const validateInstitutionLicence = (
    selectedInstitution?: InstitutionId,
    user?: UserDetailsType,
): boolean => {
    const institutionDetails = user?.assignedInstitutions?.find(
        (item) => item.id === selectedInstitution,
    );
    return user?.role === Role.OWNER && institutionDetails?.licence?.licenceStatus !== 'VALID';
};

export {
    emailValidator,
    emailValidatorWithMessage,
    regonValidator,
    rspoValidator,
    nipValidator,
    zipCodeValidator,
    passwordValidator,
    maxExpenseValidator,
    isNotGreaterThanNumberValidator,
    maxFilesAmountValidator,
    maxFilesSizeValidator,
    noNumbersValidator,
    validateInstitutionLicence,
};
