import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import * as reducers from './reducers';
import { ReportsState } from './state';

type Reducers = typeof reducers;

export const reportsSliceName = 'reports';

const initialState: ReportsState = {
    generateReportState: { loading: false },
};

const slice = createSlice<ReportsState, Reducers & { reset: () => ReportsState }>({
    name: reportsSliceName,
    initialState,
    reducers: { ...reducers, reset: () => initialState },
});

export const { actions, reducer } = slice;

export const selectState = (state: RootState) => state[reportsSliceName];
