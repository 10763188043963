import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Container, Stack, Typography, FormControl, Link as LinkMui } from '@mui/material';
import { useSelector } from 'react-redux';
import { LOGIN_PAGE } from 'consts/routes';
import { useAuth } from 'utils/hooks';
import { useAppTranslation } from 'utils/hooks/translation';
import { createInstituteHash } from 'utils/functions/string';
import { emailValidator, noNumbersValidator } from 'utils/functions/validators';
import {
    RegisterInstitutionForm,
    RegisterStepper,
    AppButton,
    RegisterStepOne,
    BackdropAppLoader,
} from 'components';
import { useErrorTranslation } from 'utils/functions/error';
import { selectors } from 'redux/slices/auth';
import { StyledMainStack, StyledSubHeaderTypography } from './RegisterPageStyled';
import { useSnackBar } from 'contexts/SnackbarContext/SnackbarContext';

const STEP_AMOUNT = 2;

const RegisterPage = () => {
    const { t: authT } = useAppTranslation('auth');
    const { t: commonT } = useAppTranslation('common');
    const { signUp } = useAuth();
    const { showSnackBar } = useSnackBar();
    const signState = useSelector(selectors.selectSignUpState);
    const [step, setStep] = useState<1 | 2>(1);
    const [instituteList, setInstituteList] = useState<string[]>([createInstituteHash()]);

    const { translateError } = useErrorTranslation('auth:registerPage');

    const form = useForm();
    const {
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        unregister,
        watch,
        setValue,
    } = form;

    const validateFirstStep = () => {
        const { firstName, lastName, email, password, termsAndPolicy } = getValues();
        return (
            firstName.length &&
            lastName.length &&
            password.length >= 8 &&
            termsAndPolicy &&
            emailValidator(email) &&
            noNumbersValidator()(firstName) &&
            noNumbersValidator()(lastName)
        );
    };

    const addInstituteToList = () => setInstituteList([...instituteList, createInstituteHash()]);

    const removeInstituteFromList = (instituteHash: string) => {
        if (instituteList.length > 1) {
            const copyInstituteList = [...instituteList];
            copyInstituteList.splice(
                instituteList.findIndex((institute) => institute === instituteHash),
                1,
            );
            setInstituteList(copyInstituteList);
            unregister(`institutionDtos.${instituteHash}`);
        }
    };

    const onNextClick = () => {
        const validated = validateFirstStep();
        clearErrors();
        if (!validated) {
            const { firstName, lastName, email, password, termsAndPolicy } = getValues();
            !firstName.length &&
                setError('firstName', { type: 'required', message: commonT('form.required') });
            !noNumbersValidator()(firstName) &&
                setError('firstName', { type: 'validate', message: commonT('form.noNumbers') });
            !lastName.length &&
                setError('lastName', { type: 'required', message: commonT('form.required') });
            !noNumbersValidator()(lastName) &&
                setError('lastName', { type: 'validate', message: commonT('form.noNumbers') });

            password.length < 8 &&
                setError('password', {
                    type: 'minLength',
                    message: authT('registerPage.errors.passwordLength'),
                });
            !emailValidator(email) &&
                setError('email', {
                    type: 'validate',
                    message: authT('registerPage.errors.email'),
                });
            !termsAndPolicy &&
                setError('termsAndPolicy', {
                    type: 'required',
                    message: authT('registerPage.errors.termsAndPolicy'),
                });
        } else {
            setStep(2);
        }
    };

    const onSubmit = (data: any) => {
        const institutionDtos = Object.values(data.institutionDtos);
        const { termsAndPolicy, ...dataWithoutTermsAndPolicy } = data;
        if (termsAndPolicy) {
            signUp({ ...dataWithoutTermsAndPolicy, institutionDtos: institutionDtos })
                .unwrap()
                .catch(() => {
                    showSnackBar(
                        commonT('snackbar.errorOccured'),
                        translateError('registerFailed') || '',
                        'error',
                    );
                });
        }
    };

    const watchPassword = watch('password');

    return (
        <>
            <Container>
                <StyledMainStack alignItems="center">
                    <Typography variant="h2" gutterBottom>
                        {authT('registerPage.title')}
                    </Typography>
                    <StyledSubHeaderTypography variant="h4">
                        <Trans i18nKey="auth:registerPage.subTitle">
                            Masz już konto?
                            <LinkMui
                                component={Link}
                                underline="none"
                                to={LOGIN_PAGE}
                                sx={{ fontWeight: 700 }}
                            >
                                Zaloguj się
                            </LinkMui>
                        </Trans>
                    </StyledSubHeaderTypography>
                    <FormProvider {...form}>
                        <FormControl fullWidth>
                            <Stack gap="19px" marginTop="34px" marginBottom="20px">
                                <RegisterStepper
                                    step={step}
                                    allSteps={STEP_AMOUNT}
                                    changeStep={setStep}
                                />
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    {step === 1 ? (
                                        <RegisterStepOne
                                            errors={errors}
                                            password={watchPassword}
                                            onNextClick={onNextClick}
                                        />
                                    ) : (
                                        <Stack gap="13px">
                                            {instituteList.map((instituteHash) => (
                                                <RegisterInstitutionForm
                                                    key={instituteHash}
                                                    hash={instituteHash}
                                                    onRemoveClick={removeInstituteFromList}
                                                    errors={errors}
                                                    deleteDisabled={instituteList.length === 1}
                                                    setValue={setValue}
                                                />
                                            ))}
                                            <AppButton
                                                variant="outlined"
                                                type="button"
                                                onClick={addInstituteToList}
                                            >
                                                {authT('registerPage.addInstituteButton')}
                                            </AppButton>

                                            <Typography variant="body2" textAlign="center">
                                                {authT('registerPage.minOneInstituteInfo')}
                                            </Typography>

                                            <AppButton type="submit" fullWidth>
                                                {authT('registerPage.button')}
                                            </AppButton>
                                        </Stack>
                                    )}
                                </form>
                            </Stack>
                        </FormControl>
                    </FormProvider>
                </StyledMainStack>
            </Container>
            <BackdropAppLoader open={signState.loading} />
        </>
    );
};

export default RegisterPage;
