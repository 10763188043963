import { PayloadAction } from '@reduxjs/toolkit';
import { SettlementState } from '../state';

export const initEditInstitution = (state: SettlementState) => {
    state.editInstitutionState = { loading: true };
};

export const clearEditInstitution = (state: SettlementState) => {
    state.editInstitutionState = { loading: false };
};

export const editInstitutionSuccess = (state: SettlementState) => {
    state.editInstitutionState = { loading: false, success: true };
};

export const editInstitutionError = (state: SettlementState, action: PayloadAction<string>) => {
    state.editInstitutionState = { loading: false, success: false, error: action.payload };
};
