import { Box, Icon, IconButton, styled } from '@mui/material';
import { AppButton } from 'components/common';

export const NavbarRow = styled(Box)`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    padding-right: 150px;
`;
export const LogoIconContainer = styled(Box)`
    margin-right: 100px;
    &:hover {
        cursor: pointer;
    }
`;
export const Logo = styled(Icon)`
    text-align: center;
    width: 156px;
    height: auto;
    margin: 40px 23px;
`;
export const Button = styled(AppButton, {
    shouldForwardProp: (prop) => prop !== 'styledColor',
})((props: { styledColor?: string }) => ({
    margin: '8px',
    color: props.styledColor,
}));
export const ButtonIconContainer = styled(Box)`
    text-align: center;
    width: 24px;
    height: 26px;
    margin-right: 7px;
`;
export const GrantButton = styled(Button)`
    margin-left: auto;
`;
export const AuthNavbarRow = styled(NavbarRow)`
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: rgb(121, 123, 137, 0.1);
    padding-right: 75px;
`;

export const AuthNavbarButton = styled(AppButton)`
    margin: 8px;
    padding-top: 13px;
    padding-bottom: 13px;
    border-radius: 8px;
    font-weight: 500;
`;
export const AuthNavbarContactButton = styled(AuthNavbarButton)`
    margin-right: auto;
`;
export const AuthNavbarRegisterButton = styled(AuthNavbarButton)`
    padding-right: 47px;
    padding-left: 47px;
`;
export const MobileAuthNavbarRow = styled(AuthNavbarRow)`
    padding-right: 16px;
`;

export const MobileLogoIconContainer = styled(LogoIconContainer)`
    margin-right: 0;
`;
export const MobileMenuButton = styled(IconButton)`
    margin-left: auto;
`;
