import React from 'react';
import { Modal, Stack, styled, Typography } from '@mui/material';
import { colors } from 'consts/colors';
import { AppButton } from '../AppButton';
import { BackdropAppLoader } from '../BackdropAppLoader';

type ConfirmModalProps = {
    open: boolean;
    onClose?: () => void;
    title?: string;
    message?: string;
    firstButtonLabel?: string;
    secondButtonLabel?: string;
    onFirstButtonClick?: () => void;
    onSecondButtonClick?: () => void;
    loading?: boolean;
};

const StyledModal = styled(Modal)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const StyledMainStack = styled(Stack)(() => ({
    backgroundColor: 'white',
    maxWidth: '600px',
    borderRadius: '4px',
}));

const StyledTitleTypography = styled(Typography)(() => ({
    color: colors.secondary,
    marginBottom: '16px',
}));

const StyledMessageTypography = styled(Typography)(() => ({
    color: colors.text3,
    fontFamily: 'DM Sans',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    marginBottom: '46px',
}));

const StyledSecondAppButton = styled(AppButton)(() => ({
    flex: 1,
    marginLeft: '8px',
}));

const StyledFirstAppButton = styled(AppButton)(() => ({
    flex: 1,
    marginRight: '8px',
}));

const ConfirmModal = ({
    open,
    onClose,
    title,
    message,
    firstButtonLabel,
    secondButtonLabel,
    onFirstButtonClick,
    onSecondButtonClick,
    loading,
}: ConfirmModalProps) => (
    <StyledModal open={open} onClose={onClose}>
        <StyledMainStack padding="32px" alignItems="center">
            {title && <StyledTitleTypography variant="h4">{title}</StyledTitleTypography>}
            {message && <StyledMessageTypography>{message}</StyledMessageTypography>}
            <Stack flexDirection="row" width="100%" justifyContent="space-between">
                {onFirstButtonClick && (
                    <StyledFirstAppButton onClick={onFirstButtonClick} variant="outlined">
                        {firstButtonLabel}
                    </StyledFirstAppButton>
                )}
                {onSecondButtonClick && (
                    <StyledSecondAppButton onClick={onSecondButtonClick}>
                        {secondButtonLabel}
                    </StyledSecondAppButton>
                )}
            </Stack>
            <BackdropAppLoader open={!!loading} />
        </StyledMainStack>
    </StyledModal>
);

export default ConfirmModal;
