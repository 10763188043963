import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import _ from 'lodash';
import { AlertColor } from '@mui/material';
import { AppSnackbar } from 'components';

type SnackBarProps = {
    open: boolean;
    title?: string;
    message?: string;
    type?: AlertColor;
};

type SnackBarContextActions = {
    showSnackBar: (
        title?: SnackBarProps['title'],
        message?: SnackBarProps['message'],
        type?: SnackBarProps['type'],
    ) => void;
};

const SnackBarContext = createContext({} as SnackBarContextActions);

interface SnackBarContextProviderProps {
    children: ReactNode;
}

const SnackBarProvider: React.FC<SnackBarContextProviderProps> = ({ children }) => {
    const [snackbar, setSnackbar] = useState<SnackBarProps>({ open: false });

    const showSnackBar = (
        title?: SnackBarProps['title'],
        message?: SnackBarProps['message'],
        type?: SnackBarProps['type'],
    ) => {
        setSnackbar({ open: true, message, title, type });
    };

    const handleClose = () => {
        setSnackbar((current) => ({ ...current, open: false }));
    };

    const value = useMemo(() => ({ showSnackBar }), [showSnackBar]);

    return (
        <SnackBarContext.Provider value={value}>
            <AppSnackbar
                open={snackbar.open}
                onClose={handleClose}
                type={snackbar.type}
                title={snackbar.title}
                message={snackbar.message}
            />
            {children}
        </SnackBarContext.Provider>
    );
};

const useSnackBar = (): SnackBarContextActions => {
    const context = useContext(SnackBarContext);

    if (_.isEmpty(context)) {
        throw new Error('useSnackBar must be used within an SnackBarProvider');
    }

    return context;
};

export { SnackBarProvider, useSnackBar };
