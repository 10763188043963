import React, { useEffect, useState } from 'react';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { useAppTranslation } from 'utils/hooks/translation';
import {
    StyledColumnTitle,
    StyledDescriptionTypography,
    StyledTabContainer,
    StyledPill,
    StyledTwoColumnsRow,
    StyledAddTypeButton,
} from './ExpenseTypesTab.styled';
import hash from 'object-hash';
import ExpenseTypeModal from '../../../../../components/ExpenseTypeModal/ExpenseTypeModal';
import ExpenseTypeListMoreMenu from '../../../../../components/ExpenseTypeList/ExpenseTypeListMoreMenu/ExpenseTypeListMoreMenu';
import { useSelector } from 'react-redux';
import {
    actions as settlementActions,
    selectors as settlementSelectors,
} from '../../../../../redux/slices/settlement';
import { useDispatch } from '../../../../../redux/store';
import { useSnackBar } from '../../../../../contexts/SnackbarContext/SnackbarContext';
import { DUPLICATE_EXPENSE_TYPE_NAME } from '../../../../../consts/errors';
import { BackdropAppLoader } from '../../../../../components';

const ExpenseTypesTab = () => {
    const dispatch = useDispatch();
    const { showSnackBar } = useSnackBar();
    const [isAddExpenseTypeModalOpen, setIsAddExpenseModalOpen] = useState(false);
    const { t: settingsT } = useAppTranslation('settings');
    const { t: commonT } = useAppTranslation('common');

    const expenseListT = (itemName: string) => settingsT(`expenseList.${itemName}`);
    const expenseFormT = (itemName: string) => settingsT(`expenseForm.${itemName}`);

    const addExpenseTypeState = useSelector(settlementSelectors.selectAddExpenseTypeState);
    const editExpenseTypeState = useSelector(settlementSelectors.selectEditExpenseTypeState);
    const deleteExpenseTypeState = useSelector(settlementSelectors.selectDeleteExpenseTypeState);

    const loading =
        addExpenseTypeState.loading ||
        editExpenseTypeState.loading ||
        deleteExpenseTypeState.loading;

    const expenseTypeList = useSelector(settlementSelectors.selectExpenseTypeList) || [];
    const getExpenseTypeListState = useSelector(settlementSelectors.selectGetExpenseTypeListState);

    useEffect(() => {
        if (addExpenseTypeState.success) {
            showSnackBar(
                commonT('snackbar.success'),
                expenseFormT('successfullyAddedExpenseType'),
                'success',
            );
            dispatch(settlementActions.clearAddExpenseType());
        } else if (addExpenseTypeState.error) {
            showSnackBar(
                commonT('snackbar.errorOccured'),
                expenseFormT(
                    addExpenseTypeState.error === DUPLICATE_EXPENSE_TYPE_NAME
                        ? 'errors.duplicatedExpenseType'
                        : 'errors.couldNotAddExpenseType',
                ),
                'error',
            );
            dispatch(settlementActions.clearAddExpenseType());
        }
    }, [addExpenseTypeState]);

    useEffect(() => {
        if (editExpenseTypeState.success) {
            showSnackBar(
                commonT('snackbar.success'),
                expenseFormT('successfullyEditedExpenseType'),
                'success',
            );
            dispatch(settlementActions.clearEditExpenseType());
        } else if (editExpenseTypeState.error) {
            showSnackBar(
                commonT('snackbar.errorOccured'),
                expenseFormT(
                    editExpenseTypeState.error === DUPLICATE_EXPENSE_TYPE_NAME
                        ? 'errors.duplicatedExpenseType'
                        : 'errors.couldNotAddExpenseType',
                ),
                'error',
            );
            dispatch(settlementActions.clearEditExpenseType());
        }
    }, [editExpenseTypeState]);

    useEffect(() => {
        if (deleteExpenseTypeState.success) {
            showSnackBar(
                commonT('snackbar.success'),
                expenseFormT('successfullyDeletedExpenseType'),
                'success',
            );
            dispatch(settlementActions.clearDeleteExpenseType());
        } else if (deleteExpenseTypeState.error) {
            showSnackBar(
                commonT('snackbar.errorOccured'),
                expenseFormT('errors.couldNotDeleteExpenseType'),
                'error',
            );
            dispatch(settlementActions.clearDeleteExpenseType());
        }
    }, [deleteExpenseTypeState]);

    const openExpenseTypeModal = () => {
        setIsAddExpenseModalOpen(true);
    };

    const closeExpenseTypeModal = () => {
        setIsAddExpenseModalOpen(false);
    };

    if (getExpenseTypeListState.loading) {
        return (
            <Stack paddingY="120px" alignItems="center">
                <CircularProgress />
            </Stack>
        );
    }

    return (
        <StyledTabContainer>
            <BackdropAppLoader open={loading} />
            <StyledTwoColumnsRow>
                <StyledColumnTitle>{expenseListT('title')}</StyledColumnTitle>
                <StyledAddTypeButton onClick={openExpenseTypeModal}>
                    {expenseListT('addExpenseType')}
                </StyledAddTypeButton>
            </StyledTwoColumnsRow>
            {expenseTypeList.length !== 0 ? (
                expenseTypeList.map((expenseType) => (
                    <StyledTwoColumnsRow key={hash(expenseType)}>
                        <StyledPill>
                            <StyledDescriptionTypography>
                                {expenseType.name}
                            </StyledDescriptionTypography>
                            <ExpenseTypeListMoreMenu expenseType={expenseType} />
                        </StyledPill>
                    </StyledTwoColumnsRow>
                ))
            ) : (
                <Typography variant="h5" textAlign="center">
                    {expenseListT('noExpenses')}
                </Typography>
            )}
            <ExpenseTypeModal
                isOpen={isAddExpenseTypeModalOpen}
                onClose={closeExpenseTypeModal}
                type="add"
            />
        </StyledTabContainer>
    );
};

export default ExpenseTypesTab;
