import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import * as reducers from './reducers';
import { SettingsState } from './state';

type Reducers = typeof reducers;

export const settingsSliceName = 'settings';

const initialState: SettingsState = {
    getFullInstitutionsListState: { loading: false },
    fullInstitutionsList: null,
};

const slice = createSlice<SettingsState, Reducers & { reset: () => SettingsState }>({
    name: settingsSliceName,
    initialState,
    reducers: { ...reducers, reset: () => initialState },
});

export const { actions, reducer } = slice;

export const selectState = (state: RootState) => state[settingsSliceName];
