import React, { useEffect } from 'react';
import { colors } from 'consts/colors';
import { styled } from '@mui/material';
import { useAppTranslation } from 'utils/hooks/translation';
import { isNotGreaterThanNumberValidator, maxExpenseValidator } from 'utils/functions/validators';
import Layout from '../Layout/Layout';
import { AppButton, Autocomplete, BackdropAppLoader, Input, Select } from '../common';
import { expenseCategoryOptions } from 'utils/functions/options';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useDispatch } from 'redux/store';
import { actions, selectors } from '../../redux/slices/settlement';

const DeleteButton = styled(AppButton)(() => ({
    color: colors.primary,
    textDecorationLine: 'underline',
    ':hover': {
        textDecorationLine: 'underline',
    },
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '22px',
    marginLeft: 'auto',
    marginRight: 0,
}));

type Props = {
    errors: any;
    id: string;
    disabledRemove: boolean;
    onRemoveClick: (id: string) => void;
};

const ExpenseForm = ({ errors, id, onRemoveClick, disabledRemove }: Props) => {
    const dispatch = useDispatch();
    const { t: commonT } = useAppTranslation('common');
    const { t: settlementT } = useAppTranslation('settlement');

    const { watch } = useFormContext();

    const getExpenseTypeListState = useSelector(selectors.selectGetExpenseTypeListState);
    const expenseTypeList = useSelector(selectors.selectExpenseTypeList);
    const expenseTypeOptions =
        expenseTypeList?.map((expenseType) => ({
            value: expenseType.id,
            name: expenseType.name,
        })) || [];

    const { loading } = getExpenseTypeListState;

    const selectedCategory = watch(`expenseDtos.${id}.category`);

    useEffect(() => {
        if (!expenseTypeList) {
            dispatch(actions.getExpenseTypeList());
        }
    }, [dispatch]);

    return (
        <Layout.Container>
            <BackdropAppLoader open={loading} />
            <DeleteButton
                disabled={disabledRemove}
                variant="inline"
                onClick={() => onRemoveClick(id)}
            >
                {commonT('button.delete')}
            </DeleteButton>
            <Select
                rules={{ required: commonT('form.required') }}
                name={`expenseDtos.${id}.category.type`}
                label={settlementT('addDocument.category')}
                options={expenseCategoryOptions}
                error={!!errors.expenseDtos?.[id]?.category?.type}
                helperText={errors.expenseDtos?.[id]?.category?.type?.message}
                defaultValue={expenseCategoryOptions[0].value}
            />
            <Autocomplete
                rules={{ required: commonT('form.required') }}
                name={`expenseDtos.${id}.expenseType`}
                label={settlementT('addDocument.expenseType')}
                options={expenseTypeOptions}
                error={!!errors.expenseDtos?.[id]?.expenseType}
                helperText={errors.expenseDtos?.[id]?.expenseType?.message}
            />
            <Input
                label={settlementT('addDocument.expenseName')}
                name={`expenseDtos.${id}.expenseName`}
                fullWidth
                error={!!errors.expenseDtos?.[id]?.expenseName}
                helperText={errors.expenseDtos?.[id]?.expenseName?.message}
            />
            <Input
                rules={{
                    required: commonT('form.required'),
                    validate: {
                        maxValue: maxExpenseValidator(100000000),
                        minValue: (value: number) =>
                            value > 0 || commonT('form.valueHasToBeGreaterThan0'),
                    },
                }}
                type="number"
                lang="pl"
                label={settlementT('addDocument.grossAmount')}
                name={`expenseDtos.${id}.grossAmount`}
                fullWidth
                error={!!errors.expenseDtos?.[id]?.grossAmount}
                helperText={errors.expenseDtos?.[id]?.grossAmount?.message}
            />
            {selectedCategory?.type === 'EDUCATIONAL' && (
                <Input
                    rules={{
                        required: commonT('form.required'),
                        validate: {
                            maxValue: isNotGreaterThanNumberValidator(
                                100,
                                commonT('form.valueCanNotBeGreaterThan100'),
                            ),
                            minValue: (value: number) =>
                                value >= 0 || commonT('form.valueHasToBeGreaterOrEqual0'),
                        },
                    }}
                    name={`expenseDtos.${id}.category.percent`}
                    label={settlementT('addDocument.percentAmountForChildrenWithCertificate')}
                    error={!!errors.expenseDtos?.[id]?.category?.percent}
                    helperText={errors.expenseDtos?.[id]?.category?.percent?.message}
                    type="number"
                    lang="pl"
                />
            )}
        </Layout.Container>
    );
};

export { ExpenseForm };
