export const MONTHS = [
    { name: 'Styczeń', value: '1' },
    { name: 'Luty', value: '2' },
    { name: 'Marzec', value: '3' },
    { name: 'Kwiecień', value: '4' },
    { name: 'Maj', value: '5' },
    { name: 'Czerwiec', value: '6' },
    { name: 'Lipiec', value: '7' },
    { name: 'Sierpień', value: '8' },
    { name: 'Wrzesień', value: '9' },
    { name: 'Październik', value: '10' },
    { name: 'Listopad', value: '11' },
    { name: 'Grudzień', value: '12' },
];
