import type { Institution, InstitutionDto } from 'utils/interfaces/institution';

export const mapDtoToInstitution = ({
    id,
    name,
    regon,
    rspo,
    address,
    licence,
}: InstitutionDto): Institution => ({
    id,
    name,
    regon,
    rspo,
    address,
    licence,
});
