import React from 'react';
import { Radio, RadioGroup, Stack } from '@mui/material';
import {
    StyledCategoryTypography,
    StyledFormControlLabel,
    StyledRadioLabelTypography,
} from './ReportGrantCategoryFilter.styled';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { reportCategoryOptions } from 'utils/functions/options';
import Checkboxes from 'components/common/Checkboxes/Checkboxes';
import { GenerateReportForm } from 'utils/interfaces/report';
import { useAppTranslation } from 'utils/hooks/translation';

const ReportGrantCategoryFilter = () => {
    const { t: reportsT } = useAppTranslation('reports');
    const generateReportT = (itemName: string) => reportsT(`generateReport.${itemName}`);

    const {
        control,
        setValue,
        setError,
        formState: { errors },
    } = useFormContext<GenerateReportForm>();

    const onRadioGroupChange =
        (field: ControllerRenderProps<GenerateReportForm, 'mainCategory'>) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setValue('subCategories', []);
            setError('subCategories', {});
            field.onChange(event);
        };

    return (
        <Stack>
            <StyledCategoryTypography variant="h5">
                {generateReportT('grantCategory')}
            </StyledCategoryTypography>

            <Controller
                name="mainCategory"
                defaultValue={reportCategoryOptions[0].value}
                control={control}
                render={({ field }) => (
                    <RadioGroup onChange={onRadioGroupChange(field)}>
                        {reportCategoryOptions.map((option) => (
                            <Stack key={option.value}>
                                <StyledFormControlLabel
                                    value={option.value}
                                    control={<Radio checked={field.value === option.value} />}
                                    label={
                                        <StyledRadioLabelTypography>
                                            {option.name}
                                        </StyledRadioLabelTypography>
                                    }
                                />
                                {option.subCategories && (
                                    <Stack marginLeft="20px">
                                        <Checkboxes
                                            name="subCategories"
                                            options={option.subCategories}
                                            rules={{
                                                required:
                                                    field.value === option.value
                                                        ? generateReportT(
                                                              'errors.subCategoryRequired',
                                                          )
                                                        : undefined,
                                            }}
                                            disabled={field.value !== option.value}
                                            error={errors.subCategories?.message}
                                        />
                                    </Stack>
                                )}
                            </Stack>
                        ))}
                    </RadioGroup>
                )}
            />
        </Stack>
    );
};

export default ReportGrantCategoryFilter;
