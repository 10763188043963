import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import SelectMui from '@mui/material/Select';
import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { SelectOptions } from 'utils/interfaces/common';
import { StyledMenuItem } from './Select.styled';
import { CheckboxIcon } from '../../../assets/icons';

type Props = {
    name: string;
    label: React.ReactNode;
    options: SelectOptions;
    rules?: any;
    multiple?: boolean;
    helperText?: string;
    error?: boolean;
} & Pick<React.ComponentProps<typeof Controller>, 'rules' | 'shouldUnregister' | 'defaultValue'> &
    React.ComponentProps<typeof SelectMui>;

const Select: React.FC<Props> = ({
    name,
    label,
    options,
    rules,
    shouldUnregister,
    multiple,
    defaultValue = multiple ? [] : options?.[0]?.value || '',
    helperText,
    error,
    ...selectProps
}) => {
    const { control } = useFormContext();
    const inputLabelId = `id-${name}`;

    function shouldRenderCheckbox(field: any, value: string | number) {
        return multiple && field.value.includes(value);
    }

    const renderValue = (selected: string | string[]) => {
        if (!multiple) {
            return options
                .filter((option) => selected === option.value)
                .map((record) => record.name);
        }
        return options
            .filter((option) => selected.includes(option.value.toString()))
            .map((record) => record.name)
            .join(', ');
    };

    return (
        <FormControl>
            <InputLabel id={inputLabelId} error={error}>
                {label}
            </InputLabel>
            <Controller
                name={name}
                control={control}
                rules={rules}
                shouldUnregister={shouldUnregister}
                defaultValue={defaultValue}
                render={({ field }) => (
                    <SelectMui
                        label={label}
                        labelId={inputLabelId}
                        {...field}
                        {...selectProps}
                        multiple={multiple}
                        error={error}
                        renderValue={renderValue}
                    >
                        {options.map((option) => (
                            <StyledMenuItem
                                value={option.value}
                                key={option.value}
                                disabled={option.disabled}
                            >
                                <p>{option.name}</p>
                                {shouldRenderCheckbox(field, option.value) && (
                                    <img src={CheckboxIcon} alt="selected" />
                                )}
                            </StyledMenuItem>
                        ))}
                    </SelectMui>
                )}
            />
            <FormHelperText error={error}>{helperText}</FormHelperText>
        </FormControl>
    );
};

export { Select };
