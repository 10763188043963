import React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { Stack, Typography, styled } from '@mui/material';
import { useAppTranslation } from 'utils/hooks/translation';

const StyledDropzone = styled(Stack)`
    height: 48px;
    border: 1px dashed #3a48e2;
    border-radius: 4px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
`;

type Props = { onChange: (event: any) => void } & DropzoneOptions;

const Dropzone = ({ multiple, maxFiles, onChange, ...rest }: Props) => {
    const { t: settlementT } = useAppTranslation('settlement');
    const { getRootProps, getInputProps } = useDropzone({
        maxFiles,
        multiple,
        ...rest,
    });

    return (
        <StyledDropzone {...getRootProps()}>
            <Typography
                color={(theme) => theme.palette.primary.main}
                fontWeight={700}
                fontSize="14px"
                lineHeight="20px"
                fontFamily="DM Sans"
                letterSpacing="-0.2px"
            >
                {settlementT('addDocument.addInvoice')}
            </Typography>
            <input {...getInputProps({ onChange })} />
        </StyledDropzone>
    );
};

export { Dropzone };
