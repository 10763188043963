import { PayloadAction } from '@reduxjs/toolkit';
import { ExpenseType } from 'utils/interfaces/document';
import { SettlementState } from '../state';

export const initGetExpenseTypeList = (state: SettlementState) => {
    state.getExpenseTypeListState = { loading: true };
};

export const initGetAllExpenseTypeList = (state: SettlementState) => {
    state.getAllExpenseTypeListState = { loading: true };
};
export const getExpenseTypeListSuccess = (
    state: SettlementState,
    action: PayloadAction<ExpenseType[]>,
) => {
    state.getExpenseTypeListState = { loading: false, success: true };
    state.expenseTypeList = action.payload;
};

export const getAllExpenseTypeListSuccess = (
    state: SettlementState,
    action: PayloadAction<string[]>,
) => {
    state.getAllExpenseTypeListState = { loading: false, success: true };
    state.allExpenseTypeList = action.payload;
};

export const getExpenseTypeListError = (state: SettlementState, action: PayloadAction<string>) => {
    state.getExpenseTypeListState = { loading: false, success: false, error: action.payload };
};
export const getAllExpenseTypeListError = (
    state: SettlementState,
    action: PayloadAction<string>,
) => {
    state.getAllExpenseTypeListState = { loading: false, success: false, error: action.payload };
};
export const setExpenseTypeList = (
    state: SettlementState,
    action: PayloadAction<ExpenseType[]>,
) => {
    state.expenseTypeList = action.payload;
};
