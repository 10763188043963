import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DocumentIcon, ELFLogo, SettingsIcon } from 'assets/icons';
import { colors } from 'consts/colors';
import { HOME_PAGE, SETTINGS_PAGE } from 'consts/routes';
import { useAppTranslation } from 'utils/hooks/translation';
import GenerateReportModal from 'components/GenerateReportModal/GenerateReportModal';
import { LANDING_URL } from 'consts/links';
import {
    Button,
    ButtonIconContainer,
    GrantButton,
    Logo,
    LogoIconContainer,
    NavbarRow,
} from '../LayoutNavbarStyled';
import { useSelector } from 'react-redux';
import {
    actions as settlementActions,
    selectors as settlementSelectors,
} from '../../../../redux/slices/settlement';
import { useDispatch } from '../../../../redux/store';
import { RequestPaymentDialog } from '../../../RequestPaymentDialog/RequestPaymentDialog';
import { selectors as userSelectors } from '../../../../redux/slices/auth';
import { validateInstitutionLicence } from '../../../../utils/functions/validators';
import { useAccessManager } from '../../../../utils/hooks';
import { Permission } from '../../../../utils/interfaces/permissions';

type AppNavbarProps = {
    goToHomePage?: () => void;
    goToSettingsPage?: () => void;
    onAddGrantButtonClick?: () => void;
    onSendDocumentButtonClick?: () => void;
    logoutButtonOnClick?: () => void;
};

const AppNavbar = ({
    goToHomePage,
    goToSettingsPage,
    onAddGrantButtonClick,
    onSendDocumentButtonClick,
    logoutButtonOnClick,
}: AppNavbarProps) => {
    const { canAccess } = useAccessManager();
    const location = useLocation();
    const dispatch = useDispatch();
    const user = useSelector(userSelectors.selectUser);
    const { t: commonT } = useAppTranslation('common');
    const { t: authT } = useAppTranslation('auth');
    const selectedInstitution = useSelector(settlementSelectors.selectSelectedInstitution);
    const userInstitutions = user?.assignedInstitutions || [];

    const commonLayoutT = (itemName: string) => commonT(`layout.${itemName}`);

    const [isGenerateRaportModalOpen, setIsGenerateRaportModalOpen] = useState(false);
    const [isRequestPaymentModalOpen, setIsRequestPaymentModalOpen] = useState(false);

    const onGenerateRaportModalClose = () => setIsGenerateRaportModalOpen(false);
    const onRequestPaymentModalClose = () => setIsRequestPaymentModalOpen(false);

    useEffect(() => {
        if (!selectedInstitution) {
            dispatch(settlementActions.setSelectedInstitution(userInstitutions[0]?.id));
        }
    }, []);

    const onGenerateReportButtonClick = () => {
        if (validateInstitutionLicence(selectedInstitution, user)) {
            setIsRequestPaymentModalOpen(true);
        } else {
            setIsGenerateRaportModalOpen(true);
        }
    };

    const sendDocumentWithValidation = () => {
        if (validateInstitutionLicence(selectedInstitution, user)) {
            setIsRequestPaymentModalOpen(true);
        } else {
            onSendDocumentButtonClick?.();
        }
    };

    return (
        <>
            <NavbarRow>
                <a href={LANDING_URL}>
                    <LogoIconContainer>
                        <Logo>
                            <img src={ELFLogo} alt="logo" />
                        </Logo>
                    </LogoIconContainer>
                </a>
                <Button
                    variant="text"
                    onClick={goToHomePage}
                    styledColor={
                        location.pathname === HOME_PAGE ? colors.primary : colors.secondary
                    }
                    startIcon={
                        <ButtonIconContainer>
                            <DocumentIcon
                                fill={
                                    location.pathname === HOME_PAGE ? colors.primary : colors.text4
                                }
                            />
                        </ButtonIconContainer>
                    }
                >
                    {commonLayoutT('documents')}
                </Button>

                {canAccess(Permission.SETTINGS_VIEW) && (
                    <Button
                        variant="text"
                        onClick={goToSettingsPage}
                        styledColor={
                            location.pathname.match(`${SETTINGS_PAGE.slice(0, -1)}.*`)
                                ? colors.primary
                                : colors.secondary
                        }
                        startIcon={
                            <ButtonIconContainer>
                                <SettingsIcon
                                    fill={
                                        location.pathname.match(`${SETTINGS_PAGE.slice(0, -1)}.*`)
                                            ? colors.primary
                                            : colors.text4
                                    }
                                />
                            </ButtonIconContainer>
                        }
                    >
                        {commonLayoutT('settings')}
                    </Button>
                )}
                <GrantButton variant="outlined" onClick={onAddGrantButtonClick}>
                    {commonLayoutT('addGrant')}
                </GrantButton>

                <Button variant="outlined" onClick={onGenerateReportButtonClick}>
                    {commonLayoutT('generateReport')}
                </Button>

                <Button onClick={sendDocumentWithValidation}>
                    {commonLayoutT('sendDocument')}
                </Button>

                <Button variant="text" onClick={logoutButtonOnClick}>
                    {authT('LogOut')}
                </Button>
            </NavbarRow>
            <GenerateReportModal
                isGenerateRaportModalOpen={isGenerateRaportModalOpen}
                onGenerateRaportModalClose={onGenerateRaportModalClose}
            />
            <RequestPaymentDialog
                open={isRequestPaymentModalOpen}
                onClose={onRequestPaymentModalClose}
            />
        </>
    );
};
export default AppNavbar;
