import { MenuItem, styled } from '@mui/material';

export const StyledMenuItem = styled(MenuItem)({
    '*': {
        margin: 0,
    },
    img: {
        marginLeft: 'auto',
        width: 16,
        height: 16,
    },
});
