import { PayloadAction } from '@reduxjs/toolkit';
import { UsersState } from '../state';

export const initDeleteUser = (state: UsersState) => {
    state.deleteUserState = { loading: true };
};

export const clearDeleteUser = (state: UsersState) => {
    state.deleteUserState = { loading: false };
};

export const deleteUserSuccess = (state: UsersState) => {
    state.deleteUserState = { loading: false, success: true };
};

export const deleteUserError = (state: UsersState, action: PayloadAction<string>) => {
    state.deleteUserState = { loading: false, success: false, error: action.payload };
};
