import { Institution } from 'utils/interfaces/institution';

export const addDocument = (institutionId: string) =>
    `/api/v1/institutions/${institutionId}/documents`;
export const getDocument = (institutionId: Institution['id'], documentId: string) =>
    `/api/v1/institutions/${institutionId}/documents/${documentId}`;
export const documentList = (institutionId: string) =>
    `/api/v1/institutions/${institutionId}/documents`;
export const documentCostsList = (institutionId: string) =>
    `/api/v1/institutions/${institutionId}/documents/costs`;
