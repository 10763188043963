import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {Stack, styled, Typography} from '@mui/material';
import {MAX_FILES_AMOUNT} from 'consts/file';
import {FileGallery} from './FileGallery';
import {Dropzone} from './Dropzone';
import HTTPService from 'services/HTTPService/HTTPService';
import {instantDownloadFileFromBlob} from 'utils/functions/download';

const StyledError = styled(Typography)`
    color: ${({ theme }) => `${theme.palette.error.main}`};
`;

type FileDropzoneType = {
    multiple: boolean;
    name: string;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    showSnackBar:any;
    commonT:any;
    settlementT:any;
} & Pick<React.ComponentProps<typeof Controller>, 'rules' | 'shouldUnregister' | 'defaultValue'>;

const FileDropzone = ({
    multiple,
    name,
    rules,
    loading,
    setLoading,
                          showSnackBar,
                          commonT,
                          settlementT,
    ...rest
}: FileDropzoneType) => {
    const { control,setValue,setError,formState:{errors} } = useFormContext();

    const onRemove = (field: any) => (index: number) => {
        const values = Array.from(field.value);
        values.splice(index, 1);
        field.onChange(values);
    };

    const onClick =
        (values: Array<{ fileName: string; fileUri: string }>) => async (index: number) => {
            const res = await HTTPService.getFile(values[index].fileUri);
            const blob = new Blob([res.data], { type: res.headers['content-type'] });
            instantDownloadFileFromBlob(blob, values[index].fileName);
        };

    const addTemporaryFiles = async (field: any, files: File[]) => {
        setLoading(true);
        if (multiple) {
            if (field.value) {
                const newFilesWithoutNameRepetitions = files.filter(
                    (eventFile: any) =>
                        !Array.from(field.value).some(
                            (file: any) => file.fileName === eventFile.name,
                        ),
                );

                Promise.all(
                    newFilesWithoutNameRepetitions.map((file) => {
                        const formData = new FormData();
                        formData.append('file', file);
                        return HTTPService.postTemporaryFile(formData);
                    }),
                ).then(res=> {

                    field.onChange([
                        ...field.value,
                        ...res.map((item) => ({
                            fileUri: item.data.fileUri,
                            fileName: item.data.fileName,
                        })),
                    ]);
                })
                    .catch(e=>{
                        if(e.response.status===409){
                            showSnackBar(
                                commonT('snackbar.errorOccured'),
                                settlementT('addDocument.fileDuplication'),
                                'error',
                            );
                        }
                        setLoading(false);
                    })
            } else {
                 Promise.all(
                    files.map((file) => {
                        const formData = new FormData();
                        formData.append('file', file);
                        return HTTPService.postTemporaryFile(formData);
                    }),
                ).then((res)=>{
                    field.onChange(
                        res.map((item) => ({
                            fileUri: item.data.fileUri,
                            fileName: item.data.fileName,
                        })),

                    );
                     setLoading(false);
                }).catch(e=>{
                    console.log('e.response ',e.response)
                     if(e.response.status===409){
                         showSnackBar(
                             commonT('snackbar.errorOccured'),
                             settlementT('addDocument.fileDuplication'),
                             'error',
                         );
                    }
                     setLoading(false);
                    })

            }
        } else {
            const formData = new FormData();
            formData.append('file', files[0]);
               HTTPService.postTemporaryFile(formData).then(res=>{
                field.onChange({
                    fileUri: res.data.fileUri,
                    fileName: res.data.fileName,
                });
                    setLoading(false);

                }
            ).
            catch (e=>{
                if(e.response.status===409){
                    showSnackBar(
                        commonT('snackbar.errorOccured'),
                        settlementT('addDocument.fileDuplication'),
                        'error',
                    );
                }
                    setLoading(false);
            })

        }

    };
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => (
                <Stack>
                    <Dropzone
                        multiple
                        maxFiles={MAX_FILES_AMOUNT}
                        onChange={(event: any) =>
                            addTemporaryFiles(field, Array.from(event.target.files))
                        }
                        onDrop={(acceptedFiles) => addTemporaryFiles(field, acceptedFiles)}
                        disabled={loading}
                        {...rest}
                    />
                    {(field.value || loading) && (
                        <FileGallery
                            items={field.value}
                            onRemoveClick={onRemove(field)}
                            loading={loading}
                            onClick={onClick(field.value)}
                        />
                    )}
                    {fieldState.error && <StyledError>{fieldState.error.message}</StyledError>}
                </Stack>
            )}
        />
    );
};

export { FileDropzone };

