import { createSelector } from '@reduxjs/toolkit';
import { selectState } from '../slice';
import type { SettlementState } from '../state';

const selectGetInstitutionListState = createSelector(
    selectState,
    (state: SettlementState) => state.getInstitutionListState,
);

const selectInstitutionList = createSelector(
    selectState,
    (state: SettlementState) => state.institutionList,
);

const selectAddInstitutionState = createSelector(
    selectState,
    (state: SettlementState) => state.addInstitutionState,
);
const selectEditInstitutionState = createSelector(
    selectState,
    (state: SettlementState) => state.editInstitutionState,
);
const selectDeleteInstitutionState = createSelector(
    selectState,
    (state: SettlementState) => state.deleteInstitutionState,
);

export {
    selectGetInstitutionListState,
    selectInstitutionList,
    selectAddInstitutionState,
    selectEditInstitutionState,
    selectDeleteInstitutionState,
};
