import { PayloadAction } from '@reduxjs/toolkit';
import { SettlementState } from '../state';

export const initEditExpenseType = (state: SettlementState) => {
    state.editExpenseTypeState = { loading: true };
};

export const clearEditExpenseType = (state: SettlementState) => {
    state.editExpenseTypeState = { loading: false };
};

export const editExpenseTypeSuccess = (state: SettlementState) => {
    state.editExpenseTypeState = { loading: false, success: true };
};

export const editExpenseTypeError = (state: SettlementState, action: PayloadAction<string>) => {
    state.editExpenseTypeState = { loading: false, success: false, error: action.payload };
};
