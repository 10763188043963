import React, { useState } from 'react';
import { FormControl, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FormProvider, useForm } from 'react-hook-form';
import { BackdropAppLoader, Input, MaskInput, Modal, Select } from 'components/common';
import { useAppTranslation } from 'utils/hooks/translation';
import { useDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import {
    noNumbersValidator,
    regonValidator,
    rspoValidator,
    zipCodeValidator,
} from 'utils/functions/validators';
import { findVoivodeshipsOption, voivodeshipsOptions } from 'utils/functions/options';
import { zipCodeMask } from 'consts/masks';
import {
    actions as settlementActions,
    selectors as settlementSelectors,
} from 'redux/slices/settlement';
import { selectors as settingsSelectors } from 'redux/slices/settings';

import {
    InstitutionModalVariant,
    InstitutionFormData,
    FullInstitution,
} from 'utils/interfaces/institution';
import ConfirmModal from 'components/common/ConfirmModal/ConfirmModal';
import { StyledActionAppButton, StyledLineStack } from './InstitutionModal.styled';
import HTTPService from '../../services/HTTPService/HTTPService';
import { logAxiosError } from '../../utils/errors';
import { TypographyWithValidationError } from '../common/FormError/ValidationErrorText.styled';
import { CAN_NOT_DELETE_OWNER_LAST_INSTITUTION } from '../../consts/errors';

type InstitutionModalProps = {
    isOpen: boolean;
    onClose: () => void;
    type: InstitutionModalVariant;
    institution?: FullInstitution;
};

const InstitutionModal = ({ isOpen, onClose, type, institution }: InstitutionModalProps) => {
    const dispatch = useDispatch();
    const addInstitutionState = useSelector(settlementSelectors.selectAddInstitutionState);
    const editInstitutionState = useSelector(settlementSelectors.selectEditInstitutionState);
    const fullInstitutionList = useSelector(settingsSelectors.selectFullInstitutionsList);

    const [isDeleteInstitutionConfirmModalOpen, setIsDeleteInstitutionConfirmModalOpen] =
        useState(false);
    const [gusDataNotFound, setGusDataNotFound] = useState(false);

    const { t: settingsT } = useAppTranslation('settings');
    const { t: commonT } = useAppTranslation('common');

    const institutionFormT = (itemName: string) => settingsT(`institutionForm.${itemName}`);
    const institutionListT = (itemName: string) => settingsT(`institutionsList.${itemName}`);

    const form = useForm<InstitutionFormData>({
        defaultValues: {
            name: institution?.name || '',
            address: {
                street: institution?.address.street || '',
                voivodeship: institution?.address.voivodeship || voivodeshipsOptions[0].value,
                zipCode: institution?.address.zipCode || '',
                city: institution?.address.city || '',
            },
        },
    });
    const {
        handleSubmit,
        formState: { errors },
        setValue,
    } = form;

    const getTitle = () => {
        switch (type) {
            case 'add':
                return institutionFormT('addInstitution');
            default:
                return institutionFormT('editInstitution');
        }
    };

    const closeDeleteInstitutionConfirmModal = () => setIsDeleteInstitutionConfirmModalOpen(false);
    const openDeleteInstitutionConfirmModal = () => setIsDeleteInstitutionConfirmModalOpen(true);

    const onConfirmDeleteInstitutionClick = () => {
        if (fullInstitutionList?.length && fullInstitutionList.length > 1) {
            institution &&
                dispatch(settlementActions.deleteInstitution({ institutionId: institution.id }));
            closeDeleteInstitutionConfirmModal();
            onClose();
        } else {
            dispatch(
                settlementActions.deleteInstitutionError(CAN_NOT_DELETE_OWNER_LAST_INSTITUTION),
            );
        }
    };

    const onSubmit = (data: any) => {
        if (type === 'add') {
            dispatch(settlementActions.addInstitution(data))
                .unwrap()
                .then(() => onClose());
        }
        if (type === 'edit') {
            dispatch(settlementActions.editInstitution({ ...institution, ...data }))
                .unwrap()
                .then(() => onClose());
        }
    };

    const fetchGUSDataAndSetFormValues = (regon: string) => {
        HTTPService.getGUSData(regon)
            .then(({ data }) => {
                setGusDataNotFound(false);
                setValue('name', data.name);
                setValue('rspo', data.rspo);
                setValue(
                    'address.street',
                    `${data.street} ${data.buildingNumber}${
                        data.premisesNumber && ` / ${data.premisesNumber}`
                    }`,
                );
                setValue('address.zipCode', data.zipCode);
                setValue('address.city', data.city);
                setValue('address.voivodeship', findVoivodeshipsOption(data.province)?.value);
            })
            .catch((error) => {
                if (error.response.data.status === 'NOT_FOUND') {
                    setGusDataNotFound(true);
                }
                logAxiosError(error);
            });
    };

    const onRegonChange = (event: any) => {
        const value = event.target.value.trim();
        setValue('regon', value);
        setGusDataNotFound(false);

        if (regonValidator('')(value)) {
            fetchGUSDataAndSetFormValues(value);
        }
    };

    return (
        <>
            <Modal
                open={isOpen}
                maxWidth="910px"
                body={
                    <Stack flexDirection="column" width="100%">
                        <Stack
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Typography variant="h5" color="secondary">
                                {getTitle()}
                            </Typography>
                            <IconButton color="primary" onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Stack>

                        <FormProvider {...form}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <StyledLineStack marginTop="23px" marginBottom="34px" />
                                <FormControl fullWidth>
                                    <Stack gap="35px" width="100%">
                                        <Input
                                            label={institutionFormT('instituteNameLabel')}
                                            rules={{ required: commonT('form.required') }}
                                            name="name"
                                            fullWidth
                                            error={!!errors?.name}
                                            helperText={errors?.name?.message || ''}
                                        />
                                        {type === 'add' && (
                                            <Stack direction="row" gap="25px">
                                                <Stack width="100%">
                                                    <Input
                                                        label={institutionFormT('regonLabel')}
                                                        rules={{
                                                            validate: {
                                                                regonValidator: regonValidator(
                                                                    institutionFormT(
                                                                        'errors.regon',
                                                                    ),
                                                                ),
                                                            },
                                                        }}
                                                        name="regon"
                                                        fullWidth
                                                        error={!!errors?.regon}
                                                        helperText={errors?.regon?.message || ''}
                                                        onChange={onRegonChange}
                                                    />
                                                    <TypographyWithValidationError
                                                        displayError={gusDataNotFound}
                                                        variant="body1"
                                                    >
                                                        {gusDataNotFound
                                                            ? institutionFormT(
                                                                  'regonGUSDataNotFound',
                                                              )
                                                            : institutionFormT('regonGUSData')}
                                                    </TypographyWithValidationError>
                                                </Stack>
                                                <Input
                                                    label={institutionFormT('rspoLabel')}
                                                    rules={{
                                                        validate: {
                                                            rspoValidator: rspoValidator(
                                                                institutionFormT('errors.rspo'),
                                                            ),
                                                        },
                                                    }}
                                                    name="rspo"
                                                    fullWidth
                                                    error={!!errors?.rspo}
                                                    helperText={errors?.rspo?.message || ''}
                                                />
                                            </Stack>
                                        )}
                                        <Input
                                            label={institutionFormT('streetAndNumberLabel')}
                                            rules={{ required: commonT('form.required') }}
                                            name="address.street"
                                            fullWidth
                                            error={!!errors.address?.street}
                                            helperText={errors.address?.street?.message || ''}
                                        />
                                        <Stack direction="row" gap="25px">
                                            <MaskInput
                                                label={institutionFormT('zipCodeLabel')}
                                                rules={{
                                                    validate: {
                                                        zipCodeValidator: zipCodeValidator(
                                                            institutionFormT('errors.zipCode'),
                                                        ),
                                                    },
                                                }}
                                                name="address.zipCode"
                                                fullWidth
                                                error={!!errors.address?.zipCode}
                                                helperText={errors.address?.zipCode?.message || ''}
                                                mask={zipCodeMask}
                                            />
                                            <Input
                                                label={institutionFormT('cityLabel')}
                                                rules={{
                                                    required: commonT('form.required'),
                                                    validate: {
                                                        noNumber: noNumbersValidator(
                                                            commonT('form.noNumbers'),
                                                        ),
                                                    },
                                                }}
                                                name="address.city"
                                                fullWidth
                                                error={!!errors.address?.city}
                                                helperText={errors.address?.city?.message || ''}
                                            />
                                        </Stack>
                                        <Select
                                            label={institutionFormT('voivodeshipLabel')}
                                            rules={{ required: commonT('form.required') }}
                                            name="address.voivodeship"
                                            options={voivodeshipsOptions}
                                            fullWidth
                                        />
                                    </Stack>
                                </FormControl>
                                <StyledLineStack marginTop="34px" marginBottom="17px" />
                                <Stack
                                    flexDirection="row"
                                    width="100%"
                                    justifyContent="space-between"
                                >
                                    <StyledActionAppButton type="submit">
                                        {institutionFormT('save')}
                                    </StyledActionAppButton>
                                    {type === 'edit' && (
                                        <StyledActionAppButton
                                            variant="outlined"
                                            onClick={openDeleteInstitutionConfirmModal}
                                        >
                                            {institutionFormT('deleteInstitution')}
                                        </StyledActionAppButton>
                                    )}
                                </Stack>
                            </form>
                        </FormProvider>
                        <BackdropAppLoader
                            open={addInstitutionState.loading || editInstitutionState.loading}
                        />
                    </Stack>
                }
                actionButtons={<div />}
            />
            <ConfirmModal
                open={isDeleteInstitutionConfirmModalOpen}
                onClose={closeDeleteInstitutionConfirmModal}
                title={institutionListT('areYouSureYouWantToDeleteInstitution')}
                message={institutionListT('afterDeletingInstitutionYouWillLoseSettlementHistory')}
                firstButtonLabel={institutionListT('notYet')}
                secondButtonLabel={institutionListT('yesDelete')}
                onFirstButtonClick={closeDeleteInstitutionConfirmModal}
                onSecondButtonClick={onConfirmDeleteInstitutionClick}
            />
        </>
    );
};

export default InstitutionModal;
