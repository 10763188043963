import React from 'react';
import { Icon, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { AlertCircleOutlineIcon } from 'assets/icons';
import { Table } from 'components/common';
import { colors } from 'consts/colors';
import {
    selectDocumentsCostsList,
    selectDocumentsCostsListFetchingState,
} from 'redux/slices/settlement/selectors';
import { BACKEND_DATE_FORMAT, DOCUMENTS_LIST_SALE_DATE_FORMAT } from 'utils/functions/dates';
import { useAppTranslation } from 'utils/hooks/translation';
import { PAID_STATUS, UNPAID_STATUS } from 'consts/payments';
import DocumentTableMoreMenu from '../DocumentTableMoreMenu/DocumentTableMoreMenu';
import { StyledValueAndStatusTableCellTypography } from './DocumentsCostsListTable.styled';
import { SortData } from 'utils/interfaces/common';

dayjs.extend(customParseFormat);

type DocumentsCostsListTableProps = {
    rowsPerPageOptions?: number[];
    count?: number;
    rowsPerPage?: number;
    page?: number;
    sortData?: SortData;
    onSortClick?: (columnKey: string) => void;
    onPageChange?: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        page: number,
    ) => void;
    onRowsPerPageChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

const DocumentsCostsListTable = ({
    rowsPerPageOptions,
    count,
    rowsPerPage,
    page,
    sortData,
    onSortClick,
    onPageChange,
    onRowsPerPageChange,
}: DocumentsCostsListTableProps) => {
    const documentsCostsList = useSelector(selectDocumentsCostsList);
    const documentsCostsListFetchingState = useSelector(selectDocumentsCostsListFetchingState);

    const { t: settlementT } = useAppTranslation('settlement');
    const docsT = (itemName: string) => settlementT(`documentsList.${itemName}`);

    const getValueFontColorFromStatus = (status?: string) => {
        switch (status) {
            case UNPAID_STATUS:
                return colors.error;
            case PAID_STATUS:
                return colors.green1;
            default:
                return colors.green1;
        }
    };

    const getStatusLabelTextFromStatus = (status: string) => {
        switch (status) {
            case UNPAID_STATUS:
                return docsT('noPayment');
            case PAID_STATUS:
                return docsT('paymentMade');
            default:
                return '';
        }
    };

    const getStatusIconFromStatus = (status: string) => {
        switch (status) {
            case UNPAID_STATUS:
                return <AlertCircleOutlineIcon />;
            default:
                return null;
        }
    };

    return (
        <Table
            template={[
                { title: docsT('documentName'), key: 'documentName', sortable: true, width: '15%' },
                { title: docsT('costType'), key: 'costType', sortable: true, width: '29%' },
                {
                    title: docsT('saleDate'),
                    key: 'saleDate',
                    sortable: true,
                    render: (data) =>
                        dayjs(data.saleDate, BACKEND_DATE_FORMAT).format(
                            DOCUMENTS_LIST_SALE_DATE_FORMAT,
                        ),
                    width: '16%',
                },
                {
                    title: docsT('grossAmount'),
                    key: 'grossAmount',
                    sortable: true,
                    render: (data) => (
                        <StyledValueAndStatusTableCellTypography
                            styledColor={getValueFontColorFromStatus(data.costDocumentStatus)}
                        >
                            {`${Number(data.grossAmount).toFixed(2)}ZŁ`}
                        </StyledValueAndStatusTableCellTypography>
                    ),
                    width: '18%',
                },
                {
                    title: docsT('costDocumentStatus'),
                    key: 'costDocumentStatus',
                    sortable: true,
                    render: (data) =>
                        data.costDocumentStatus && (
                            <Stack flexDirection="row" alignItems="center">
                                <Icon>{getStatusIconFromStatus(data.costDocumentStatus)}</Icon>
                                <StyledValueAndStatusTableCellTypography
                                    styledColor={
                                        data.costDocumentStatus
                                            ? getValueFontColorFromStatus(data.costDocumentStatus)
                                            : undefined
                                    }
                                >
                                    {getStatusLabelTextFromStatus(data.costDocumentStatus)}
                                </StyledValueAndStatusTableCellTypography>
                            </Stack>
                        ),
                    width: '12%',
                },
                {
                    title: '',
                    key: 'action',
                    render: (data) => <DocumentTableMoreMenu documentId={data.documentId} />,
                    width: '10%',
                },
            ]}
            data={documentsCostsList || []}
            noDataPlaceholder={
                <Stack width="100%" alignItems="center">
                    <Typography marginTop="160px" marginBottom="160px">
                        {docsT('thereAreNoDucumentsProvidedForThisInstitution')}
                    </Typography>
                </Stack>
            }
            loading={documentsCostsListFetchingState.loading}
            paginated={!documentsCostsListFetchingState.loading}
            rowsPerPageOptions={rowsPerPageOptions}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            sortProps={
                sortData && onSortClick
                    ? {
                          sortBy: sortData.columnKey,
                          direction: sortData.direction,
                          onSortColumnClick: onSortClick,
                      }
                    : undefined
            }
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
        />
    );
};

export default DocumentsCostsListTable;
