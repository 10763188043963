import { PayloadAction } from '@reduxjs/toolkit';
import { SettlementState } from '../state';

export const initAddInstitutionLicensePayment = (state: SettlementState) => {
    state.addInstitutionLicensePaymentState = { loading: true };
};

export const addInstitutionLicensePaymentSuccess = (state: SettlementState) => {
    state.addInstitutionLicensePaymentState = { loading: false, success: true };
};

export const addInstitutionLicensePaymentError = (
    state: SettlementState,
    action: PayloadAction<string>,
) => {
    state.addInstitutionLicensePaymentState = {
        loading: false,
        success: false,
        error: action.payload,
    };
};
