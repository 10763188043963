import { FormControlLabel, styled, Typography } from '@mui/material';
import { colors } from 'consts/colors';

export const StyledLabelTypography = styled(Typography)({
    fontWeight: 500,
});

export const StyledCheckboxLabelTypography = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'disabled',
})((props: { disabled?: boolean }) => ({
    fontWeight: 400,
    fontFamily: 'DM Sans',
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '-0.4px',
    color: props.disabled ? colors.lightGray : colors.black100,
}));

export const StyledFormControlLabel = styled(FormControlLabel)({
    alignSelf: 'flex-start',
});
