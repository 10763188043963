import React, { useEffect } from 'react';
import { Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { BackdropAppLoader, FormError, Modal } from 'components/common';
import {
    BACKEND_DATE_FORMAT,
    getFirstDayOfDecemberInTheYear,
    getFirstDayOfTheYear,
    getYearsWithMonthsArray,
} from 'utils/functions/dates';
import { useAppTranslation } from 'utils/hooks/translation';
import { useDispatch } from 'redux/store';
import { actions as reportActions, selectors as reportSelectors } from 'redux/slices/reports';
import { selectors as appSelectors } from 'redux/slices/app';
import {
    actions as settlementActions,
    selectors as settlementSelectors,
} from 'redux/slices/settlement';
import { GenerateReportForm, ReportType } from 'utils/interfaces/report';
import {
    StyledButton,
    StyledDateRangeTypography,
    StyledLine,
    StyledSelect,
} from './GenerateReportModal.styled';
import ReportGrantCategoryFilter from 'components/ReportGrantCategoryFilter/ReportGrantCategoryFilter';
import { useSnackBar } from 'contexts/SnackbarContext/SnackbarContext';
import FormControlLabel from '@mui/material/FormControlLabel';

dayjs.extend(customParseFormat);

type GenerateReportModalProps = {
    isGenerateRaportModalOpen: boolean;
    onGenerateRaportModalClose: () => void;
};

const GenerateReportModal = ({
    isGenerateRaportModalOpen,
    onGenerateRaportModalClose,
}: GenerateReportModalProps) => {
    const dispatch = useDispatch();
    const { showSnackBar } = useSnackBar();
    const expenseTypeList = useSelector(settlementSelectors.selectAllExpenseTypeList);
    const expenseTypeOptions =
        expenseTypeList?.map((expenseType) => ({
            value: expenseType,
            name: expenseType,
        })) || [];
    const generateReportState = useSelector(reportSelectors.selectGenerateReportState);
    const selectedDate = useSelector(appSelectors.selectDate);
    const selectedInstitution = useSelector(settlementSelectors.selectSelectedInstitution);
    const { t: reportsT } = useAppTranslation('reports');
    const { t: commonT } = useAppTranslation('common');
    const generateReportT = (itemName: string) => reportsT(`generateReport.${itemName}`);

    const yearsWithMonthArray = getYearsWithMonthsArray();

    const form = useForm<GenerateReportForm>({
        defaultValues: {
            date: {
                from: selectedDate
                    ? getFirstDayOfTheYear(selectedDate.year).toISOString()
                    : yearsWithMonthArray[0].value,
                to: selectedDate
                    ? getFirstDayOfDecemberInTheYear(selectedDate.year).toISOString()
                    : yearsWithMonthArray[0].value,
            },
        },
    });

    const {
        handleSubmit,
        clearErrors,
        setError,
        formState: { errors },
        setValue,
        control,
        getValues,
    } = form;

    useEffect(() => {
        selectedDate &&
            setValue('date', {
                from: getFirstDayOfTheYear(selectedDate.year).toISOString(),
                to: getFirstDayOfDecemberInTheYear(selectedDate.year).toISOString(),
            });
    }, [selectedDate]);
    useEffect(() => {
        console.log('expenseTypeList ', expenseTypeList);
        console.log('selectedInstitution ', selectedInstitution);
        if (!expenseTypeList && selectedInstitution) {
            dispatch(settlementActions.getAllExpenseTypeList(selectedInstitution));
        }
    }, [dispatch, selectedInstitution]);

    useEffect(() => {
        if (generateReportState.success) {
            showSnackBar(
                commonT('snackbar.success'),
                generateReportT('successfullyGeneratedReport'),
                'success',
            );
            dispatch(reportActions.clearGenerateReport());
        } else if (generateReportState.error) {
            showSnackBar(
                commonT('snackbar.errorOccured'),
                generateReportState.error === generateReportT('errors.wrongSelectedInstitution')
                    ? generateReportState.error
                    : generateReportT('errors.couldNotGenerateReport'),
                'error',
            );
            dispatch(reportActions.clearGenerateReport());
        }
    }, [generateReportState]);

    const clearReportSelectError = () => clearErrors('date');
    const clearExpenseCategoryError = () => clearErrors('expenseType');

    const onSubmit = (data: GenerateReportForm) => {
        clearReportSelectError();
        const fromDate = dayjs(data.date.from);
        const toDate = dayjs(data.date.to).endOf('month');

        if (fromDate.isAfter(toDate)) {
            setError('date', {
                type: 'required',
                message: generateReportT('errors.wrongTimeRange'),
            });
            return;
        }
        if (!selectedInstitution) {
            dispatch(
                reportActions.generateReportError(
                    generateReportT('errors.wrongSelectedInstitution'),
                ),
            );
            return;
        }

        data.mainCategory &&
            dispatch(
                reportActions.generateReport({
                    categories: data.subCategories?.length
                        ? data.subCategories
                        : [data.mainCategory],
                    startDate: fromDate.format(BACKEND_DATE_FORMAT),
                    endDate: toDate.format(BACKEND_DATE_FORMAT),
                    institutionId: selectedInstitution,
                    type: data.type,
                    expenseType: data.expenseType,
                }),
            )
                .unwrap()
                .then(() => onGenerateRaportModalClose());
    };

    const onRadioGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue('type', (event.target as HTMLInputElement).value as ReportType);
    };

    return (
        <FormProvider {...form}>
            <form>
                <Modal
                    title={generateReportT('generateReport')}
                    description={generateReportT('selectTimeRangeForReport')}
                    open={isGenerateRaportModalOpen}
                    maxWidth="632px"
                    body={
                        <Stack marginBottom={errors.date ? '20px' : '56px'} gap="36px">
                            <ReportGrantCategoryFilter />
                            <Stack>
                                <StyledDateRangeTypography variant="h5">
                                    {generateReportT('expensesList')}
                                </StyledDateRangeTypography>
                                <StyledSelect
                                    label={generateReportT('expenseTypes')}
                                    name="expenseType"
                                    options={expenseTypeOptions}
                                    error={!!errors.expenseType}
                                    onClick={clearExpenseCategoryError}
                                    defaultValue={null}
                                />

                                <StyledDateRangeTypography variant="h5">
                                    {generateReportT('dateRange')}
                                </StyledDateRangeTypography>
                                <Stack
                                    flexDirection="row"
                                    justifyContent="center"
                                    width="100%"
                                    gap="16px"
                                    alignItems="center"
                                >
                                    <StyledSelect
                                        label={generateReportT('from')}
                                        name="date.from"
                                        options={yearsWithMonthArray}
                                        error={!!errors.date}
                                        onClick={clearReportSelectError}
                                    />
                                    <StyledLine />
                                    <StyledSelect
                                        label={generateReportT('to')}
                                        name="date.to"
                                        options={yearsWithMonthArray}
                                        error={!!errors.date}
                                        onClick={clearReportSelectError}
                                    />
                                </Stack>
                                <Stack
                                    flexDirection="row"
                                    justifyContent="center"
                                    width="100%"
                                    gap="16px"
                                    alignItems="center"
                                    mt={5}
                                >
                                    <Typography>{generateReportT('type')}</Typography>
                                    <Controller
                                        control={control}
                                        name="type"
                                        defaultValue="csv"
                                        render={({ field }) => (
                                            <RadioGroup
                                                row
                                                {...field}
                                                onChange={onRadioGroupChange}
                                            >
                                                <FormControlLabel
                                                    value="pdf"
                                                    control={<Radio />}
                                                    label="PDF"
                                                />
                                                <FormControlLabel
                                                    value="csv"
                                                    control={<Radio />}
                                                    label="CSV"
                                                />
                                            </RadioGroup>
                                        )}
                                    />
                                </Stack>
                            </Stack>

                            <FormError error={errors?.date?.message?.toString() || null} />
                            <BackdropAppLoader open={!!generateReportState?.loading} />
                        </Stack>
                    }
                    actionButtons={
                        <Stack flexDirection="row" justifyContent="center" width="100%" gap="24px">
                            <StyledButton variant="outlined" onClick={onGenerateRaportModalClose}>
                                {generateReportT('cancel')}
                            </StyledButton>
                            <StyledButton onClick={handleSubmit(onSubmit)}>
                                {generateReportT('generate')}
                            </StyledButton>
                        </Stack>
                    }
                />
            </form>
        </FormProvider>
    );
};
export default GenerateReportModal;
