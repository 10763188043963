import { PAID_STATUS, UNPAID_STATUS } from 'consts/payments';
import { SelectOptions } from 'utils/interfaces/common';

const documentTypeOption: SelectOptions = [
    { name: 'Nieopłacony', value: UNPAID_STATUS },
    { name: 'Opłacony', value: PAID_STATUS },
];

const expenseTypeOption: SelectOptions = [
    { name: 'Opłaty za energię, wodę, gaz, ogrzewanie', value: '1' },
    {
        name: 'Pochodne od wynagrodzeń (składki na ubezpieczenie społeczne i Fundusz Pracy)',
        value: '2',
    },
    {
        name: 'Pozostałe wydatki nie stanowiące wydatków inwestycyjnych, przeznaczone na realizację zadań z zakresu kształcenia, wych. i opieki',
        value: '',
    },
    { name: 'Remonty', value: '3' },
    { name: 'Wynagrodzenia pracowników', value: '4' },
    { name: 'Wynajem pomieszczeń', value: '5' },
    { name: 'Zakup materiałów i wyposażenia', value: '6' },
    {
        name: 'Zakup środków trwałych i wartości niematerialnych i prawnych, zgodnie z art. 35 ust. 1 pkt 2 ustawy',
        value: '77',
    },
    { name: '', value: '8' },
];

export { documentTypeOption, expenseTypeOption };
