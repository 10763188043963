import { UserDetailsType } from 'utils/interfaces/auth';
import { Institution } from '../../../utils/interfaces/institution';

export const USER = '/api/v1/user';

export const updateUserEndpoint = (userId: UserDetailsType['id']) => `${USER}/${userId}`;
export const getUserEndpoint = updateUserEndpoint;
export const deleteUserFromInstitutionEndpoint = (
    userId: UserDetailsType['id'],
    institutionId: Institution['id'],
) => `${USER}/delete-from-institution/${userId}?institutionId=${institutionId}`;
