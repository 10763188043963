import { useAuth } from './useAuth';
import { Permission, Role } from '../interfaces/permissions';
import { accessMap } from '../functions/role';

const useAccessManager = () => {
    const { user } = useAuth();

    const role = user?.role ?? Role.ANONYMOUS;

    const canAccess = (permission: Permission) => accessMap[role].includes(permission);

    return { canAccess };
};

export { useAccessManager };
