import React, { useEffect, useState } from 'react';
import {
    FormGroup,
    FormControlLabel,
    Checkbox,
    RadioGroup,
    Radio,
    Modal,
    Stack,
} from '@mui/material';
import _ from 'lodash';
import {
    CheckboxesContainer,
    Container,
    AppButtonStyled,
    Label,
    RadioSectionContainer,
    RadioSectionLabel,
    CheckboxSectionNested,
} from './DocumentsCategoriesFilter.styled';
import { DocCostCategoryType } from '../../../utils/interfaces/document';
import { DASHBOARD_FILTERS } from 'consts/dashboardFilters';
import { useAppTranslation } from '../../../utils/hooks/translation';
import {
    selectors as settlementSelectors,
    actions as settlementActions,
} from 'redux/slices/settlement';
import { useDispatch } from 'redux/store';
import { useSelector } from 'react-redux';

const DocumentsCategoriesFilters = () => {
    const dispatch = useDispatch();

    const { t: commonT } = useAppTranslation('settlement');
    const [showFilters, setShowFilters] = useState<boolean>(false);

    const [filtersContainerPosition, setFiltersContainerPosition] = useState<{
        right: number;
        top: number;
    }>({ right: 0, top: 0 });

    const setPositionOfFiltersBoxBellowButton = () => {
        const showFiltersButtonBoundingRect = document
            .getElementById('show-filters-button')
            ?.getBoundingClientRect();

        setFiltersContainerPosition({
            right: showFiltersButtonBoundingRect
                ? window.innerWidth - showFiltersButtonBoundingRect.right
                : 0,
            top: (showFiltersButtonBoundingRect?.bottom || 0) + 10,
        });
    };

    const handleResizeForPositionOfFiltersBoxBellowButton = _.debounce(
        () => setPositionOfFiltersBoxBellowButton(),
        100,
    );

    useEffect(() => {
        window.addEventListener('resize', handleResizeForPositionOfFiltersBoxBellowButton);
        return () =>
            window.removeEventListener('resize', handleResizeForPositionOfFiltersBoxBellowButton);
    }, []);

    const toggleShowFilters = () => {
        !showFilters && setPositionOfFiltersBoxBellowButton();
        setShowFilters((prev) => !prev);
    };

    const selectedDocumentsCostsFilters = useSelector(
        settlementSelectors.selectDocumentsCostsFilters,
    );

    const { value: selectedCategory, subcategories: selectedSubcategories } =
        selectedDocumentsCostsFilters.grantCategory;

    const onSelectCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
            settlementActions.onSelectDocumentsCostsGrantCategoryFilter(
                event.target.value as DocCostCategoryType,
            ),
        );
    };

    const onSelectSubcategory = (value: DocCostCategoryType) => {
        dispatch(settlementActions.onSelectDocumentsCostsGrantSubategoryFilter(value));
    };

    return (
        <Container>
            <AppButtonStyled
                variant="outlined"
                onClick={toggleShowFilters}
                id="show-filters-button"
            >
                {commonT('documentsList.filters')}
            </AppButtonStyled>
            <Modal open={showFilters} onClose={toggleShowFilters}>
                <CheckboxesContainer
                    right={filtersContainerPosition.right}
                    top={filtersContainerPosition.top}
                >
                    <FormGroup>
                        {DASHBOARD_FILTERS.map((section) => (
                            <RadioSectionContainer key={section.groupTitle}>
                                <RadioSectionLabel>{section.groupTitle}</RadioSectionLabel>
                                <RadioGroup value={selectedCategory} onChange={onSelectCategory}>
                                    {section.groupValues.map((value) => (
                                        <Stack key={value.value}>
                                            <FormControlLabel
                                                value={value.value}
                                                control={<Radio />}
                                                label={<Label>{value.name}</Label>}
                                            />
                                            <CheckboxSectionNested>
                                                {value.subcategories?.map((subcategory) => (
                                                    <FormControlLabel
                                                        key={subcategory.value}
                                                        control={
                                                            <Checkbox
                                                                checked={selectedSubcategories.includes(
                                                                    subcategory.value,
                                                                )}
                                                                onChange={() =>
                                                                    onSelectSubcategory(
                                                                        subcategory.value,
                                                                    )
                                                                }
                                                                disabled={
                                                                    selectedCategory !== value.value
                                                                }
                                                            />
                                                        }
                                                        label={<Label>{subcategory.name}</Label>}
                                                    />
                                                ))}
                                            </CheckboxSectionNested>
                                        </Stack>
                                    ))}
                                </RadioGroup>
                            </RadioSectionContainer>
                        ))}
                    </FormGroup>
                </CheckboxesContainer>
            </Modal>
        </Container>
    );
};

export default DocumentsCategoriesFilters;
