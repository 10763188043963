import { createSelector } from '@reduxjs/toolkit';
import { selectState } from '../slice';
import { UsersState } from '../state';

const selectSelectedUser = createSelector(selectState, (state: UsersState) => state.selectedUser);

const selectGetSelectedUserState = createSelector(
    selectState,
    (state: UsersState) => state.getSelectedUserState,
);

export { selectSelectedUser, selectGetSelectedUserState };
