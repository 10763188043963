import React from 'react';
import { styled, Typography } from '@mui/material';

const StyledError = styled(Typography)`
    font-weight: 500;
    letter-spacing: -0.2px;
    color: ${({ theme }) => `${theme.palette.text.error}`};
    margin-top: -22px;
`;

const FormError = ({ error }: { error: string | null }) =>
    error ? <StyledError variant="body2">{error}</StyledError> : null;

export { FormError };
