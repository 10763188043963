import { Stack, styled, Typography } from '@mui/material';
import { colors } from 'consts/colors';

export const StyledContainerStack = styled(Stack)({
    width: '100%',
    padding: '24px',
    backgroundColor: colors.background2,
    alignItems: 'center',
    borderRadius: '8px',
});

export const StyledTotalExpensesTypography = styled(Typography)({
    fontFamily: 'DM Sans',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '36px',
    color: colors.secondary,
});
