import { Institution, InstitutionId } from 'utils/interfaces/institution';
import { ExpenseType } from '../../../utils/interfaces/document';

export const INSTITUTIONS = '/api/v1/institutions';
export const EXPENSE_TYPES = '/api/v1/institutions/cost-types';
export const getAllExpenseTypes = (institutionId: InstitutionId) =>
    `/api/v1/institutions/cost-types/all/${institutionId}`;

export const FULL_INSTITUTIONS = '/api/v1/institutions/withUser';

export const updateInstitutionEndpoint = (institutionId: Institution['id']) =>
    `${INSTITUTIONS}/${institutionId}`;

export const deleteInstitutionEndpoint = updateInstitutionEndpoint;

export const institutionLicenseEndpoint = (institutionId: Institution['id']) =>
    `${INSTITUTIONS}/${institutionId}/licences`;

export const institutionLicensePaymentEndpoint = (institutionId: Institution['id']) =>
    `${INSTITUTIONS}/${institutionId}/licences/payment`;

export const updateExpenseTypeEndpoint = (expenseTypeId: ExpenseType['id']) =>
    `${EXPENSE_TYPES}/${expenseTypeId}`;

export const deleteExpenseTypeEndpoint = updateExpenseTypeEndpoint;
