import { AppError } from 'utils/interfaces/error';
import { useAppTranslation } from '../hooks/translation';

const convertFirebaseErrorToError = (firebaseError: any): AppError => {
    const slashIndex = firebaseError.code.indexOf('/');
    return firebaseError.code.slice(slashIndex + 1);
};

const useErrorTranslation = (scope: string) => {
    const { t, i18n } = useAppTranslation();

    const translateError = (error: string | null | undefined): string | null => {
        if (!error) {
            return null;
        }
        return i18n.exists(`${scope}.errors.${error}`)
            ? t(`${scope}.errors.${error}`)
            : t('common:errors.unknown');
    };

    return { translateError };
};

export { convertFirebaseErrorToError, useErrorTranslation };
