import { IconButton, MenuItem, styled } from '@mui/material';
import { colors } from 'consts/colors';

export const StyledMoreIconButton = styled(IconButton)({
    color: colors.primary,
});
export const StyledMenuItem = styled(MenuItem)({
    minWidth: '190px',
    textTransform: 'none',
});
