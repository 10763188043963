import React from 'react';
import { ELFLogo } from 'assets/icons';
import { useAppTranslation } from 'utils/hooks/translation';
import {
    LANDING_PAGE_CONTACT,
    LANDING_PAGE_HERO,
    LANDING_PAGE_PAYMENTS,
    LANDING_URL,
} from 'consts/links';
import {
    AuthNavbarButton,
    AuthNavbarContactButton,
    AuthNavbarRegisterButton,
    AuthNavbarRow,
    Logo,
    LogoIconContainer,
} from '../LayoutNavbarStyled';
import MobileAuthNavbar from './MobileAuthNavbar/MobileAuthNavbar';

type AuthNavbarProps = {
    onAboutProductButtonClick?: () => void;
    onPaymentsButtonClick?: () => void;
    onContactButtonClick?: () => void;
    onLoginButtonClick?: () => void;
    onRegisterButtonClick?: () => void;
    isMobile?: boolean;
    openNavMenu: (event: React.MouseEvent<HTMLElement>) => void;
    anchorElNav: null | HTMLElement;
    closeNavMenu: () => void;
};

const AuthNavbar = ({
    onAboutProductButtonClick,
    onPaymentsButtonClick,
    onContactButtonClick,
    onLoginButtonClick,
    onRegisterButtonClick,
    isMobile,
    openNavMenu,
    anchorElNav,
    closeNavMenu,
}: AuthNavbarProps) => {
    const { t: commonT } = useAppTranslation('common');

    const commonLayoutT = (itemName: string) => commonT(`layout.${itemName}`);

    return !isMobile ? (
        <AuthNavbarRow>
            <a href={LANDING_URL}>
                <LogoIconContainer>
                    <Logo>
                        <img src={ELFLogo} alt="logo" />
                    </Logo>
                </LogoIconContainer>
            </a>

            <AuthNavbarButton variant="text" LinkComponent="a" href={LANDING_PAGE_HERO}>
                {commonLayoutT('aboutProduct')}
            </AuthNavbarButton>
            <AuthNavbarButton variant="text" LinkComponent="a" href={LANDING_PAGE_PAYMENTS}>
                {commonLayoutT('payments')}
            </AuthNavbarButton>
            <AuthNavbarContactButton variant="text" LinkComponent="a" href={LANDING_PAGE_CONTACT}>
                {commonLayoutT('contact')}
            </AuthNavbarContactButton>

            <AuthNavbarButton variant="text" onClick={onLoginButtonClick}>
                {commonLayoutT('login')}
            </AuthNavbarButton>
            <AuthNavbarRegisterButton onClick={onRegisterButtonClick}>
                {commonLayoutT('register')}
            </AuthNavbarRegisterButton>
        </AuthNavbarRow>
    ) : (
        <MobileAuthNavbar
            onAboutProductButtonClick={onAboutProductButtonClick}
            onContactButtonClick={onContactButtonClick}
            onPaymentsButtonClick={onPaymentsButtonClick}
            onLoginButtonClick={onLoginButtonClick}
            onRegisterButtonClick={onRegisterButtonClick}
            anchorElNav={anchorElNav}
            closeNavMenu={closeNavMenu}
            openNavMenu={openNavMenu}
        />
    );
};
export default AuthNavbar;
