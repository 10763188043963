import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Container, FormControl, Stack, styled, Typography } from '@mui/material';
import { useAppTranslation } from 'utils/hooks/translation';
import { useAuth } from 'utils/hooks';
import Layout from 'components/Layout/Layout';
import { AppButton, Input, BackdropAppLoader } from 'components';
import { actions, selectors } from 'redux/slices/auth';
import { useDispatch } from 'redux/store';
import { useSnackBar } from 'contexts/SnackbarContext/SnackbarContext';

const StyledContainer = styled(Container)`
    margin-top: 80px;
    margin-bottom: 100px;
`;

const ResetPasswordPage = () => {
    const { sendResetPasswordMail } = useAuth();
    const { showSnackBar } = useSnackBar();
    const { t: authT } = useAppTranslation('auth');
    const { t: commonT } = useAppTranslation('common');
    const dispatch = useDispatch();
    const form = useForm();
    const sendResetMailState = useSelector(selectors.selectSendResetMailState);

    const {
        handleSubmit,
        formState: { errors },
    } = form;

    const onSendMailButtonClick = (data: any) => {
        sendResetPasswordMail(data);
    };

    useEffect(() => {
        if (sendResetMailState.success || sendResetMailState.error) {
            showSnackBar(commonT('snackbar.success'), authT('resetPage.success'), 'info');
            dispatch(actions.clearResetMailSuccess());
        }
    }, [sendResetMailState]);

    return (
        <StyledContainer>
            <BackdropAppLoader open={sendResetMailState.loading} />
            <Stack alignItems="center">
                <Typography variant="h2" gutterBottom>
                    {authT('resetPage.title')}
                </Typography>
                <Typography
                    variant="h5"
                    marginBottom="16px"
                    textAlign="center"
                    whiteSpace="pre-line"
                    color={(theme) => theme.palette.text.secondary}
                >
                    {authT('resetPage.subtitle')}
                </Typography>
                <FormProvider {...form}>
                    <FormControl fullWidth>
                        <form onSubmit={handleSubmit(onSendMailButtonClick)}>
                            <Stack gap="32px" marginTop="34px" marginBottom="20px">
                                <Layout.Container>
                                    <Input
                                        rules={{ required: commonT('form.required') }}
                                        label={authT('resetPage.email')}
                                        name="email"
                                        type="email"
                                        fullWidth
                                        error={!!errors.email}
                                        helperText={(errors?.email?.message || '') as string}
                                    />
                                </Layout.Container>
                                <AppButton type="submit" fullWidth>
                                    {authT('resetPage.button')}
                                </AppButton>
                                <Typography textAlign="center" variant="body2">
                                    {authT('resetPage.noMail')}
                                </Typography>
                            </Stack>
                        </form>
                    </FormControl>
                </FormProvider>
            </Stack>
        </StyledContainer>
    );
};

export default ResetPasswordPage;
