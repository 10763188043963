import { PayloadAction } from '@reduxjs/toolkit';
import { DOC_COST_CATEGORIES } from 'consts/docCostCategories';
import { DocCostCategoryType } from 'utils/interfaces/document';
import { SettlementState } from '../state';

export const clearDocumentsCostsList = (state: SettlementState) => {
    state.documentsCostsList = [];
};

export const setDocumentsCostsList = (state: SettlementState, action: any) => {
    state.documentsCostsList = action.payload;
};

export const initDocumentsCostsListFetching = (state: SettlementState) => {
    state.documentsCostsListFetchingState = { loading: true };
};

export const documentsCostsListFetchingSuccess = (state: SettlementState) => {
    state.documentsCostsListFetchingState = { loading: false, success: true };
};

export const documentsCostsListFetchingError = (
    state: SettlementState,
    action: PayloadAction<string>,
) => {
    state.documentsCostsListFetchingState = {
        loading: false,
        success: false,
        error: action.payload,
    };
};

export const setDocumentsCostsListTotalElements = (state: SettlementState, action: any) => {
    state.documentsCostsListTotalElements = action.payload;
};

export const setDocumentsCostsFilters = (
    state: SettlementState,
    action: PayloadAction<{
        grantCategory: { value: DocCostCategoryType; subcategories: DocCostCategoryType[] };
    }>,
) => {
    state.documentsCostsFilters = action.payload;
};

export const onSelectDocumentsCostsGrantCategoryFilter = (
    state: SettlementState,
    action: PayloadAction<DocCostCategoryType>,
) => {
    state.documentsCostsFilters.grantCategory = {
        value: action.payload,
        subcategories:
            DOC_COST_CATEGORIES.find((category) => category.value === action.payload)
                ?.subcategories?.filter((subcategory) => subcategory.selectedByDefault)
                .map((subcategory) => subcategory.value) || [],
    };
};

export const onSelectDocumentsCostsGrantSubategoryFilter = (
    state: SettlementState,
    action: PayloadAction<DocCostCategoryType>,
) => {
    const { subcategories } = state.documentsCostsFilters.grantCategory;

    if (subcategories.includes(action.payload)) {
        if (subcategories.length > 1) {
            state.documentsCostsFilters.grantCategory.subcategories = subcategories.filter(
                (subcategory) => subcategory !== action.payload,
            );
        }
    } else {
        state.documentsCostsFilters.grantCategory.subcategories = [
            ...subcategories,
            action.payload,
        ];
    }
};
