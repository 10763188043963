import { CircularProgress, Stack, Typography } from '@mui/material';
import ConfirmModal from 'components/common/ConfirmModal/ConfirmModal';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppTranslation } from 'utils/hooks/translation';
import {
    StyledBuyButton,
    StyledDescriptionTypography,
    StyledInfoTypography,
    StyledPaymentsColumnTitle,
    StyledPaymentsTabContainer,
    StyledPill,
    StyledTitleRow,
    StyledTwoColumnsRow,
    StyledValueTypography,
} from './PaymentsTab.styled';
import { usePaymentsTab } from './usePaymentsTab';
import hash from 'object-hash';

export type PaymentsStatuses = undefined | 'success' | 'error';

const paymentsDialogContents = {
    success: {
        title: 'paymentSuccess',
        message: 'paymentSuccessDescription',
        firstButtonLabel: 'close',
    },
    error: {
        title: 'paymentFailed',
        message: 'paymentFailedDescription',
        firstButtonLabel: 'close',
    },
} as const;

const getPaymentsDialogContents = (
    status: Exclude<PaymentsStatuses, undefined>,
    translate: (key: string) => string,
) => {
    const { title, message, firstButtonLabel } = paymentsDialogContents[status];

    return {
        title: translate(title),
        message: translate(message),
        firstButtonLabel: translate(firstButtonLabel),
    };
};

const PaymentsTab = () => {
    const { institutionsWithLicense, institutionsWithoutLicense, onButtonPress, isLoading } =
        usePaymentsTab();
    const { t } = useAppTranslation('settings', { keyPrefix: 'paymentsList' });
    const [searchParams, setSearchParams] = useSearchParams();
    const paymentsStatusError = searchParams.get('error');
    const paymentsStatus = searchParams.get('paymentStatus');
    const [isPaymentStatusDialogOpen, setIsPaymentStatusDialogOpen] = useState(false);
    const paymentsDialogProps =
        paymentsStatus && paymentsStatusError
            ? getPaymentsDialogContents('error', t)
            : getPaymentsDialogContents('success', t);

    const closeDialog = () => {
        setIsPaymentStatusDialogOpen(false);
        setSearchParams({});
    };

    useEffect(() => {
        if (paymentsStatus) {
            setIsPaymentStatusDialogOpen(true);
        }
    }, [paymentsStatus]);

    if (isLoading) {
        return (
            <Stack paddingY="120px" alignItems="center">
                <CircularProgress />
            </Stack>
        );
    }

    return (
        <StyledPaymentsTabContainer>
            <StyledTitleRow>
                <StyledPaymentsColumnTitle>{t('acquiredLicenses')}</StyledPaymentsColumnTitle>
                <StyledPaymentsColumnTitle>{t('price')}</StyledPaymentsColumnTitle>
            </StyledTitleRow>
            {institutionsWithLicense.map((institution) => (
                <StyledTwoColumnsRow key={hash(institution)}>
                    <StyledPill>
                        <StyledDescriptionTypography>
                            {institution.name}
                        </StyledDescriptionTypography>
                    </StyledPill>
                    <StyledPill>
                        <StyledValueTypography> {t('licenseAcquiredFor')} </StyledValueTypography>
                    </StyledPill>
                </StyledTwoColumnsRow>
            ))}
            <StyledInfoTypography>{t('licenseIsPermanent')}</StyledInfoTypography>
            <StyledTwoColumnsRow>
                <StyledPaymentsColumnTitle>
                    {t('institutionsWithoutLicense')}
                </StyledPaymentsColumnTitle>
            </StyledTwoColumnsRow>
            {institutionsWithoutLicense.map((institution) => (
                <StyledTwoColumnsRow key={institution.regon}>
                    <StyledPill>
                        <StyledDescriptionTypography>
                            {institution.name}
                        </StyledDescriptionTypography>
                    </StyledPill>
                    <StyledPill>
                        <StyledValueTypography> {t('acquireLicenseFor')} </StyledValueTypography>
                        <Stack>
                            {institution?.licence?.licenceStatus === 'PENDING_PAYMENT' && (
                                <Typography variant="body2">{t('paymentIsProcessed')}</Typography>
                            )}
                            <StyledBuyButton onClick={() => onButtonPress(institution)}>
                                {t('acquire')}
                            </StyledBuyButton>
                        </Stack>
                    </StyledPill>
                </StyledTwoColumnsRow>
            ))}
            {paymentsDialogProps && (
                <ConfirmModal
                    {...paymentsDialogProps}
                    open={isPaymentStatusDialogOpen}
                    onFirstButtonClick={closeDialog}
                />
            )}
        </StyledPaymentsTabContainer>
    );
};

export default PaymentsTab;
