import { styled, TableCell, TableContainer, Typography } from '@mui/material';
import { colors } from 'consts/colors';

export const StyledInnerTableContainer = styled(TableContainer)(({ theme }) => ({
    paddingLeft: '3px',
    backgroundColor: theme.palette.table.background,
    borderTop: `1px solid ${colors.lightGray}`,
    borderBottom: `1px solid ${colors.lightGray}`,
}));

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    padding: '16px',
    backgroundColor: theme.palette.table.background,
    borderRadius: '16px 16px 0 0',
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: 0,
    color: theme.palette.table.contrastText,
    letterSpacing: '-0.4px',
    lineHeight: '22px',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'DM Sans',
    padding: '24px 0 !important',
}));

export const StyledNameTypography = styled(Typography)({
    color: colors.secondaryText,
    lineHeight: '22px',
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: 'DM Sans',
});
