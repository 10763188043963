import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import UsersListTable from 'components/UsersList/UsersListTable/UsersListTable';
import { useSelector } from 'react-redux';
import { useAppTranslation } from 'utils/hooks/translation';
import UserModal from 'components/UserModal/UserModal';
import { actions as usersActions, selectors as usersSelectors } from 'redux/slices/users';
import { selectors as settingsSelectors } from 'redux/slices/settings';
import { StyledAddUserButton } from './UsersTab.styled';
import { BackdropAppLoader } from 'components';
import { useDispatch } from 'redux/store';
import { useSnackBar } from 'contexts/SnackbarContext/SnackbarContext';
import { FullInstitution } from '../../../../../utils/interfaces/institution';
import { UserWithInstitutions } from '../../../../../utils/interfaces/auth';
import { mapUserDetailsTypeToUserWithInstitution } from '../../../../../utils/mappers/authMapper';

const UsersTab = () => {
    const dispatch = useDispatch();
    const { showSnackBar } = useSnackBar();

    const fullInstitutionsList = useSelector(settingsSelectors.selectFullInstitutionsList);
    const getFullInstitutionListState = useSelector(
        settingsSelectors.selectGetFullInstitutionsListState,
    );
    const deleteUserState = useSelector(usersSelectors.selectDeleteUserState);

    const { t: commonT } = useAppTranslation('common');
    const { t: settingsT } = useAppTranslation('settings');

    const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);

    const usersListT = (itemName: string) => settingsT(`usersList.${itemName}`);

    const onAddUserButtonClick = () => setIsAddUserModalOpen(true);

    const closeAddUserModal = () => setIsAddUserModalOpen(false);

    useEffect(() => {
        if (deleteUserState.success) {
            showSnackBar(
                commonT('snackbar.success'),
                usersListT('successfullyDeletedUser'),
                'success',
            );
            dispatch(usersActions.clearDeleteUser());
        } else if (deleteUserState.error) {
            showSnackBar(
                commonT('snackbar.errorOccured'),
                usersListT('errors.couldNotDeleteUser'),
                'error',
            );
            dispatch(usersActions.clearDeleteUser());
        }
    }, [deleteUserState]);

    const sortUsersByStringProps = (
        users: UserWithInstitutions[],
        sortCriteria: (keyof Omit<UserWithInstitutions, 'assignedInstitutionNames'>)[],
    ) =>
        users.sort((user1, user2) => {
            for (let i = 0; i < sortCriteria.length; i += 1) {
                const sortProp = sortCriteria[i];
                const user1Prop = user1[sortProp];
                const user2Prop = user2[sortProp];

                if (!user1Prop) {
                    // move to the end
                    return 1;
                }
                if (!user2Prop) {
                    // move to the end
                    return -1;
                }

                const sortResult = user1Prop.toUpperCase().localeCompare(user2Prop.toUpperCase());
                if (sortResult !== 0) return sortResult;
            }
            return 0; // equal
        });

    // return list of all users with their institutions sorted by last name
    const getUsersWithInstitutions = (institutions: FullInstitution[]) => {
        const usersMap = new Map<string, UserWithInstitutions>();
        institutions.forEach((institution) => {
            if (institution.assignedUsers)
                institution.assignedUsers.forEach((user) => {
                    if (usersMap.has(user.email)) {
                        const userInMap = usersMap.get(user.email) as UserWithInstitutions;
                        usersMap.set(user.email, {
                            ...userInMap,
                            assignedInstitutionNames: [
                                ...userInMap.assignedInstitutionNames,
                                institution.name,
                            ],
                        });
                    } else {
                        const userWithInstitutions = mapUserDetailsTypeToUserWithInstitution(user);
                        usersMap.set(user.email, {
                            ...userWithInstitutions,
                            assignedInstitutionNames: [institution.name],
                        });
                    }
                });
        });

        const sortCriteria: (keyof Omit<UserWithInstitutions, 'assignedInstitutionNames'>)[] = [
            'lastName',
            'firstName',
            'email',
        ];
        return sortUsersByStringProps(Array.from(usersMap.values()), sortCriteria);
    };

    return (
        <Stack gap="21px">
            <UsersListTable
                usersWithInstitutions={getUsersWithInstitutions(fullInstitutionsList || [])}
                loading={getFullInstitutionListState.loading}
            />
            <StyledAddUserButton onClick={onAddUserButtonClick}>
                {settingsT('addUser')}
            </StyledAddUserButton>
            <UserModal isOpen={isAddUserModalOpen} onClose={closeAddUserModal} type="add" />
            <BackdropAppLoader open={deleteUserState.loading} />
        </Stack>
    );
};

export default UsersTab;
