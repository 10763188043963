import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { Container, FormControl, Stack, Typography, styled } from '@mui/material';
import { actions, selectors } from 'redux/slices/settlement';
import { useDispatch } from 'redux/store';
import { useAppTranslation } from 'utils/hooks/translation';
import { BackButton, BackdropAppLoader, GrantForm, Modal } from 'components';
import { HOME_PAGE } from 'consts/routes';
import { GrantFormData } from 'utils/interfaces/grant';
import { GRAND_EXISTS_CODE } from 'services/HTTPService/grants/errors';
import { useSnackBar } from 'contexts/SnackbarContext/SnackbarContext';

const StyledContainer = styled(Container)`
    margin-top: 50px;
    margin-bottom: 80px;
`;

const StyledStack = styled(Stack)`
    max-width: 630px;
    width: 100%;
`;

const StyledBackButton = styled(BackButton)`
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
`;

const AddGrantPage = () => {
    const { t: commonT } = useAppTranslation('common');
    const { t: settlementT } = useAppTranslation('settlement');
    const dispatch = useDispatch();
    const { showSnackBar } = useSnackBar();
    const grantRequestState = useSelector(selectors.selectGrantRequestState);
    const getInstituteListState = useSelector(selectors.selectGetInstitutionListState);
    const instituteList = useSelector(selectors.selectInstitutionList);
    const loading = grantRequestState.loading || getInstituteListState.loading;
    const error = grantRequestState.error?.code || getInstituteListState.error;
    const doesGrantExist = grantRequestState?.error?.code === GRAND_EXISTS_CODE;
    const navigate = useNavigate();
    const form = useForm();
    const {
        handleSubmit,
        formState: { errors },
        getValues,
    } = form;

    const onAddGrantClick = (formData: unknown) => {
        dispatch(actions.addGrant(formData as GrantFormData));
    };

    const onConfirmClick = () => {
        const formData = getValues() as GrantFormData;
        const existingGrantData = grantRequestState?.error?.data?.grantId;

        if (existingGrantData) {
            dispatch(actions.updateGrant({ ...formData, grantId: existingGrantData }));
        }

        dispatch(actions.clearGrantRequest());
    };

    const onConfirmCancelClick = () => {
        dispatch(actions.clearGrantRequest());
    };

    const goBack = () => navigate(HOME_PAGE);

    useEffect(() => {
        !instituteList && dispatch(actions.getInstitutionList());
    }, [dispatch, instituteList]);

    useEffect(() => {
        instituteList?.length && form.reset({ institution: instituteList?.[0].id });
    }, [instituteList]);

    useEffect(() => {
        if (grantRequestState.success) {
            showSnackBar(
                commonT('snackbar.success'),
                settlementT('addGrant.successfullyAddedGrant'),
                'success',
            );
            dispatch(actions.clearGrantRequest());
        }
    }, [grantRequestState]);

    return (
        <StyledContainer>
            <BackdropAppLoader open={loading} />
            <Stack alignItems="center" gap="37px">
                <StyledStack position="relative">
                    <StyledBackButton onClick={goBack} label={commonT('button.back')} />
                    <Typography variant="h2" textAlign="center">
                        {settlementT('addGrant.title')}
                    </Typography>
                </StyledStack>
                <FormProvider {...form}>
                    <FormControl fullWidth>
                        <form onSubmit={handleSubmit(onAddGrantClick)}>
                            <GrantForm
                                goBack={goBack}
                                errors={errors}
                                backendError={error}
                                instituteList={instituteList}
                            />
                        </form>
                    </FormControl>
                </FormProvider>
            </Stack>

            <Modal
                title={settlementT('addGrant.modal.title')}
                open={doesGrantExist}
                onClick={onConfirmClick}
                onClose={onConfirmCancelClick}
                description={settlementT('addGrant.modal.description')}
                understand={settlementT('addGrant.modal.understand')}
            />
        </StyledContainer>
    );
};

export default AddGrantPage;
