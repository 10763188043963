import React, { useState } from 'react';
import { MoreHoriz } from '@mui/icons-material';
import { Menu, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useAppTranslation } from 'utils/hooks/translation';
import InstitutionModal from 'components/InstitutionModal/InstitutionModal';
import {
    actions as settlementActions,
    selectors as settlementSelectors,
} from 'redux/slices/settlement';
import { selectors as settingsSelectors } from 'redux/slices/settings';
import { useDispatch } from 'redux/store';
import ConfirmModal from 'components/common/ConfirmModal/ConfirmModal';
import { BackdropAppLoader } from 'components/common';
import { StyledMenuItem, StyledMoreIconButton } from './InstitutionTableMoreMenu.styled';
import type { FullInstitution } from 'utils/interfaces/institution';
import { CAN_NOT_DELETE_OWNER_LAST_INSTITUTION } from '../../../consts/errors';

type InstitutionTableMoreMenuProps = {
    institutionId: FullInstitution['id'];
};

const InstitutionTableMoreMenu = ({ institutionId }: InstitutionTableMoreMenuProps) => {
    const dispatch = useDispatch();
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [isEditInstitutionModalOpen, setIsEditInstitutionModalOpen] = useState(false);
    const [isDeleteInstitutionConfirmModalOpen, setIsDeleteInstitutionConfirmModalOpen] =
        useState(false);

    const fullInstitutionsList = useSelector(settingsSelectors.selectFullInstitutionsList);
    const deleteInstitutionState = useSelector(settlementSelectors.selectDeleteInstitutionState);

    const openNavMenu = (event: React.MouseEvent<HTMLElement>) =>
        setAnchorElNav(event.currentTarget);
    const closeNavMenu = () => setAnchorElNav(null);

    const { t: settingsT } = useAppTranslation('settings');
    const institutionsT = (itemName: string) => settingsT(`institutionsList.${itemName}`);

    const closeEditInstitutionModal = () => {
        setIsEditInstitutionModalOpen(false);
    };

    const closeDeleteInstitutionConfirmModal = () => setIsDeleteInstitutionConfirmModalOpen(false);

    const onConfirmDeleteInstitutionClick = () => {
        if (fullInstitutionsList?.length && fullInstitutionsList.length > 1) {
            dispatch(settlementActions.deleteInstitution({ institutionId: institutionId }));
            closeDeleteInstitutionConfirmModal();
        } else {
            dispatch(
                settlementActions.deleteInstitutionError(CAN_NOT_DELETE_OWNER_LAST_INSTITUTION),
            );
        }
    };

    const onEditClick = () => {
        setIsEditInstitutionModalOpen(true);
        closeNavMenu();
    };
    const onDeleteClick = () => {
        setIsDeleteInstitutionConfirmModalOpen(true);
        closeNavMenu();
    };

    return (
        <>
            <StyledMoreIconButton onClick={openNavMenu}>
                <MoreHoriz />
            </StyledMoreIconButton>
            <Menu
                anchorEl={anchorElNav}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={!!anchorElNav}
                onClose={closeNavMenu}
                componentsProps={{
                    backdrop: { style: { backgroundColor: 'transparent' } },
                }}
            >
                <StyledMenuItem onClick={onEditClick}>
                    <Typography textAlign="left">{institutionsT('edit')}</Typography>
                </StyledMenuItem>
                <StyledMenuItem onClick={onDeleteClick}>
                    <Typography textAlign="left">{institutionsT('delete')}</Typography>
                </StyledMenuItem>
            </Menu>
            <InstitutionModal
                isOpen={isEditInstitutionModalOpen}
                onClose={closeEditInstitutionModal}
                type="edit"
                institution={fullInstitutionsList?.find(
                    (institution) => institution.id === institutionId,
                )}
            />
            <ConfirmModal
                open={isDeleteInstitutionConfirmModalOpen}
                onClose={closeDeleteInstitutionConfirmModal}
                title={institutionsT('areYouSureYouWantToDeleteInstitution')}
                message={institutionsT('afterDeletingInstitutionYouWillLoseSettlementHistory')}
                firstButtonLabel={institutionsT('notYet')}
                secondButtonLabel={institutionsT('yesDelete')}
                onFirstButtonClick={closeDeleteInstitutionConfirmModal}
                onSecondButtonClick={onConfirmDeleteInstitutionClick}
            />

            <BackdropAppLoader open={deleteInstitutionState.loading} />
        </>
    );
};

export default InstitutionTableMoreMenu;
