import { createSelector } from '@reduxjs/toolkit';
import { selectState } from '../slice';
import { SettlementState } from '../state';

const selectDocumentsCostsList = createSelector(
    selectState,
    (state: SettlementState) => state.documentsCostsList,
);

const selectDocumentsCostsListTotalElements = createSelector(
    selectState,
    (state: SettlementState) => state.documentsCostsListTotalElements,
);

const selectDocumentsCostsListFetchingState = createSelector(
    selectState,
    (state: SettlementState) => state.documentsCostsListFetchingState,
);

const selectDocumentsCostsFilters = createSelector(
    selectState,
    (state: SettlementState) => state.documentsCostsFilters,
);

const selectAddDocumentState = createSelector(
    selectState,
    (state: SettlementState) => state.addDocumentState,
);

const selectDeleteDocumentState = createSelector(
    selectState,
    (state: SettlementState) => state.deleteDocumentState,
);

const selectGetDocumentState = createSelector(
    selectState,
    (state: SettlementState) => state.getDocumentState,
);

const selectUpdateDocumentState = createSelector(
    selectState,
    (state: SettlementState) => state.updateDocumentState,
);

const selectDocument = createSelector(selectState, (state: SettlementState) => state.document);

export {
    selectDocumentsCostsList,
    selectDocumentsCostsListTotalElements,
    selectDocumentsCostsListFetchingState,
    selectDocumentsCostsFilters,
    selectAddDocumentState,
    selectDeleteDocumentState,
    selectGetDocumentState,
    selectUpdateDocumentState,
    selectDocument,
};
