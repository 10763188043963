import { PayloadAction } from '@reduxjs/toolkit';
import { UsersState } from '../state';

export const initAddUser = (state: UsersState) => {
    state.addUserState = { loading: true };
};

export const clearAddUser = (state: UsersState) => {
    state.addUserState = { loading: false };
};

export const addUserSuccess = (state: UsersState) => {
    state.addUserState = { loading: false, success: true };
};

export const addUserError = (state: UsersState, action: PayloadAction<string>) => {
    state.addUserState = { loading: false, success: false, error: action.payload };
};
