import { Stack, styled } from '@mui/material';
import { AppButton } from 'components/common';

export const StyledLineStack = styled(Stack)(({ theme }) => ({
    height: '2px',
    width: '100%',
    backgroundColor: theme.palette.primary.light,
}));

export const StyledActionAppButton = styled(AppButton)({
    paddingRight: '32px',
    paddingLeft: '32px',
});
