import React from 'react';
import { CircularProgress, Stack, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { AppButton } from '../AppButton';
import { DocumentIcon } from 'assets/icons';
import { colors } from 'consts/colors';

const StyledFileNameButton = styled(AppButton)({
    color: `${colors.secondary} !important`,
});

type FileGaleryProps = {
    items: Array<{ fileName: string; fileUri: string }>;
    onClick?: (index: number) => void;
    onRemoveClick?: (index: number) => void;
    loading?: boolean;
};

const FileGallery = ({ items, onClick, onRemoveClick, loading }: FileGaleryProps) => (
    <Stack>
        {loading ? (
            <Stack alignItems="center" width="100%" padding="20px">
                <CircularProgress />
            </Stack>
        ) : (
            Array.from(items).map((item, index) => (
                <Stack key={item.fileName} direction="row" alignItems="center">
                    <StyledFileNameButton
                        startIcon={<DocumentIcon />}
                        variant="text"
                        onClick={() => onClick?.(index)}
                        disabled={!onClick}
                    >
                        {item.fileName}
                    </StyledFileNameButton>
                    <IconButton color="primary" onClick={() => onRemoveClick?.(index)}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            ))
        )}
    </Stack>
);

export { FileGallery };
