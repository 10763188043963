import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { StyledTextField } from './Input.styled';

type InputProps = {
    name: string;
} & Pick<React.ComponentProps<typeof Controller>, 'rules' | 'shouldUnregister' | 'defaultValue'> &
    React.ComponentProps<typeof TextField>;

const Input: React.FC<InputProps> = ({
    name,
    shouldUnregister,
    defaultValue = '',
    rules,
    type,
    ...inputProps
}) => {
    const { control } = useFormContext();
    return (
        <Controller
            name={name}
            control={control}
            shouldUnregister={shouldUnregister}
            rules={rules}
            defaultValue={defaultValue}
            render={(fieldProps) => (
                <StyledTextField
                    {...fieldProps.field}
                    {...inputProps}
                    type={type}
                    onWheel={(e) =>
                        type === 'number' && e.target instanceof HTMLElement && e.target.blur()
                    }
                    onKeyDown={(e) => {
                        type === 'number' &&
                            e.target instanceof HTMLElement &&
                            (e.key === 'ArrowUp' || e.key === 'ArrowDown') &&
                            e.preventDefault();
                    }}
                />
            )}
        />
    );
};

export { Input };
