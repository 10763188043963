import { Typography, styled } from '@mui/material';
import { AppButton } from 'components';

export const StyledTabContainer = styled('div')`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: #fff;
    border-radius: 16px;
    padding: 0 32px 30px 32px;
`;

export const StyledColumnTitle = styled(Typography)`
    font-family: 'DM Sans';
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    width: 50%;
    color: ${({ theme }) => theme.palette.text.grey100};
`;

export const StyledTwoColumnsRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '12px',
});

export const StyledAddTypeButton = styled(AppButton)`
    margin-top: 22px;
    margin-bottom: 10px;
`;
export const StyledPill = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    border-color: ${({ theme }) => theme.palette.table.border};
    border-width: 1px;
    padding: 17px 20px 17px 24px;
    border-style: solid;
    border-radius: 6px 6px 6px 6px;
`;

export const StyledDescriptionTypography = styled(Typography)`
    font-family: 'DM Sans';
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: ${({ theme }) => theme.palette.text.contentBody};
`;

export const IconButton = styled('img');
