import React, { useState } from 'react';
import { MoreHoriz } from '@mui/icons-material';
import { Menu, Typography } from '@mui/material';
import { UserWithInstitutions } from 'utils/interfaces/auth';
import { useAppTranslation } from 'utils/hooks/translation';
import UserModal from 'components/UserModal/UserModal';
import ConfirmModal from 'components/common/ConfirmModal/ConfirmModal';
import { actions as usersActions } from 'redux/slices/users';
import { useDispatch } from 'redux/store';
import { StyledMenuItem, StyledMoreIconButton } from './UserTableMoreMenu.styled';

type UserTableMoreMenuProps = {
    user: UserWithInstitutions;
};

const UserTableMoreMenu = ({ user }: UserTableMoreMenuProps) => {
    const dispatch = useDispatch();

    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
    const [isDeleteUserConfirmModalOpen, setIsDeleteUserConfirmModalOpen] = useState(false);

    const openNavMenu = (event: React.MouseEvent<HTMLElement>) =>
        setAnchorElNav(event.currentTarget);
    const closeNavMenu = () => setAnchorElNav(null);

    const { t: settingsT } = useAppTranslation('settings');
    const usersListT = (itemName: string) => settingsT(`usersList.${itemName}`);

    const closeEditUserModal = () => {
        setIsEditUserModalOpen(false);
    };

    const closeDeleteUserConfirmModal = () => setIsDeleteUserConfirmModalOpen(false);

    const onConfirmDeleteUserClick = () => {
        dispatch(usersActions.deleteUser(user.id));
        closeDeleteUserConfirmModal();
    };

    const onEditClick = () => {
        setIsEditUserModalOpen(true);
        closeNavMenu();
    };

    const onDeleteClick = () => {
        setIsDeleteUserConfirmModalOpen(true);
        closeNavMenu();
    };

    return (
        <>
            <StyledMoreIconButton onClick={openNavMenu}>
                <MoreHoriz />
            </StyledMoreIconButton>
            <Menu
                anchorEl={anchorElNav}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={!!anchorElNav}
                onClose={closeNavMenu}
                componentsProps={{
                    backdrop: { style: { backgroundColor: 'transparent' } },
                }}
            >
                <StyledMenuItem onClick={onEditClick}>
                    <Typography textAlign="left">{usersListT('edit')}</Typography>
                </StyledMenuItem>
                <StyledMenuItem onClick={onDeleteClick}>
                    <Typography textAlign="left">{usersListT('delete')}</Typography>
                </StyledMenuItem>
            </Menu>
            <UserModal
                isOpen={isEditUserModalOpen}
                onClose={closeEditUserModal}
                type="edit"
                userId={user.id}
            />
            <ConfirmModal
                open={isDeleteUserConfirmModalOpen}
                onClose={closeDeleteUserConfirmModal}
                title={usersListT('areYouSureYouWantToDeleteUser')}
                firstButtonLabel={usersListT('cancel')}
                secondButtonLabel={usersListT('yesDelete')}
                onFirstButtonClick={closeDeleteUserConfirmModal}
                onSecondButtonClick={onConfirmDeleteUserClick}
            />
        </>
    );
};

export default UserTableMoreMenu;
