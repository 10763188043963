import React from 'react';
import { Stack } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import { LOGIN_PAGE, REGISTER_PAGE } from 'consts/routes';
import { Container } from './Container/Container';
import LayoutFooter from './LayoutFooter/LayoutFooter';
import { LayoutStyledContainer } from './LayoutStyled';
import LayoutNavbar from './LayoutNavbar/LayoutNavbar';

type NamespaceComponents = {
    Container: typeof Container;
};

const Layout: React.FC & NamespaceComponents = () => {
    const location = useLocation();

    const shouldFooterBeVisible =
        location.pathname !== LOGIN_PAGE && location.pathname !== REGISTER_PAGE;

    return (
        <LayoutStyledContainer>
            <LayoutNavbar />

            <Stack flex={1}>
                <Outlet />
            </Stack>

            {shouldFooterBeVisible && <LayoutFooter />}
        </LayoutStyledContainer>
    );
};

Layout.Container = Container;

export default Layout;
