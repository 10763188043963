import { PayloadAction } from '@reduxjs/toolkit';
import { DocType } from 'utils/interfaces/document';
import { SettlementState } from '../state';

export const initGetDocument = (state: SettlementState) => {
    state.getDocumentState = { loading: true };
};

export const clearGetDocument = (state: SettlementState) => {
    state.getDocumentState = { loading: false };
};

export const getDocumentSuccess = (state: SettlementState, action: PayloadAction<DocType>) => {
    state.getDocumentState = { loading: false, success: true };
    state.document = action.payload;
};

export const getDocumentError = (state: SettlementState, action: PayloadAction<string>) => {
    state.getDocumentState = { loading: false, success: false, error: action.payload };
};
