import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    confirmPasswordReset,
    signOut,
} from 'firebase/auth';
import { auth } from 'configs/firebase';
import { LoginData, RegisterPostData } from 'utils/interfaces/auth';
import HTTPService from 'services/HTTPService/HTTPService';
import { logAxiosError } from 'utils/errors';
import { actions } from '../index';
import { actions as appActions } from 'redux/slices/app';

const getUser = createAsyncThunk('auth/getUser', async (payload, thunkApi) => {
    try {
        thunkApi.dispatch(actions.initGetUser());
        const { data } = await HTTPService.getUser();
        thunkApi.dispatch(actions.getUserSuccess(data));
    } catch (error: any) {
        thunkApi.dispatch(actions.getUserError());
        throw error;
    }
});

const signUp = createAsyncThunk('auth/signUp', async (payload: RegisterPostData, thunkApi) => {
    try {
        thunkApi.dispatch(actions.initSignUp());
        await HTTPService.createAdminUser(payload);
        thunkApi.dispatch(actions.signUpSuccess());
        thunkApi.dispatch(actions.signIn({ email: payload.email, password: payload.password }));
    } catch (error: any) {
        logAxiosError(error);
        thunkApi.dispatch(actions.signUpError());
        throw error;
    }
});

const signIn = createAsyncThunk('auth/signIn', async (payload: LoginData, thunkApi) => {
    try {
        thunkApi.dispatch(actions.initSignIn());
        await signInWithEmailAndPassword(auth, payload.email, payload.password);
        await getUser();
        thunkApi.dispatch(actions.signInSuccess());
    } catch (error: any) {
        thunkApi.dispatch(actions.signInError());
        throw error;
    }
});

const sendResetPasswordMail = createAsyncThunk(
    'auth/sendResetPasswordMail',
    async (payload: { email: string }, thunkApi) => {
        try {
            thunkApi.dispatch(actions.initSendResetMail());
            await sendPasswordResetEmail(auth, payload.email);
            thunkApi.dispatch(actions.sendResetMailSuccess());
        } catch (error: any) {
            thunkApi.dispatch(actions.sendResetMailError());
            throw error;
        }
    },
);

const changePassword = createAsyncThunk(
    'auth/changePassword',
    async (payload: { password: string; oobCode: string }, thunkApi) => {
        try {
            thunkApi.dispatch(actions.initResetPassword());
            await confirmPasswordReset(auth, payload.oobCode, payload.password);
            thunkApi.dispatch(actions.resetPasswordSuccess());
        } catch (error: any) {
            thunkApi.dispatch(actions.resetPasswordError());
            throw error;
        }
    },
);

const logOut = createAsyncThunk('auth/logOut', async (payload, thunkApi) => {
    try {
        thunkApi.dispatch(appActions.resetWholeStore());
        await signOut(auth);
    } catch (error: any) {
        logAxiosError(error);
        throw error;
    }
});

export { signUp, signIn, sendResetPasswordMail, changePassword, logOut, getUser };
