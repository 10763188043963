import React from 'react';
import { Backdrop, BackdropProps, CircularProgress, styled } from '@mui/material';

const BackdropWithZIndex = styled(Backdrop)`
    z-index: ${({ theme }) => `${theme.zIndex.drawer + 1}`};
`;

interface BackdropAppLoaderProps extends BackdropProps {}

const BackdropAppLoader = ({ ...props }: BackdropAppLoaderProps) => (
    <BackdropWithZIndex {...props}>
        <CircularProgress />
    </BackdropWithZIndex>
);

export { BackdropAppLoader };
