import { useState } from 'react';
import { useSelector } from 'react-redux';
import { actions as authActions, selectors as authSelectors } from 'redux/slices/auth';
import { useDispatch } from 'redux/store';
import { LoginData, RegisterPostData } from '../interfaces/auth';
import { AppError } from '../interfaces/error';
import { convertFirebaseErrorToError } from '../functions/error';

const useAuth = () => {
    const user = useSelector(authSelectors.selectUser);
    const [backendError, setError] = useState<AppError>(null);
    const dispatch = useDispatch();

    const clearState = () => {
        setError(null);
    };

    const signUp = (data: RegisterPostData) => dispatch(authActions.signUp(data));

    const signIn = (data: LoginData) => {
        clearState();
        dispatch(authActions.signIn(data))
            .unwrap()
            .catch((error: any) => {
                const convertedFirebaseError = convertFirebaseErrorToError(error);
                setError(convertedFirebaseError);
            });
    };

    const sendResetPasswordMail = (data: { email: string }) => {
        dispatch(authActions.sendResetPasswordMail(data))
            .unwrap()
            .catch((error: any) => {
                const convertedFirebaseError = convertFirebaseErrorToError(error);
                setError(convertedFirebaseError);
            });
    };

    const changePassword = (data: { password: string; oobCode: string }) => {
        dispatch(authActions.changePassword(data))
            .unwrap()
            .catch((error: any) => {
                const convertedFirebaseError = convertFirebaseErrorToError(error);
                setError(convertedFirebaseError);
            });
    };

    const logOut = () => {
        dispatch(authActions.logOut())
            .unwrap()
            .catch((error: any) => {
                const convertedFirebaseError = convertFirebaseErrorToError(error);
                setError(convertedFirebaseError);
            });
    };

    return {
        user,
        backendError,
        signUp,
        logOut,
        signIn,
        sendResetPasswordMail,
        changePassword,
    };
};

export { useAuth };
