import { createSelector } from '@reduxjs/toolkit';
import { selectState } from '../slice';
import { SettlementState } from '../state';

const selectGrantRequestState = createSelector(
    selectState,
    (state: SettlementState) => state.grantRequestState,
);

export { selectGrantRequestState };
