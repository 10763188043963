import React, { useEffect, useState } from 'react';
import { FormControl, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FormProvider, useForm } from 'react-hook-form';
import { BackdropAppLoader, Input, Modal } from 'components/common';
import { useAppTranslation } from 'utils/hooks/translation';
import { useDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import {
    actions as settlementActions,
    selectors as settlementSelectors,
} from 'redux/slices/settlement';

import ConfirmModal from 'components/common/ConfirmModal/ConfirmModal';
import { StyledActionAppButton, StyledLineStack } from './ExpenseTypeModal.styled';
import {
    ExpenseTypeFromData,
    ExpenseModalVariant,
    ExpenseType,
} from '../../utils/interfaces/document';

type ExpenseTypeModalProps = {
    isOpen: boolean;
    onClose: () => void;
    type: ExpenseModalVariant;
    expenseType?: ExpenseType;
};

const ExpenseTypeModal = ({ isOpen, onClose, type, expenseType }: ExpenseTypeModalProps) => {
    const dispatch = useDispatch();
    const addExpenseTypeState = useSelector(settlementSelectors.selectAddExpenseTypeState);
    const editExpenseTypeState = useSelector(settlementSelectors.selectEditExpenseTypeState);
    const [inputValid, setInputValid] = useState<boolean>();

    const [isDeleteExpenseTypeConfirmModalOpen, setIsDeleteExpenseTypeConfirmModalOpen] =
        useState(false);

    const { t: settingsT } = useAppTranslation('settings');
    const { t: commonT } = useAppTranslation('common');

    const form = useForm<ExpenseTypeFromData>({
        defaultValues: {
            name: expenseType?.name || '',
        },
    });

    const {
        handleSubmit,
        formState: { errors },
        setValue,
        reset: resetForm,
    } = form;

    useEffect(() => {
        resetForm();
    }, [isOpen]);

    const expenseFormT = (itemName: string) => settingsT(`expenseForm.${itemName}`);
    const expenseListT = (itemName: string) => settingsT(`expenseList.${itemName}`);

    const isExpenseNameValid = (name: string) =>
        (type !== 'edit' || expenseType?.name !== name.trim()) && name.trim() !== '';

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const name = event.target.value;
        setInputValid(isExpenseNameValid(name));
        setValue('name', name);
    };

    const getTitle = () => {
        switch (type) {
            case 'add':
                return expenseFormT('addExpenseTitle');
            default:
                return expenseFormT('editExpenseTitle');
        }
    };

    const closeDeleteExpenseTypeConfirmModal = () => setIsDeleteExpenseTypeConfirmModalOpen(false);
    const openDeleteExpenseTypeConfirmModal = () => setIsDeleteExpenseTypeConfirmModalOpen(true);

    const onConfirmDeleteExpenseTypeClick = () => {
        if (expenseType) {
            dispatch(settlementActions.deleteExpenseType({ expenseTypeId: expenseType.id }));
            closeDeleteExpenseTypeConfirmModal();
            onClose();
        }
    };

    const onSubmit = (data: ExpenseTypeFromData) => {
        const submitData = {
            name: data.name.trim(),
        };

        if (type === 'add') {
            dispatch(settlementActions.addExpenseType(submitData))
                .unwrap()
                .then(() => {
                    onClose();
                    form.reset();
                });
        }
        if (type === 'edit') {
            dispatch(
                settlementActions.editExpenseType({
                    id: (expenseType as ExpenseType).id,
                    name: submitData.name,
                }),
            )
                .unwrap()
                .then(() => onClose());
        }
    };

    return (
        <>
            <Modal
                open={isOpen}
                maxWidth="910px"
                body={
                    <Stack flexDirection="column" width="100%">
                        <Stack
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Typography variant="h5" color="secondary">
                                {getTitle()}
                            </Typography>
                            <IconButton color="primary" onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Stack>

                        <FormProvider {...form}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <StyledLineStack marginTop="23px" marginBottom="34px" />
                                <FormControl fullWidth style={{ width: '100%' }}>
                                    <Stack gap="35px" width="100%">
                                        <Input
                                            label={expenseFormT('expenseNameLabel')}
                                            rules={{ required: commonT('form.required') }}
                                            name="name"
                                            fullWidth
                                            onChange={handleNameChange}
                                            error={!!errors?.name}
                                            helperText={errors?.name?.message || ''}
                                        />
                                    </Stack>
                                </FormControl>
                                <StyledLineStack marginTop="34px" marginBottom="17px" />
                                <Stack
                                    flexDirection="row"
                                    width="100%"
                                    justifyContent="space-between"
                                >
                                    <StyledActionAppButton type="submit" disabled={!inputValid}>
                                        {expenseFormT('save')}
                                    </StyledActionAppButton>
                                    {type === 'edit' && (
                                        <StyledActionAppButton
                                            variant="outlined"
                                            onClick={openDeleteExpenseTypeConfirmModal}
                                        >
                                            {expenseFormT('delete')}
                                        </StyledActionAppButton>
                                    )}
                                </Stack>
                            </form>
                        </FormProvider>
                        <BackdropAppLoader
                            open={addExpenseTypeState.loading || editExpenseTypeState.loading}
                        />
                    </Stack>
                }
                actionButtons={<div />}
            />
            <ConfirmModal
                open={isDeleteExpenseTypeConfirmModalOpen}
                onClose={closeDeleteExpenseTypeConfirmModal}
                title={expenseListT('areYouSureYouWantToDelete')}
                firstButtonLabel={expenseListT('cancel')}
                secondButtonLabel={expenseListT('yesDelete')}
                onFirstButtonClick={closeDeleteExpenseTypeConfirmModal}
                onSecondButtonClick={onConfirmDeleteExpenseTypeClick}
            />
        </>
    );
};

export default ExpenseTypeModal;
