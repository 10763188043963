import type { GrantFormData, GrantPostDto } from 'utils/interfaces/grant';

export const mapGrantToDto = (grant: Omit<GrantFormData, 'instituteId'>): GrantPostDto => ({
    grossAmount: Number(grant.grossAmount),
    month: Number(grant.month),
    year: Number(grant.year),
    category: {
        ...grant.category,
        amount:
            grant.category.type === 'EARLY_DEVELOPMENT_SUPPORT'
                ? undefined
                : Number(grant.category.amount),
    },
});
