import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { currentEnvName } from './currentEnviroment';

const configs = {
    devLocal: {
        apiKey: 'AIzaSyCKWaCPQL78rXl3z652YiK_cCA_07cAQDI',
        authDomain: 'dotacje-dev.firebaseapp.com',
        projectId: 'dotacje-dev',
        storageBucket: 'dotacje-dev.appspot.com',
        messagingSenderId: '502782104835',
        appId: '1:502782104835:web:2d3cfe2640c39cce708536',
        measurementId: 'G-GFF5LJMQ3M',
    },
    devRemote: {},
    uat: {
        apiKey: 'AIzaSyAu7odODXvIaP4waE0WB1zkAq2gW5pFHrI',
        authDomain: 'dotacje-uat-ab2b3.firebaseapp.com',
        projectId: 'dotacje-uat-ab2b3',
        storageBucket: 'dotacje-uat-ab2b3.appspot.com',
        messagingSenderId: '1035486990673',
        appId: '1:1035486990673:web:ff8d9c20510d89ca918370',
        measurementId: 'G-VMK3B24KVV',
    },
    prod: {
        apiKey: 'AIzaSyDVQGbEcRTNt-leONuWTwJk-Z0elojO9hQ',
        authDomain: 'dotacje-prod.firebaseapp.com',
        projectId: 'dotacje-prod',
        storageBucket: 'dotacje-prod.appspot.com',
        messagingSenderId: '634556113333',
        appId: '1:634556113333:web:2aa76e73cc8aeb2f3339fc',
        measurementId: 'G-EKVXHG1784',
    },
};

configs.devRemote = configs.devLocal;

export const config = configs[currentEnvName];

const app = initializeApp(config);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);

export { auth, db };
export default app;
