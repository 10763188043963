import { PayloadAction } from '@reduxjs/toolkit';
import { ReportsState } from '../state';

export const initGenerateReport = (state: ReportsState) => {
    state.generateReportState = { loading: true };
};

export const clearGenerateReport = (state: ReportsState) => {
    state.generateReportState = { loading: false };
};

export const generateReportSuccess = (state: ReportsState) => {
    state.generateReportState = { loading: false, success: true };
};

export const generateReportError = (state: ReportsState, action: PayloadAction<string>) => {
    state.generateReportState = { loading: false, success: false, error: action.payload };
};
