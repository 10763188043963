import React from 'react';
import { Trans } from 'react-i18next';
import { Link as LinkMui, Stack, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useAppTranslation } from 'utils/hooks/translation';
import { noNumbersValidator, passwordValidator } from 'utils/functions/validators';
import { Checkbox } from '../common/Checkbox';
import { AppButton, Input, PasswordInput } from '..';
import Layout from '../Layout/Layout';
import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from '../../consts/links';

type Props = {
    errors: any;
    password: any;
    onNextClick: () => void;
};

const RegisterStepOne = ({ errors, password, onNextClick }: Props) => {
    const { t: authT } = useAppTranslation('auth');
    const { t: commonT } = useAppTranslation('common');

    return (
        <Stack gap="13px">
            <Layout.Container>
                <Stack direction="row" gap="25px">
                    <Input
                        rules={{
                            required: commonT('form.required'),
                            validate: {
                                noNumber: noNumbersValidator(commonT('form.noNumbers')),
                            },
                        }}
                        label={authT('registerPage.name')}
                        name="firstName"
                        fullWidth
                        error={!!errors.firstName}
                        helperText={(errors?.firstName?.message || '') as string}
                    />
                    <Input
                        rules={{
                            required: commonT('form.required'),
                            validate: {
                                noNumber: noNumbersValidator(commonT('form.noNumbers')),
                            },
                        }}
                        label={authT('registerPage.surname')}
                        name="lastName"
                        fullWidth
                        error={!!errors.lastName}
                        helperText={(errors?.lastName?.message || '') as string}
                    />
                </Stack>
                <Input
                    rules={{ required: commonT('form.required') }}
                    label={authT('registerPage.email')}
                    name="email"
                    type="email"
                    fullWidth
                    error={!!errors.email}
                    helperText={(errors?.email?.message || '') as string}
                />
                <PasswordInput
                    rules={{
                        validate: {
                            passwordValidator: passwordValidator(
                                authT('registerPage.errors.passwordLength'),
                            ),
                        },
                    }}
                    label={authT('registerPage.password')}
                    name="password"
                    fullWidth
                    error={!!errors.password}
                    helperText={(errors?.password?.message || '') as string}
                />
                <Stack direction="row" gap="8px" marginTop="-10px">
                    <CheckCircleIcon
                        fontSize="small"
                        color={passwordValidator()(password || '') ? 'primary' : 'disabled'}
                    />
                    <Typography variant="body2">{authT('registerPage.eightCharacters')}</Typography>
                </Stack>
            </Layout.Container>
            <Checkbox
                name="termsAndPolicy"
                label={
                    <Trans i18nKey="auth:registerPage.terms">
                        {'Wyrażam zgodę na'}
                        <LinkMui
                            underline="none"
                            href={TERMS_AND_CONDITIONS}
                            rel="noopener,noreferrer"
                            target="_blank"
                        >
                            {'regulamin'}
                        </LinkMui>
                        {'oraz'}
                        <LinkMui
                            underline="none"
                            href={PRIVACY_POLICY}
                            rel="noopener,noreferrer"
                            target="_blank"
                        >
                            {' politykę prywatności'}
                        </LinkMui>
                    </Trans>
                }
                rules={{
                    required: authT('registerPage.errors.termsAndPolicy'),
                }}
                helperText={(errors?.termsAndPolicy?.message || '') as string}
            />
            <AppButton type="button" onClick={onNextClick} fullWidth>
                {commonT('button.next')}
            </AppButton>
        </Stack>
    );
};

export { RegisterStepOne };
