export const colors = {
    primary: '#3A48E2',
    secondary: '#383A47',
    text3: '#797B89',
    text4: '#C6C8D3',
    neutral: '#FFFFFF',
    background1: '#474958',
    background2: '#E8E9ED',
    background3: '#F8F8FA',
    mainText: '#333333',
    backdrop: 'rgba(221, 221, 221, 0.6)',
    green1: '#219653',
    error: '#FD6003',
    lightGray: '#C4C4C4',
    secondaryText: '#545454',
    black100: '#171717',
};
