import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { Container, FormControl, Stack, Typography, styled } from '@mui/material';
import { actions, selectors } from 'redux/slices/settlement';
import { useDispatch } from 'redux/store';
import { useAppTranslation } from 'utils/hooks/translation';
import { BackButton, InvoiceForm, BackdropAppLoader } from 'components';
import { HOME_PAGE } from 'consts/routes';
import { useErrorTranslation } from 'utils/functions/error';
import { DocTypeFromForm } from 'utils/interfaces/document';
import { createInstituteHash } from 'utils/functions/string';
import { RequestPaymentDialog } from '../../../components/RequestPaymentDialog/RequestPaymentDialog';
import { validateInstitutionLicence } from '../../../utils/functions/validators';
import { selectors as userSelectors } from '../../../redux/slices/auth';
import { useSnackBar } from 'contexts/SnackbarContext/SnackbarContext';

const StyledContainer = styled(Container)`
    margin-top: 50px;
    margin-bottom: 80px;
`;

const StyledStack = styled(Stack)`
    max-width: 630px;
    width: 100%;
`;

const StyledBackButton = styled(BackButton)`
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
`;

const AddDocumentPage = () => {
    const { t: commonT } = useAppTranslation('common');
    const { t: settlementT } = useAppTranslation('settlement');
    const dispatch = useDispatch();
    const { showSnackBar } = useSnackBar();
    const user = useSelector(userSelectors.selectUser);
    const selectedInstitution = useSelector(selectors.selectSelectedInstitution);
    const addDocumentState = useSelector(selectors.selectAddDocumentState);
    const navigate = useNavigate();
    const form = useForm<DocTypeFromForm>({
        defaultValues: {
            documentStatus: 'UNPAID',
            expenseDtos: {
                [createInstituteHash()]: {},
            },
        },
    });
    const { translateError } = useErrorTranslation('settlement:addDocument');
    const {
        handleSubmit,
        formState: { errors },
        watch,
        unregister,
        setValue,
    } = form;

    useEffect(() => {
        if (!selectedInstitution) {
            navigate(HOME_PAGE);
        }
    }, [selectedInstitution]);

    useEffect(() => {
        if (addDocumentState.success) {
            showSnackBar(
                commonT('snackbar.success'),
                settlementT('addDocument.success'),
                'success',
            );
            dispatch(actions.clearAddDocument());
        } else if (addDocumentState.error) {
            showSnackBar(
                commonT('snackbar.errorOccured'),
                translateError(addDocumentState?.error) || '',
                'error',
            );
            dispatch(actions.clearAddDocument());
        }
    }, [addDocumentState]);

    const onAddDocument = (data: DocTypeFromForm) => {
        if (selectedInstitution) {
            dispatch(actions.addDocument({ ...data, institutionId: selectedInstitution }))
                .unwrap()
                .then(() => navigate(HOME_PAGE));
        }
    };

    const goBack = () => navigate(HOME_PAGE);

    const documentStatus = watch('documentStatus');

    return (
        <StyledContainer>
            <BackdropAppLoader open={addDocumentState.loading} />
            <Stack alignItems="center">
                <StyledStack position="relative">
                    <StyledBackButton onClick={goBack} label={commonT('button.back')} />
                    <Typography variant="h2" textAlign="center">
                        {settlementT('addDocument.title')}
                    </Typography>
                </StyledStack>
                <FormProvider {...form}>
                    <FormControl fullWidth>
                        <form onSubmit={handleSubmit(onAddDocument)}>
                            <InvoiceForm
                                goBack={goBack}
                                errors={errors}
                                documentStatus={documentStatus}
                                unregister={unregister}
                                setValue={setValue}
                            />
                        </form>
                    </FormControl>
                </FormProvider>
            </Stack>
            <RequestPaymentDialog
                open={validateInstitutionLicence(selectedInstitution, user)}
                onClose={goBack}
            />
        </StyledContainer>
    );
};

export default AddDocumentPage;
