import { currentEnvName } from 'configs/currentEnviroment';

const LANDING_URLS = {
    devLocal: 'http://localhost:8000',
    devRemote: 'https://landing.dotacje.softwarepartner.pl',
    uat: 'https://landing.dotacje.softwarepartner.pl',
    prod: 'https://elfedu.pl/',
};

export const LANDING_URL = LANDING_URLS[currentEnvName];
export const LANDING_PAGE_HERO = `${LANDING_URL}/#hero`;
export const LANDING_PAGE_PAYMENTS = `${LANDING_URL}/#subscription`;
export const LANDING_PAGE_CONTACT = `${LANDING_URL}/#contact`;
export const PRIVACY_POLICY =
    'https://dotacje-documents.s3.eu-central-1.amazonaws.com/Elf-Edu-Polityka-Prywatnosci.pdf';
export const TERMS_AND_CONDITIONS =
    'https://dotacje-documents.s3.eu-central-1.amazonaws.com/Elf-Edu-Regulamin.pdf';
