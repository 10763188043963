import { Box, styled, Typography } from '@mui/material';
import { AppButton, Select } from 'components/common';
import { colors } from 'consts/colors';

export const StyledButton = styled(AppButton)({
    flex: 1,
    maxWidth: '188px',
});

export const StyledSelect = styled(Select)({
    minWidth: '216px',
    maxWidth: '500px',
    marginBottom: '25px',
});

export const StyledLine = styled(Box)({
    width: '15px',
    height: '1px',
    backgroundColor: colors.secondary,
});

export const StyledDateRangeTypography = styled(Typography)({
    marginBottom: '12px',
    fontWeight: 500,
});
