import { createSlice } from '@reduxjs/toolkit';
import { DOC_COST_CATEGORIES } from 'consts/docCostCategories';
import * as reducers from './reducers';
import type { SettlementState } from './state';

type Reducers = typeof reducers;

export const settlementSliceName = 'settlement';

const getDocCostCategorySelectedByDefault = () =>
    DOC_COST_CATEGORIES.find((category) => category.selectedByDefault) || DOC_COST_CATEGORIES[0];

const initialState: SettlementState = {
    documentsCostsList: null,
    documentsCostsListTotalElements: undefined,
    documentsCostsFilters: {
        grantCategory: {
            value: getDocCostCategorySelectedByDefault().value,
            subcategories:
                getDocCostCategorySelectedByDefault()
                    .subcategories?.filter((subcategory) => subcategory.selectedByDefault)
                    .map((subcategory) => subcategory.value) || [],
        },
    },
    institutionList: null,
    documentsCostsListFetchingState: { loading: false },
    addDocumentState: { loading: false },
    deleteDocumentState: { loading: false },
    getDocumentState: { loading: false },
    updateDocumentState: { loading: false },
    document: undefined,
    grantRequestState: { loading: false },
    getInstitutionListState: { loading: false },
    addInstitutionState: { loading: false },
    editInstitutionState: { loading: false },
    deleteInstitutionState: { loading: false },
    addInstitutionLicenseState: { loading: false },
    addInstitutionLicensePaymentState: { loading: false },
    getExpenseTypeListState: { loading: false },
    getAllExpenseTypeListState: { loading: false },
    expenseTypeList: null,
    allExpenseTypeList: null,
    addExpenseTypeState: { loading: false },
    editExpenseTypeState: { loading: false },
    deleteExpenseTypeState: { loading: false },
};

const slice = createSlice<SettlementState, Reducers & { reset: () => SettlementState }>({
    name: settlementSliceName,
    initialState,
    reducers: { ...reducers, reset: () => initialState },
});

export const { actions, reducer } = slice;

export const selectState = (state: any) => state[settlementSliceName];
