import { AuthState } from '../state';

export const initSendResetMail = (state: AuthState) => {
    state.sendResetMailState = { loading: true };
};

export const clearResetMailSuccess = (state: AuthState) => {
    state.sendResetMailState = { loading: false };
};

export const sendResetMailSuccess = (state: AuthState) => {
    state.sendResetMailState = { loading: false, success: true };
};

export const sendResetMailError = (state: AuthState) => {
    state.sendResetMailState = { loading: false, success: false, error: true };
};

export const clearResetPassword = (state: AuthState) => {
    state.resetPasswordState = { loading: false };
};

export const initResetPassword = (state: AuthState) => {
    state.resetPasswordState = { loading: true };
};

export const resetPasswordSuccess = (state: AuthState) => {
    state.resetPasswordState = { loading: false, success: true };
};

export const resetPasswordError = (state: AuthState) => {
    state.resetPasswordState = { loading: false, success: false };
};
