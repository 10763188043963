import { parseManuallyTypedDate } from 'utils/functions/dates';
import {
    Cost,
    DocCostDTO,
    DocType,
    DocTypeDTO,
    DocTypeFromForm,
    Expense,
    GetDocTypeDTO,
} from 'utils/interfaces/document';

export const mapExpenseToCosts = (expense: Expense): Cost => ({
    name: expense.expenseName,
    type: expense.expenseType,
    grossAmount: Number(expense.grossAmount),
    costCategory: {
        type: expense.category.type,
        percent:
            expense.category.type === 'EARLY_DEVELOPMENT_SUPPORT'
                ? undefined
                : Number(expense.category.percent),
    },
});

export const mapDocumentToDto = ({
    nip,
    documentName,
    contractor,
    documentStatus,
    expenseDtos,
    id: _id,
    value: _value,
    ...document
}: DocTypeFromForm): DocTypeDTO => {
    const [saleDate, paymentDate] = [document.saleDate, document.paymentDate].map(
        parseManuallyTypedDate,
    ) as [string, string | undefined];

    return {
        ...document,
        nip: nip || null,
        name: documentName,
        contractorName: contractor,
        costDocumentStatus: documentStatus,
        costs: Object.values(expenseDtos).reduce((acc: Cost[], value) => {
            acc.push(mapExpenseToCosts(value));

            return acc;
        }, []),
        saleDate: saleDate,
        paymentDate: paymentDate,
    };
};

export const mapDtoToDocument = (document: GetDocTypeDTO): DocType => ({
    id: document.id,
    documentName: document.name,
    nip: document?.nip || '',
    contractor: document.contractorName,
    saleDate: document.saleDate,
    documentStatus: document.costDocumentStatus,
    paymentDate: document.paymentDate,
    expenseDtos: document.costs.map((cost) => ({
        expenseName: cost.name,
        expenseType: cost.type,
        grossAmount: cost.grossAmount.toString(),
        category: { type: cost.costCategory.type, percent: cost.costCategory?.percent?.toString() },
    })),
    attachments: document.attachments,
    value: document.costsGrossSum,
});

export const mapDocCostDtoToDocCost = ({
    documentId,
    documentName,
    costDocumentStatus,
    saleDate,
    costType,
    categoryType,
    grossAmount,
}: DocCostDTO) => ({
    documentId,
    documentName,
    costDocumentStatus,
    saleDate,
    costType,
    categoryType,
    grossAmount,
});
