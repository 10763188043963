import React, { useState } from 'react';
import { Stack, Typography, styled } from '@mui/material';
import {
    noNumbersValidator,
    regonValidator,
    rspoValidator,
    zipCodeValidator,
} from 'utils/functions/validators';
import { colors } from 'consts/colors';
import { zipCodeMask } from 'consts/masks';
import { useAppTranslation } from 'utils/hooks/translation';
import { findVoivodeshipsOption, voivodeshipsOptions } from 'utils/functions/options';
import Layout from '../Layout/Layout';
import { AppButton, Input, MaskInput, Select } from '../common';
import HTTPService from '../../services/HTTPService/HTTPService';
import { logAxiosError } from '../../utils/errors';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { TypographyWithValidationError } from '../common/FormError/ValidationErrorText.styled';

type Props = {
    hash: string;
    onRemoveClick: (hash: string) => void;
    errors: any;
    deleteDisabled: boolean;
    setValue: UseFormSetValue<any>;
};

const DeleteButton = styled(AppButton)(() => ({
    color: colors.primary,
    textDecorationLine: 'underline',
    ':hover': {
        textDecorationLine: 'underline',
    },
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '22px',
}));

const RegisterInstitutionForm = ({
    hash,
    onRemoveClick,
    errors,
    deleteDisabled,
    setValue,
}: Props) => {
    const { t: authT } = useAppTranslation('auth');
    const { t: commonT } = useAppTranslation('common');
    const [gusDataNotFound, setGusDataNotFound] = useState(false);

    const fetchGUSDataAndSetFormValues = (regon: string) => {
        HTTPService.getGUSData(regon)
            .then(({ data }) => {
                setGusDataNotFound(false);
                setValue(`institutionDtos.${hash}.name`, data.name);
                setValue(`institutionDtos.${hash}.rspo`, data.rspo);
                setValue(
                    `institutionDtos.${hash}.address.street`,
                    `${data.street} ${data.buildingNumber}${
                        data.premisesNumber && ` / ${data.premisesNumber}`
                    }`,
                );
                setValue(`institutionDtos.${hash}.address.zipCode`, data.zipCode);
                setValue(`institutionDtos.${hash}.address.city`, data.city);
                setValue(
                    `institutionDtos.${hash}.address.voivodeship`,
                    findVoivodeshipsOption(data.province)?.value,
                );
            })
            .catch((error) => {
                if (error.response.data.status === 'NOT_FOUND') {
                    setGusDataNotFound(true);
                }
                logAxiosError(error);
            });
    };

    const onRegonChange = (event: any) => {
        const value = event.target.value.trim();
        setValue(`institutionDtos.${hash}.regon`, value);
        setGusDataNotFound(false);

        if (regonValidator('')(value)) {
            fetchGUSDataAndSetFormValues(value);
        }
    };

    return (
        <Layout.Container>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h5">{authT('registerPage.instituteInfo')}</Typography>
                <DeleteButton
                    disabled={deleteDisabled}
                    variant="inline"
                    onClick={() => onRemoveClick(hash)}
                >
                    {commonT('button.delete')}
                </DeleteButton>
            </Stack>
            <Input
                label={authT('registerPage.instituteNameLabel')}
                rules={{ required: commonT('form.required') }}
                name={`institutionDtos.${hash}.name`}
                fullWidth
                error={!!errors?.institutionDtos?.[hash]?.name}
                helperText={errors?.institutionDtos?.[hash]?.name?.message}
            />
            <Stack direction="row" gap="25px">
                <Stack width="100%">
                    <Input
                        label={authT('registerPage.regonLabel')}
                        rules={{
                            validate: {
                                regonValidator: regonValidator(authT('registerPage.errors.regon')),
                            },
                        }}
                        name={`institutionDtos.${hash}.regon`}
                        fullWidth
                        error={!!errors?.institutionDtos?.[hash]?.regon}
                        helperText={errors?.institutionDtos?.[hash]?.regon?.message}
                        onChange={onRegonChange}
                    />
                    <TypographyWithValidationError displayError={gusDataNotFound} variant="body1">
                        {gusDataNotFound
                            ? authT('registerPage.regonGUSDataNotFound')
                            : authT('registerPage.regonGUSData')}
                    </TypographyWithValidationError>
                </Stack>
                <Input
                    label={authT('registerPage.rspoLabel')}
                    rules={{
                        validate: {
                            rspoValidator: rspoValidator(authT('registerPage.errors.rspo')),
                        },
                    }}
                    name={`institutionDtos.${hash}.rspo`}
                    fullWidth
                    error={!!errors?.institutionDtos?.[hash]?.rspo}
                    helperText={errors?.institutionDtos?.[hash]?.rspo?.message}
                />
            </Stack>
            <Typography variant="h5">{authT('registerPage.addressInfo')}</Typography>
            <Input
                label={authT('registerPage.streetAndNumberLabel')}
                rules={{ required: commonT('form.required') }}
                name={`institutionDtos.${hash}.address.street`}
                error={!!errors?.institutionDtos?.[hash]?.address?.street}
                helperText={errors?.institutionDtos?.[hash]?.address?.street?.message}
            />
            <Stack direction="row" gap="25px">
                <MaskInput
                    label={authT('registerPage.zipCodeLabel')}
                    rules={{
                        validate: {
                            zipCodeValidator: zipCodeValidator(
                                authT('registerPage.errors.zipCode'),
                            ),
                        },
                    }}
                    name={`institutionDtos.${hash}.address.zipCode`}
                    fullWidth
                    error={!!errors.institutionDtos?.[hash]?.address?.zipCode}
                    helperText={errors?.institutionDtos?.[hash]?.address?.zipCode?.message}
                    mask={zipCodeMask}
                />
                <Input
                    label={authT('registerPage.cityLabel')}
                    rules={{
                        required: commonT('form.required'),
                        validate: {
                            noNumber: noNumbersValidator(commonT('form.noNumbers')),
                        },
                    }}
                    name={`institutionDtos.${hash}.address.city`}
                    fullWidth
                    error={!!errors?.institutionDtos?.[hash]?.address?.city}
                    helperText={errors?.institutionDtos?.[hash]?.address?.city?.message}
                />
            </Stack>
            <Select
                label={authT('registerPage.voivodeshipLabel')}
                rules={{ required: commonT('form.required') }}
                name={`institutionDtos.${hash}.address.voivodeship`}
                options={voivodeshipsOptions}
                fullWidth
            />
        </Layout.Container>
    );
};

export { RegisterInstitutionForm };
