import React, { useState } from 'react';
import { MoreHoriz } from '@mui/icons-material';
import { Menu, Typography } from '@mui/material';
import { useAppTranslation } from 'utils/hooks/translation';
import ConfirmModal from 'components/common/ConfirmModal/ConfirmModal';
import { actions as usersActions } from 'redux/slices/users';
import { useDispatch } from 'redux/store';
import { StyledMenuItem, StyledMoreIconButton } from './InstitutionUserTableMoreMenu.styled';
import { UserDetailsType } from '../../../utils/interfaces/auth';
import { Institution } from '../../../utils/interfaces/institution';

type InstitutionUserTableMoreMenuProps = {
    userId: UserDetailsType['id'];
    institutionId: Institution['id'];
};

const InstitutionUserTableMoreMenu = ({
    userId,
    institutionId,
}: InstitutionUserTableMoreMenuProps) => {
    const dispatch = useDispatch();

    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [
        isDeleteUserFromInstitutionConfirmModalOpen,
        setIsDeleteUserFromInstitutionConfirmModalOpen,
    ] = useState(false);

    const openNavMenu = (event: React.MouseEvent<HTMLElement>) =>
        setAnchorElNav(event.currentTarget);
    const closeNavMenu = () => setAnchorElNav(null);

    const { t: settingsT } = useAppTranslation('settings');
    const institutionsListT = (itemName: string) => settingsT(`institutionsList.${itemName}`);
    const closeDeleteUserFromInstitutionConfirmModal = () =>
        setIsDeleteUserFromInstitutionConfirmModalOpen(false);

    const onConfirmDeleteUserClick = () => {
        dispatch(
            usersActions.deleteUserFromInstitution({
                userId: userId,
                institutionId: institutionId,
            }),
        ).unwrap();
        closeDeleteUserFromInstitutionConfirmModal();
    };

    const onDeleteClick = () => {
        setIsDeleteUserFromInstitutionConfirmModalOpen(true);
        closeNavMenu();
    };

    return (
        <>
            <StyledMoreIconButton onClick={openNavMenu}>
                <MoreHoriz />
            </StyledMoreIconButton>
            <Menu
                anchorEl={anchorElNav}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={!!anchorElNav}
                onClose={closeNavMenu}
                componentsProps={{
                    backdrop: { style: { backgroundColor: 'transparent' } },
                }}
            >
                <StyledMenuItem onClick={onDeleteClick}>
                    <Typography textAlign="left">{institutionsListT('deleteUser')}</Typography>
                </StyledMenuItem>
            </Menu>
            <ConfirmModal
                open={isDeleteUserFromInstitutionConfirmModalOpen}
                onClose={closeDeleteUserFromInstitutionConfirmModal}
                title={institutionsListT('areYouSureYouWantToDeleteUser')}
                firstButtonLabel={institutionsListT('notYet')}
                secondButtonLabel={institutionsListT('yesDelete')}
                onFirstButtonClick={closeDeleteUserFromInstitutionConfirmModal}
                onSecondButtonClick={onConfirmDeleteUserClick}
            />
        </>
    );
};

export default InstitutionUserTableMoreMenu;
