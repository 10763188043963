import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectors as authSelectors } from 'redux/slices/auth';
import { BackdropAppLoader } from '..';

type Props = {
    fallback: string;
    component: React.ReactNode;
};

// Route that is accessible once you are not logged-in user
const AnonymousRoute = ({ fallback, component }: Props) => {
    const userState = useSelector(authSelectors.selectGetUserState);

    return (
        <>
            {userState.loading && <BackdropAppLoader open={userState.loading} />}
            {!userState.loading && userState.success && <Navigate to={fallback} />}
            {!userState.loading && !userState.success && component}
        </>
    );
};

export { AnonymousRoute };
