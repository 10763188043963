import React from 'react';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Stack,
    Typography,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { SelectOptions } from 'utils/interfaces/common';
import {
    StyledCheckboxLabelTypography,
    StyledFormControlLabel,
    StyledLabelTypography,
} from './Checkboxes.styled';
import { FormError } from '../FormError/FormError';

type CheckboxesProps = {
    name: string;
    defaultValue?: any[];
    options: SelectOptions;
    label?: string;
    disabled?: boolean;
    error?: string;
} & Pick<React.ComponentProps<typeof Controller>, 'rules'>;

const Checkboxes = ({
    name,
    defaultValue,
    options,
    label,
    disabled,
    rules,
    error,
}: CheckboxesProps) => {
    const { control } = useFormContext();

    return (
        <FormControl>
            <StyledLabelTypography variant="h5">{label}</StyledLabelTypography>
            <FormGroup>
                <Controller
                    name={name}
                    rules={rules}
                    defaultValue={defaultValue || []}
                    control={control}
                    render={({ field }) => (
                        <>
                            {options.map((option) => (
                                <StyledFormControlLabel
                                    key={option.value}
                                    label={
                                        <StyledCheckboxLabelTypography
                                            variant="body1"
                                            disabled={option.disabled || disabled}
                                        >
                                            {option.name}
                                        </StyledCheckboxLabelTypography>
                                    }
                                    disabled={option.disabled || disabled}
                                    control={
                                        <Checkbox
                                            value={option.value}
                                            checked={field.value.includes(option.value)}
                                            onChange={(event, checked) =>
                                                field.onChange(
                                                    checked
                                                        ? [...field.value, event.target.value]
                                                        : field.value.filter(
                                                              (value: any) =>
                                                                  value !== event.target.value,
                                                          ),
                                                )
                                            }
                                        />
                                    }
                                />
                            ))}
                        </>
                    )}
                />
            </FormGroup>
            {!!error && (
                <Stack marginTop="22px">
                    <FormError error={error} />
                </Stack>
            )}
        </FormControl>
    );
};

export default Checkboxes;
