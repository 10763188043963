import { useMemo } from 'react';
import { selectors as settingsSelectors } from 'redux/slices/settings';
import { useSelector } from 'react-redux';
import { FullInstitution } from 'utils/interfaces/institution';
import { actions as settlementActions } from '../../../../../redux/slices/settlement';
import { useDispatch } from '../../../../../redux/store';

export const usePaymentsTab = () => {
    const dispatch = useDispatch();
    const fullInstitutionsList = useSelector(settingsSelectors.selectFullInstitutionsList);
    const { loading } = useSelector(settingsSelectors.selectGetFullInstitutionsListState);

    const { institutionsWithLicense, institutionsWithoutLicense } = useMemo(() => {
        if (!fullInstitutionsList) {
            return {
                institutionsWithLicense: [],
                institutionsWithoutLicense: [],
            };
        }

        const dividedInstitutions = fullInstitutionsList?.reduce(
            (
                acc: {
                    institutionsWithLicense: FullInstitution[];
                    institutionsWithoutLicense: FullInstitution[];
                },
                institution: FullInstitution,
            ) => {
                if (institution?.licence?.licenceStatus === 'VALID') {
                    acc.institutionsWithLicense.push(institution);
                } else {
                    acc.institutionsWithoutLicense.push(institution);
                }

                return acc;
            },
            {
                institutionsWithLicense: [],
                institutionsWithoutLicense: [],
            },
        );

        return dividedInstitutions;
    }, [fullInstitutionsList]);

    const onButtonPress = (institution: FullInstitution) => {
        if (institution.licence) {
            dispatch(
                settlementActions.createNewInstitutionLicensePayment({
                    institutionId: institution.id,
                }),
            );
        } else {
            dispatch(settlementActions.addInstitutionLicense({ institutionId: institution.id }));
        }
    };

    return {
        institutionsWithLicense: institutionsWithLicense,
        institutionsWithoutLicense: institutionsWithoutLicense,
        onButtonPress: onButtonPress,
        isLoading: loading,
    };
};
