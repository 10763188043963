import { createAsyncThunk } from '@reduxjs/toolkit';
import { logAxiosError } from 'utils/errors';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
    DeleteUserFromInstitutionRequestDto,
    UpdateUserRequestDto,
    UserPostData,
} from 'utils/interfaces/users';
import { actions } from '../index';
import { actions as settlementActions } from '../../settlement/index';
import { actions as settingsActions } from '../../settings/index';
import { UserDetailsType } from 'utils/interfaces/auth';
import HTTPService from 'services/HTTPService/HTTPService';

dayjs.extend(customParseFormat);

const addUser = createAsyncThunk('users/add', async (payload: UserPostData, thunkApi) => {
    try {
        thunkApi.dispatch(actions.initAddUser());

        await HTTPService.createEmployeeUser(payload);

        thunkApi.dispatch(settlementActions.getInstitutionList());
        thunkApi.dispatch(settingsActions.getFullInstitutionsList());
        thunkApi.dispatch(actions.addUserSuccess());
    } catch (error: any) {
        logAxiosError(error);
        thunkApi.dispatch(actions.addUserError(error.response?.data || 'unknown error'));
        throw error;
    }
});

const editUser = createAsyncThunk(
    'users/edit',
    async (payload: UpdateUserRequestDto & { id: UserDetailsType['id'] }, thunkApi) => {
        try {
            thunkApi.dispatch(actions.initEditUser());

            const { id: userId, ...updateUserRequestDto } = payload;

            await HTTPService.editEmployeeUser(updateUserRequestDto, userId);

            thunkApi.dispatch(settlementActions.getInstitutionList());
            thunkApi.dispatch(settingsActions.getFullInstitutionsList());
            thunkApi.dispatch(actions.editUserSuccess());
        } catch (error: any) {
            logAxiosError(error);
            thunkApi.dispatch(actions.editUserError(error.response?.data || 'unknown error'));
            throw error;
        }
    },
);

const deleteUser = createAsyncThunk(
    'users/delete',
    async (payload: UserDetailsType['id'], thunkApi) => {
        try {
            thunkApi.dispatch(actions.initDeleteUser());

            await HTTPService.deleteUser(payload);

            thunkApi.dispatch(settlementActions.getInstitutionList());
            thunkApi.dispatch(settingsActions.getFullInstitutionsList());

            thunkApi.dispatch(actions.deleteUserSuccess());
        } catch (error: any) {
            logAxiosError(error);
            thunkApi.dispatch(actions.deleteUserError(error.response?.data || 'unknown error'));
            throw error;
        }
    },
);

const deleteUserFromInstitution = createAsyncThunk(
    'users/deleteFromInstitution',
    async (payload: DeleteUserFromInstitutionRequestDto, thunkApi) => {
        try {
            thunkApi.dispatch(actions.initDeleteUserFromInstitution());

            await HTTPService.deleteUserFromInstitution(payload);

            thunkApi.dispatch(settlementActions.getInstitutionList());
            thunkApi.dispatch(settingsActions.getFullInstitutionsList());

            thunkApi.dispatch(actions.deleteUserFromInstitutionSuccess());
        } catch (error: any) {
            logAxiosError(error);
            thunkApi.dispatch(
                actions.deleteUserFromInstitutionError(
                    error.response?.data?.code || 'unknown error',
                ),
            );
            throw error;
        }
    },
);

const getSelectedUser = createAsyncThunk(
    'users/getSelected',
    async (payload: UserDetailsType['id'], thunkApi) => {
        try {
            thunkApi.dispatch(actions.initGetSelectedUser());

            const { data } = await HTTPService.getEmployeeUser(payload);

            return thunkApi.dispatch(actions.getSelectedUserSuccess(data));
        } catch (error: any) {
            logAxiosError(error);
            thunkApi.dispatch(
                actions.getSelectedUserError(error.response?.data || 'unknown error'),
            );
            throw error;
        }
    },
);

export { addUser, editUser, getSelectedUser, deleteUser, deleteUserFromInstitution };
