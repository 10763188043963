import { createSelector } from '@reduxjs/toolkit';
import { selectState } from '../slice';
import { SettingsState } from '../state';

const selectFullInstitutionsList = createSelector(
    selectState,
    (state: SettingsState) => state.fullInstitutionsList,
);

const selectGetFullInstitutionsListState = createSelector(
    selectState,
    (state: SettingsState) => state.getFullInstitutionsListState,
);

export { selectFullInstitutionsList, selectGetFullInstitutionsListState };
