import { createAsyncThunk } from '@reduxjs/toolkit';
import { Institution } from '../../../../utils/interfaces/institution';
import { RootState } from '../../../store';
import { actions } from '../index';
import HTTPService from '../../../../services/HTTPService/HTTPService';
import { logAxiosError } from '../../../../utils/errors';

const addInstitutionLicense = createAsyncThunk<
    void,
    { institutionId: Institution['id'] },
    { state: RootState }
>('institutionsLicense/add', async (payload, thunkApi) => {
    try {
        thunkApi.dispatch(actions.initAddInstitutionLicense());

        const { data } = await HTTPService.createInstitutionLicense(payload.institutionId);
        window.open(data.payment.paymentProviderDetails.redirectUri, '_blank');

        thunkApi.dispatch(actions.addInstitutionLicenseSuccess());
    } catch (error: any) {
        logAxiosError(error);
        thunkApi.dispatch(
            actions.addInstitutionLicenseError(error.response?.data || 'unknown error'),
        );
        throw error;
    }
});

const createNewInstitutionLicensePayment = createAsyncThunk<
    void,
    { institutionId: Institution['id'] },
    { state: RootState }
>('institutionsLicensePayment/add', async (payload, thunkApi) => {
    try {
        thunkApi.dispatch(actions.initAddInstitutionLicensePayment());

        const { data } = await HTTPService.createNewInstitutionLicensePayment(
            payload.institutionId,
        );
        window.open(data.payment.paymentProviderDetails.redirectUri, '_blank');

        thunkApi.dispatch(actions.addInstitutionLicensePaymentSuccess());
    } catch (error: any) {
        logAxiosError(error);
        thunkApi.dispatch(
            actions.addInstitutionLicensePaymentError(error.response?.data || 'unknown error'),
        );
        throw error;
    }
});

export { addInstitutionLicense, createNewInstitutionLicensePayment };
