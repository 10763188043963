import { createAsyncThunk } from '@reduxjs/toolkit';
import { logAxiosError } from 'utils/errors';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { actions } from '../index';
import HTTPService from 'services/HTTPService/HTTPService';

dayjs.extend(customParseFormat);

const getFullInstitutionsList = createAsyncThunk(
    'fullInstitutionsList/get',
    async (payload, thunkApi) => {
        try {
            thunkApi.dispatch(actions.initGetFullInstitutionsList());

            const fullInstitutionsListResponse = await HTTPService.getFullInstitutionsList();

            return thunkApi.dispatch(
                actions.getFullInstitutionsListSuccess(fullInstitutionsListResponse.data),
            );
        } catch (error: any) {
            logAxiosError(error);
            thunkApi.dispatch(
                actions.getFullInstitutionsListError(error.response?.data || 'unknown error'),
            );
            throw error;
        }
    },
);

export { getFullInstitutionsList };
