import { PayloadAction } from '@reduxjs/toolkit';
import { SettlementState } from '../state';

export const initAddExpenseType = (state: SettlementState) => {
    state.addExpenseTypeState = { loading: true };
};

export const clearAddExpenseType = (state: SettlementState) => {
    state.addExpenseTypeState = { loading: false };
};

export const addExpenseTypeSuccess = (state: SettlementState) => {
    state.addExpenseTypeState = { loading: false, success: true };
};

export const addExpenseTypeError = (state: SettlementState, action: PayloadAction<string>) => {
    state.addExpenseTypeState = { loading: false, success: false, error: action.payload };
};
