import { createSelector } from '@reduxjs/toolkit';
import { selectState } from '../slice';
import { AuthState } from '../state';

const selectUser = createSelector(selectState, (state: AuthState) => state.user);

const selectSignInState = createSelector(selectState, (state: AuthState) => state.signInState);

const selectSendResetMailState = createSelector(
    selectState,
    (state: AuthState) => state.sendResetMailState,
);

const selectResetPasswordState = createSelector(
    selectState,
    (state: AuthState) => state.resetPasswordState,
);

const selectSignUpState = createSelector(selectState, (state: AuthState) => state.signUpState);

const selectGetUserState = createSelector(selectState, (state: AuthState) => state.getUserState);

export {
    selectUser,
    selectSignUpState,
    selectSendResetMailState,
    selectResetPasswordState,
    selectSignInState,
    selectGetUserState,
};
