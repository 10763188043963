import React from 'react';
import TableMui from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import { createKeyHash } from 'utils/functions/string';
import { CircularProgress, Stack, TablePagination } from '@mui/material';
import TableBodyMui from '@mui/material/TableBody';
import type TableCellMui from '@mui/material/TableCell';
import type TableContainerMui from '@mui/material/TableContainer';
import TableHeadMui from '@mui/material/TableHead';
import { SortDirection } from 'utils/interfaces/common';
import {
    StyledTableCell,
    StyledTableContainer,
    StyledTableHead,
    StyledTableSortLabel,
} from './Table.styled';

export interface Column<RowData, TableData = undefined> {
    title: string;
    key: string;
    render?: (data: RowData, tableData?: TableData) => React.ReactNode;
    width?: string;
    sortable?: boolean;
}

export interface Columns<RowData, TableData> {
    template: Column<RowData, TableData>[];
    data: RowData[];
    tableData?: TableData;
    noDataPlaceholder?: React.ReactNode;
    loading?: boolean;
    headless?: boolean;
    customTableHeadComponent?: typeof StyledTableHead | typeof TableHeadMui;
    customTableCellComponent?: typeof StyledTableCell | typeof TableCellMui;
    customTableBodyComponent?: typeof TableBodyMui;
    customTableContainerComponent?: typeof StyledTableContainer | typeof TableContainerMui;
    paginated?: boolean;
    rowsPerPageOptions?: number[];
    count?: number;
    rowsPerPage?: number;
    sortProps?: {
        sortBy: string;
        direction: SortDirection;
        onSortColumnClick: (columnKey: string) => void;
    };
    page?: number;
    onPageChange?: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        page: number,
    ) => void;
    onRowsPerPageChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

const Table = <RowData extends Record<string, unknown>, TableData>({
    template,
    data,
    tableData,
    noDataPlaceholder,
    loading,
    headless = false,
    customTableHeadComponent,
    customTableCellComponent,
    customTableBodyComponent,
    customTableContainerComponent,
    paginated,
    rowsPerPageOptions = [10, 25, 50],
    count = -1,
    rowsPerPage,
    sortProps,
    page = 0,
    onPageChange = () => {},
    onRowsPerPageChange,
}: Columns<RowData, TableData>) => {
    const TableHead = customTableHeadComponent ?? StyledTableHead;
    const TableCell = customTableCellComponent ?? StyledTableCell;
    const TableBody = customTableBodyComponent ?? TableBodyMui;
    const TableContainer = customTableContainerComponent ?? StyledTableContainer;

    const loadingPlaceholder = (
        <TableRow>
            <TableCell colSpan={template.length}>
                <Stack alignItems="center" paddingTop="160px" paddingBottom="160px">
                    <CircularProgress />
                </Stack>
            </TableCell>
        </TableRow>
    );
    const bodyContent =
        !data.length && noDataPlaceholder ? (
            <TableRow>
                <TableCell colSpan={template.length}>{noDataPlaceholder}</TableCell>
            </TableRow>
        ) : (
            data.map((rowData, rowIndex) => {
                const hashForRow = createKeyHash(`${JSON.stringify(rowData)}${rowIndex}`);
                return (
                    <TableRow key={`row-${hashForRow}`}>
                        {template.map((row, columnIndex) => {
                            const hashForColumn = createKeyHash(
                                `${row.key}${rowData[row.key]}${columnIndex}`,
                            );
                            return (
                                <TableCell key={hashForColumn} width={template[columnIndex].width}>
                                    {row.render
                                        ? row.render(rowData, tableData)
                                        : (rowData[row.key] as string | number) ?? ''}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                );
            })
        );

    return (
        <TableContainer>
            <TableMui>
                {!headless && (
                    <TableHead>
                        <TableRow>
                            {template.map((column: Column<RowData, TableData>) => (
                                <StyledTableCell
                                    key={`head-${column.key}`}
                                    width={column.width}
                                    sortDirection="asc"
                                >
                                    {sortProps && column.sortable ? (
                                        <StyledTableSortLabel
                                            active={sortProps.sortBy === column.key}
                                            direction={
                                                sortProps.sortBy === column.key
                                                    ? sortProps.direction
                                                    : 'asc'
                                            }
                                            onClick={() =>
                                                sortProps.onSortColumnClick?.(column.key)
                                            }
                                        >
                                            {column.title}
                                        </StyledTableSortLabel>
                                    ) : (
                                        column.title
                                    )}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                )}

                <TableBody>{loading ? loadingPlaceholder : bodyContent}</TableBody>
            </TableMui>
            {paginated && (
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage || rowsPerPageOptions[0]}
                    page={page}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                />
            )}
        </TableContainer>
    );
};

export { Table };
