import React from 'react';
import { Paper, Stack, styled, Typography } from '@mui/material';
import { colors } from 'consts/colors';

type ValueCardProps = {
    label?: string;
    value?: number;
};

const StyledPaper = styled(Paper)({
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    border: '1px solid #FFFFFF',
    borderRadius: '4px',
    flex: 1,
});

const ValueCard = ({ label, value }: ValueCardProps) => (
    <StyledPaper>
        <Stack padding="12px">
            <Typography variant="h4" lineHeight="23px" color={colors.secondary}>
                {value ? `${value?.toFixed(2)}zł` : '--'}
            </Typography>
            <Typography variant="body2" lineHeight="24px" color={colors.secondary}>
                {label}
            </Typography>
        </Stack>
    </StyledPaper>
);

export default ValueCard;
