import React, { useEffect, useState } from 'react';
import { MoreHoriz } from '@mui/icons-material';
import { Menu, Typography, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ConfirmModal from 'components/common/ConfirmModal/ConfirmModal';
import { useAppTranslation } from 'utils/hooks/translation';
import { DocType } from 'utils/interfaces/document';
import { useDispatch } from 'redux/store';
import { actions, selectors } from 'redux/slices/settlement';
import { EDIT_DOCUMENT } from 'consts/routes';
import { StyledMenuItem, StyledMoreIconButton } from './DocumentTableMoreMenu.styled';
import { useSnackBar } from 'contexts/SnackbarContext/SnackbarContext';

type DocumentTableMoreMenuProps = {
    documentId: DocType['id'];
};

const DocumentTableMoreMenu = ({ documentId }: DocumentTableMoreMenuProps) => {
    const dispatch = useDispatch();
    const { showSnackBar } = useSnackBar();
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const openNavMenu = (event: React.MouseEvent<HTMLElement>) =>
        setAnchorElNav(event.currentTarget);
    const closeNavMenu = () => setAnchorElNav(null);
    const navigate = useNavigate();

    const { t: commonT } = useAppTranslation('common');
    const { t: settlementT } = useAppTranslation('settlement');
    const docsT = (itemName: string) => settlementT(`documentsList.${itemName}`);
    const deleteDocumentT = (itemName: string) => settlementT(`deleteDocument.${itemName}`);

    const deleteDocumentState = useSelector(selectors.selectDeleteDocumentState);
    const selectedInstitution = useSelector(selectors.selectSelectedInstitution);

    const [isDeleteDocumentConfirmModalOpen, setIsDeleteDocumentConfirmModalOpen] = useState(false);

    const closeDeleteDocumentConfirmModal = () => setIsDeleteDocumentConfirmModalOpen(false);
    const openDeleteDocumentConfirmModal = () => setIsDeleteDocumentConfirmModalOpen(true);

    const onEditClick = () => {
        closeNavMenu();
        documentId &&
            selectedInstitution &&
            navigate(EDIT_DOCUMENT(selectedInstitution, documentId));
    };
    const onDeleteClick = () => {
        openDeleteDocumentConfirmModal();
        closeNavMenu();
    };

    const onConfirmDeleteClick = () =>
        selectedInstitution &&
        dispatch(actions.deleteDocument({ institutionId: selectedInstitution, documentId }))
            .unwrap()
            .then(() => closeDeleteDocumentConfirmModal());

    useEffect(() => {
        if (deleteDocumentState.success) {
            showSnackBar(
                commonT('snackbar.success'),
                deleteDocumentT('successfullyDeletedDocument'),
                'success',
            );
            dispatch(actions.clearDeleteDocument());
        } else if (deleteDocumentState.error) {
            showSnackBar(
                commonT('snackbar.errorOccured'),
                deleteDocumentT('couldNotDeleteDocument'),
                'error',
            );
            dispatch(actions.clearDeleteDocument());
        }
    }, [deleteDocumentState]);

    return (
        <>
            <Stack width="100%" alignItems="flex-end">
                <StyledMoreIconButton onClick={openNavMenu}>
                    <MoreHoriz />
                </StyledMoreIconButton>
            </Stack>

            <Menu
                anchorEl={anchorElNav}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={!!anchorElNav}
                onClose={closeNavMenu}
                componentsProps={{
                    backdrop: { style: { backgroundColor: 'transparent' } },
                }}
            >
                <StyledMenuItem onClick={onEditClick}>
                    <Typography textAlign="left">{docsT('edit')}</Typography>
                </StyledMenuItem>
                <StyledMenuItem onClick={onDeleteClick}>
                    <Typography textAlign="left">{docsT('delete')}</Typography>
                </StyledMenuItem>
            </Menu>
            <ConfirmModal
                open={isDeleteDocumentConfirmModalOpen}
                onClose={closeDeleteDocumentConfirmModal}
                title={deleteDocumentT('documentDeletion')}
                message={deleteDocumentT('areYouSureYouWantToDeleteDocument')}
                firstButtonLabel={deleteDocumentT('cancel')}
                secondButtonLabel={deleteDocumentT('delete')}
                onFirstButtonClick={closeDeleteDocumentConfirmModal}
                onSecondButtonClick={onConfirmDeleteClick}
                loading={deleteDocumentState.loading}
            />
        </>
    );
};

export default DocumentTableMoreMenu;
