import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import InstitutionsListTable from 'components/InstitutionsList/InstitutionsListTable/InstitutionsListTable';
import { selectors as settingsSelectors } from 'redux/slices/settings';
import {
    actions as settlementActions,
    selectors as settlementSelectors,
} from 'redux/slices/settlement';
import { actions as userActions, selectors as userSelectors } from 'redux/slices/users';
import { Stack } from '@mui/material';
import { useAppTranslation } from 'utils/hooks/translation';
import InstitutionModal from 'components/InstitutionModal/InstitutionModal';
import { StyledAddInstitutionButton } from './InstitutionsTab.styled';
import {
    CAN_NOT_DELETE_OWNER_LAST_INSTITUTION,
    CAN_NOT_DELETE_USER_LAST_INSTITUTION,
} from 'consts/errors';
import { useDispatch } from 'redux/store';
import { useSnackBar } from 'contexts/SnackbarContext/SnackbarContext';
import { BackdropAppLoader } from '../../../../../components';

const InstitutionsTab = () => {
    const dispatch = useDispatch();
    const { showSnackBar } = useSnackBar();

    const addInstitutionState = useSelector(settlementSelectors.selectAddInstitutionState);
    const editInstitutionState = useSelector(settlementSelectors.selectEditInstitutionState);
    const deleteInstitutionState = useSelector(settlementSelectors.selectDeleteInstitutionState);
    const deleteUserFromInstitutionState = useSelector(
        userSelectors.selectDeleteUserFromInstitutionState,
    );

    const fullInstitutionsList = useSelector(settingsSelectors.selectFullInstitutionsList);
    const getFullInstitutionListState = useSelector(
        settingsSelectors.selectGetFullInstitutionsListState,
    );
    const { t: settingsT } = useAppTranslation('settings');
    const { t: commonT } = useAppTranslation('common');

    const institutionsT = (itemName: string) => settingsT(`institutionsList.${itemName}`);
    const institutionFormT = (itemName: string) => settingsT(`institutionForm.${itemName}`);

    const [isAddInstitutionModalOpen, setIsAddInstitutionModalOpen] = useState(false);

    const onAddInstitutionButtonClick = () => {
        setIsAddInstitutionModalOpen(true);
    };

    const closeInstitutionModal = () => {
        setIsAddInstitutionModalOpen(false);
    };

    const getDeleteInstitutionErrorText = () => {
        switch (deleteInstitutionState.error) {
            case CAN_NOT_DELETE_OWNER_LAST_INSTITUTION:
                return 'errors.canNotDeleteOwnerLastInstitution';
            case CAN_NOT_DELETE_USER_LAST_INSTITUTION:
                return 'errors.canNotDeleteInstitutionWithUsersWithLastInstitution';
            default:
                return 'errors.couldNotDeleteInstitution';
        }
    };

    useEffect(() => {
        if (addInstitutionState.success) {
            showSnackBar(
                commonT('snackbar.success'),
                institutionFormT('successfullyAddedInstitution'),
                'success',
            );
            dispatch(settlementActions.clearAddInstitution());
        } else if (addInstitutionState.error) {
            showSnackBar(
                commonT('snackbar.errorOccured'),
                institutionFormT('errors.couldNotAddInstitution'),
                'error',
            );
            dispatch(settlementActions.clearAddInstitution());
        }
    }, [addInstitutionState]);

    useEffect(() => {
        if (editInstitutionState.success) {
            showSnackBar(
                commonT('snackbar.success'),
                institutionFormT('successfullyEdittedInstitution'),
                'success',
            );
            dispatch(settlementActions.clearEditInstitution());
        } else if (editInstitutionState.error) {
            showSnackBar(
                commonT('snackbar.errorOccured'),
                institutionFormT('errors.couldNotEditInstitution'),
                'error',
            );
            dispatch(settlementActions.clearEditInstitution());
        }
    }, [editInstitutionState]);

    useEffect(() => {
        if (deleteInstitutionState.success) {
            showSnackBar(
                commonT('snackbar.success'),
                institutionsT('successfullyDeletedInstitution'),
                'success',
            );
            dispatch(settlementActions.clearDeleteInstitution());
        } else if (deleteInstitutionState.error) {
            const errorText = getDeleteInstitutionErrorText();
            showSnackBar(commonT('snackbar.errorOccured'), institutionsT(errorText), 'error');
            dispatch(settlementActions.clearDeleteInstitution());
        }
    }, [deleteInstitutionState]);

    useEffect(() => {
        if (deleteUserFromInstitutionState.success) {
            showSnackBar(
                commonT('snackbar.success'),
                institutionsT('successfullyDeletedUserFromInstitution'),
                'success',
            );
            dispatch(userActions.clearDeleteUserFromInstitution());
        } else if (deleteUserFromInstitutionState.error) {
            showSnackBar(
                commonT('snackbar.errorOccured'),
                deleteUserFromInstitutionState.error === CAN_NOT_DELETE_USER_LAST_INSTITUTION
                    ? institutionsT('errors.couldNotDeleteUserFromLastInstitution')
                    : institutionsT('errors.couldNotDeleteUserFromInstitution'),
                'error',
            );
            dispatch(userActions.clearDeleteUserFromInstitution());
        }
    }, [deleteUserFromInstitutionState]);

    return (
        <Stack gap="21px">
            <BackdropAppLoader open={deleteUserFromInstitutionState.loading} />
            <InstitutionsListTable
                institutions={fullInstitutionsList}
                loading={getFullInstitutionListState.loading}
            />
            <StyledAddInstitutionButton onClick={onAddInstitutionButtonClick}>
                {institutionsT('addInstitution')}
            </StyledAddInstitutionButton>
            <InstitutionModal
                isOpen={isAddInstitutionModalOpen}
                onClose={closeInstitutionModal}
                type="add"
            />
        </Stack>
    );
};

export default InstitutionsTab;
