import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useAppTranslation } from 'utils/hooks/translation';
import { UNPAID_STATUS } from 'consts/payments';
import { documentTypeOption } from './OptionsForPickers';
import Layout from '../Layout/Layout';
import { Input, Select, Datepicker } from '../common';
import { nipValidator } from 'utils/functions/validators';

const formFields = {
    documentName: 'documentName',
    nip: 'nip',
    contractor: 'contractor',
    saleDate: 'saleDate',
    documentStatus: 'documentStatus',
    paymentDate: 'paymentDate',
};

type FormErrors = {
    [key in keyof typeof formFields]?: {
        message: string;
    };
};

type Props = {
    errors: FormErrors;
    documentStatus: string;
};

const DocumentForm = ({ errors, documentStatus }: Props) => {
    const { t: commonT } = useAppTranslation('common');
    const { t: settlementT } = useAppTranslation('settlement');

    const disabled = documentStatus === UNPAID_STATUS;

    return (
        <>
            <Stack gap="12px" marginTop="37px">
                <Typography variant="h5">{settlementT('addDocument.grantTitle')}</Typography>
                <Typography variant="body2">{settlementT('addDocument.grantSubtitle')}</Typography>
            </Stack>
            <Layout.Container>
                <Input
                    rules={{ required: commonT('form.required') }}
                    label={settlementT('addDocument.documentName')}
                    name={formFields.documentName}
                    fullWidth
                    error={!!errors.documentName}
                    helperText={errors?.documentName?.message || ''}
                />
                <Input
                    label={settlementT('addDocument.nip')}
                    rules={{
                        validate: {
                            isInvalidNIP: (value) =>
                                nipValidator(commonT('form.isInvalidNIP'))(value, true),
                        },
                    }}
                    name={formFields.nip}
                    fullWidth
                    error={!!errors.nip}
                    helperText={errors?.nip?.message || ''}
                />
                <Input
                    label={settlementT('addDocument.contractor')}
                    name={formFields.contractor}
                    fullWidth
                    error={!!errors.contractor}
                    helperText={errors?.contractor?.message || ''}
                />
                <Datepicker
                    rules={{ required: commonT('form.required') }}
                    name={formFields.saleDate}
                    label={settlementT('addDocument.saleDate')}
                    defaultValue={new Date()}
                    error={!!errors.saleDate}
                    helperText={errors?.saleDate?.message || ''}
                />
                <Select
                    name={formFields.documentStatus}
                    label={settlementT('addDocument.documentStatus')}
                    options={documentTypeOption}
                />
                <Datepicker
                    rules={{ required: !disabled && commonT('form.required') }}
                    disabled={disabled}
                    name={formFields.paymentDate}
                    label={settlementT('addDocument.paymentDate')}
                    error={!!errors.paymentDate}
                    helperText={errors?.paymentDate?.message || ''}
                />
            </Layout.Container>
        </>
    );
};

export { DocumentForm };
