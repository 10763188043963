import { RegisterPostData, UserDetailsType } from 'utils/interfaces/auth';
import type { BudgetSummaryDto } from 'utils/interfaces/budget';
import {
    DocCostDTO,
    DocType,
    ExpenseType,
    GetDocTypeDTO,
    PostDocTypeDTO,
    PostExpenseTypeDto,
    UpdateExpenseTypeDto,
} from 'utils/interfaces/document';
import { Grant, GrantPostDto } from 'utils/interfaces/grant';
import {
    FullInstitution,
    Institution,
    InstitutionDto,
    InstitutionId,
    PostInstitutionDto,
    UpdateInstitutionDto,
} from 'utils/interfaces/institution';
import { GenerateReportDto, ReportType } from 'utils/interfaces/report';
import {
    DeleteUserFromInstitutionRequestDto,
    UpdateUserRequestDto,
    UserPostData,
} from 'utils/interfaces/users';
import { AUTH, AUTH_ADMIN, deleteUserEndpoint } from './auth/endpoints';
import { CreateEmployeeUserResponseType, GenerateReportResponseType } from './auth/responses';
import AxiosInstance from './AxiosInstance';
import { addDocument, getDocument, documentCostsList } from './documents/endpoints';
import { GetDocumentsCostsParameters } from './documents/parameters';
import { FILES, REPORT, TEMPORARY_FILE } from './files/endpoints';
import { GetFileResponseType, PostTemporaryFileResponse } from './files/responses';
import { addGrant, updateGrant } from './grants/endpoints';
import {
    deleteExpenseTypeEndpoint,
    deleteInstitutionEndpoint,
    EXPENSE_TYPES,
    FULL_INSTITUTIONS,
    getAllExpenseTypes,
    institutionLicenseEndpoint,
    institutionLicensePaymentEndpoint,
    INSTITUTIONS,
    updateExpenseTypeEndpoint,
    updateInstitutionEndpoint,
} from './institutions/endpoints';
import { ResponseWithPagination } from './types';
import {
    deleteUserFromInstitutionEndpoint,
    getUserEndpoint,
    updateUserEndpoint,
} from './user/endpoints';
import qs from 'qs';
import { GUS_DATA } from './gus/endpoints';

class HTTPService {
    private axios = new AxiosInstance();

    createAdminUser = (registerPostData: RegisterPostData) =>
        this.axios.post(AUTH_ADMIN, registerPostData);

    getUser = () => this.axios.get(AUTH);

    createDocument = (institutionId: Institution['id'], documentData: PostDocTypeDTO) =>
        this.axios.post(addDocument(institutionId), documentData);

    getDocument = (institutionId: Institution['id'], documentId: DocType['id']) =>
        this.axios.get<GetDocTypeDTO>(getDocument(institutionId, documentId));

    getDocumentsCostsList = (
        institutionId: Institution['id'],
        { month, year, expenseType, page, size, categories, sortData }: GetDocumentsCostsParameters,
    ) =>
        this.axios.get<ResponseWithPagination<DocCostDTO[]>>(documentCostsList(institutionId), {
            params: {
                month,
                year,
                expenseType,
                page,
                size,
                categories,
                sort: sortData ? `${sortData.columnKey},${sortData.direction}` : undefined,
            },
            paramsSerializer: (params) => qs.stringify(params, { indices: false }),
        });

    deleteDocument = (institutionId: Institution['id'], documentId: DocType['id']) =>
        this.axios.delete(getDocument(institutionId, documentId));

    updateDocument = ({
        documentData,
        documentId,
        institutionId,
    }: {
        documentData: PostDocTypeDTO;
        documentId: DocType['id'];
        institutionId: Institution['id'];
    }) => this.axios.put(getDocument(institutionId, documentId), documentData);

    addGrant = (institutionId: Institution['id'], grantData: GrantPostDto) =>
        this.axios.post(addGrant(institutionId), grantData);

    updateGrant = (
        institutionId: Institution['id'],
        { grantId, ...grantData }: GrantPostDto & { grantId: Grant['id'] },
    ) => this.axios.put(updateGrant(institutionId, grantId), grantData);

    getInstituteList = () => this.axios.get<InstitutionDto[]>(INSTITUTIONS);

    createInstitution = (institutionData: PostInstitutionDto[]) =>
        this.axios.post<InstitutionDto[]>(INSTITUTIONS, institutionData);

    editInstitution = (institutionData: UpdateInstitutionDto, institutionId: Institution['id']) =>
        this.axios.put<InstitutionDto>(updateInstitutionEndpoint(institutionId), institutionData);

    deleteInstitution = (institutionId: Institution['id']) =>
        this.axios.delete(deleteInstitutionEndpoint(institutionId));

    createInstitutionLicense = (institutionId: Institution['id']) =>
        this.axios.post(institutionLicenseEndpoint(institutionId));

    createNewInstitutionLicensePayment = (institutionId: Institution['id']) =>
        this.axios.post(institutionLicensePaymentEndpoint(institutionId));

    getBudgetSummary = (
        institutionId: Institution['id'],
        options: {
            month: string;
            year: string;
            categories: GetDocumentsCostsParameters['categories'];
        },
    ) =>
        this.axios.get<BudgetSummaryDto>(
            `/api/v1/institutions/${institutionId}/budgets/month-summary`,
            {
                params: options,
                paramsSerializer: (params) => qs.stringify(params, { indices: false }),
            },
        );

    getFullInstitutionsList = () => this.axios.get<FullInstitution[]>(FULL_INSTITUTIONS);

    createEmployeeUser = (userPostData: UserPostData) =>
        this.axios.post<CreateEmployeeUserResponseType>(AUTH, userPostData);

    deleteUser = (userId: UserDetailsType['id']) => this.axios.delete(deleteUserEndpoint(userId));

    deleteUserFromInstitution = ({ userId, institutionId }: DeleteUserFromInstitutionRequestDto) =>
        this.axios.delete(deleteUserFromInstitutionEndpoint(userId, institutionId));

    generateReport = (generateReportDto: GenerateReportDto, type: ReportType) =>
        this.axios.post<GenerateReportResponseType>(`${REPORT}/${type}`, generateReportDto, {
            responseType: type === 'csv' ? 'text' : 'arraybuffer',
        });

    editEmployeeUser = (
        updateUserRequestDto: UpdateUserRequestDto,
        userId: UserDetailsType['id'],
    ) => this.axios.put(updateUserEndpoint(userId), updateUserRequestDto);

    getEmployeeUser = (userId: UserDetailsType['id']) =>
        this.axios.get<UserDetailsType>(getUserEndpoint(userId));

    postTemporaryFile = (formData: FormData) =>
        this.axios.post<PostTemporaryFileResponse>(TEMPORARY_FILE, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

    getFile = (fileUri: string) =>
        this.axios.get<GetFileResponseType>(FILES, {
            params: { fileUri: fileUri },
            responseType: 'blob',
        });

    getGUSData = (regon: string) => this.axios.get(GUS_DATA, { params: { regon } });

    getExpenseTypeList = () => this.axios.get<ExpenseType[]>(EXPENSE_TYPES);

    getAllExpenseTypeList = (institutionId: InstitutionId) =>
        this.axios.get<string[]>(getAllExpenseTypes(institutionId));

    createExpenseType = (expenseTypeData: PostExpenseTypeDto) =>
        this.axios.post<ExpenseType>(EXPENSE_TYPES, expenseTypeData);

    editExpenseType = (expenseTypeData: UpdateExpenseTypeDto, expenseTypeId: ExpenseType['id']) =>
        this.axios.put<ExpenseType>(updateExpenseTypeEndpoint(expenseTypeId), expenseTypeData);

    deleteExpenseType = (expenseTypeId: ExpenseType['id']) =>
        this.axios.delete(deleteExpenseTypeEndpoint(expenseTypeId));
}

export default new HTTPService();
