import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import * as reducers from './reducers';
import { UsersState } from './state';

type Reducers = typeof reducers;

export const usersSliceName = 'users';

const initialState: UsersState = {
    addUserState: { loading: false },
    editUserState: { loading: false },
    deleteUserState: { loading: false },
    deleteUserFromInstitutionState: { loading: false },
    getSelectedUserState: { loading: false },
    selectedUser: null,
};

const slice = createSlice<UsersState, Reducers & { reset: () => UsersState }>({
    name: usersSliceName,
    initialState,
    reducers: {
        ...reducers,
        reset: () => initialState,
    },
});

export const { actions, reducer } = slice;

export const selectState = (state: RootState) => state[usersSliceName];
