import { Container, styled, TableCell, TableContainer, Typography } from '@mui/material';
import { colors } from 'consts/colors';

export const StyledInnerTableContainer = styled(TableContainer)(({ theme }) => ({
    paddingLeft: '3px',
    backgroundColor: theme.palette.table.background,
}));

export const StyledMainTableContainer = styled(TableContainer)(({ theme }) => ({
    padding: '12px 24px',
    backgroundColor: theme.palette.table.background,
    borderRadius: '16px 16px 0 0',
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: 0,
    color: theme.palette.table.contrastText,
    letterSpacing: '-0.4px',
    lineHeight: '22px',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'DM Sans',
    padding: '9px 8px !important',
}));

export const StyledInnerTableCell = styled(TableCell)(({ theme }) => ({
    border: 0,
    color: theme.palette.table.contrastText,
    letterSpacing: '-0.4px',
    lineHeight: '22px',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'DM Sans',
    padding: '5px 0 !important',
}));

export const StyledNameTypography = styled(Typography)({
    color: colors.secondaryText,
    lineHeight: '22px',
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: 'DM Sans',
});

export const UsersTabHeader = styled(Typography)({
    fontSize: '13px',
    marginBottom: '10px',
});

export const UsersTableContainer = styled(Container)({
    marginTop: '15px',
    padding: '20px 0 10px 0',
    borderTop: `1px solid ${colors.lightGray}`,
    borderBottom: `1px solid ${colors.lightGray}`,
});
