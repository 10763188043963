import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

export const MONTH_OFFSET = 1;

dayjs.extend(customParseFormat);

export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';
export const DOCUMENTS_LIST_SALE_DATE_FORMAT = 'DD.MM.YYYY';

export const getTodayDate = () => {
    const today = new Date();
    const month = today.getMonth() + MONTH_OFFSET;

    return { month: month.toString(), year: today.getFullYear().toString() };
};

export const getYearsArrayForSelect = () => {
    const thisYear = new Date().getFullYear();
    return Array.from(new Array(7), (val, index) => ({
        name: (thisYear + 2 - index).toString(),
        value: (thisYear + 2 - index).toString(),
    }));
};

export const getYearsWithMonthsArray = () => {
    const years = getYearsArrayForSelect();
    return years
        .map((year) =>
            Array.from({ length: 12 }, (_v, i) => {
                const day = dayjs(
                    `01-${(i + 1).toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                    })}-${year.value}`,
                    'DD-MM-YYYY',
                ).toDate();

                return { value: dayjs(day).toISOString(), name: dayjs(day).format('MMMM YYYY') };
            }).reverse(),
        )
        .flat();
};

export const parseManuallyTypedDate = (date?: Dayjs | Date | undefined): string | undefined => {
    if (date && !(date instanceof Date)) {
        date.hour(12);

        return dayjs(date).format(BACKEND_DATE_FORMAT);
    }

    if (date && date instanceof Date) {
        return dayjs(date).format(BACKEND_DATE_FORMAT);
    }

    return undefined;
};

export const getFirstDayOfTheYear = (year: string) => dayjs(`01-01-${year}`, 'DD-MM-YYYY');
export const getFirstDayOfDecemberInTheYear = (year: string) =>
    dayjs(`01-12-${year}`, 'DD-MM-YYYY');
