import { createSelector } from '@reduxjs/toolkit';
import { selectState } from '../slice';
import type { SettlementState } from '../state';

const selectGetExpenseTypeListState = createSelector(
    selectState,
    (state: SettlementState) => state.getExpenseTypeListState,
);

const selectExpenseTypeList = createSelector(
    selectState,
    (state: SettlementState) => state.expenseTypeList,
);
const selectAllExpenseTypeList = createSelector(
    selectState,
    (state: SettlementState) => state.allExpenseTypeList,
);
const selectAddExpenseTypeState = createSelector(
    selectState,
    (state: SettlementState) => state.addExpenseTypeState,
);
const selectEditExpenseTypeState = createSelector(
    selectState,
    (state: SettlementState) => state.editExpenseTypeState,
);
const selectDeleteExpenseTypeState = createSelector(
    selectState,
    (state: SettlementState) => state.deleteExpenseTypeState,
);

export {
    selectGetExpenseTypeListState,
    selectExpenseTypeList,
    selectAllExpenseTypeList,
    selectAddExpenseTypeState,
    selectEditExpenseTypeState,
    selectDeleteExpenseTypeState,
};
