import { Icon, Link, styled, Typography, Box } from '@mui/material';

export const FooterContainer = styled(Box)`
    display: flex;

    flex-wrap: wrap;
    width: 100%;

    background-color: #383a47;

    padding-top: 23px;
    padding-bottom: 84px;
    padding-left: 128px;
    padding-right: 300px;
`;
export const FooterLogoIconContainer = styled(Box)`
    display: flex;
    align-self: center;
    margin-left: 23px;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 40px;
    margin-right: auto;
`;
export const Logo = styled(Icon)`
    text-align: center;
    width: 161px;
    height: auto;
`;
export const FooterColumn = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding-right: 30px;
    padding-left: 30px;

    margin-top: 20px;
`;
export const FooterColumnHeader = styled(Typography)`
    color: white;
    line-height: 26px;
    margin-bottom: 20px;
`;
export const FooterColumnLink = styled(Link)`
    color: white;
    line-height: 26px;
    margin-bottom: 12px;
    font-weight: 400;
    text-align: left;
    &:hover {
        cursor: pointer;
    }
`;
export const CompanyColumnLink = styled(FooterColumnLink)`
    font-size: 14px;
`;
export const ContactColumnRow = styled(Box)`
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
`;
export const ContactColumnIcon = styled(Icon)`
    text-align: center;
    width: 24px;
    margin-right: 12px;
`;
export const AddressColumn = styled(Box)`
    display: flex;
    flex-direction: column;
`;
export const ContactColumnText = styled(Typography)`
    color: white;
    line-height: 26px;
`;
export const IndentColumnText = styled(ContactColumnText)`
    font-weight: 400;
`;
