import React, { useState } from 'react';
import { Stack, Typography, styled } from '@mui/material';
import { useAppTranslation } from 'utils/hooks/translation';
import { createInstituteHash } from 'utils/functions/string';
import { maxFilesAmountValidator, maxFilesSizeValidator } from 'utils/functions/validators';
import { MAX_FILE_SIZE, MAX_FILES_AMOUNT } from 'consts/file';
import { AppButton, FileDropzone } from '../common';
import { DocumentForm } from './DocumentForm';
import { ExpenseForm } from './ExpenseForm';
import { useFormContext, UseFormSetValue } from 'react-hook-form';
import { DocTypeFromForm } from 'utils/interfaces/document';
import TotalExpensesCard from './TotalExpensesCard/TotalExpensesCard';
import { getTotalExpensesFromExpenseDtos } from 'utils/functions/documents';
import {useSnackBar} from "../../contexts/SnackbarContext/SnackbarContext";

const ButtonWithWidth = styled(AppButton)`
    min-width: 40%;
`;

type Props = {
    errors: any;
    documentStatus: string;
    unregister: any;
    goBack: () => void;
    editMode?: boolean;
    setValue: UseFormSetValue<DocTypeFromForm>;
};

export const InvoiceForm = ({
    errors,
    documentStatus,
    unregister,
    goBack,
    editMode,
    setValue,
}: Props) => {
    const { t: settlementT } = useAppTranslation('settlement');
    const { t: commonT } = useAppTranslation('common');
    const [fileDropzoneLoading, setFileDropzoneLoading] = useState(false);
    const { showSnackBar } = useSnackBar();

    const { watch } = useFormContext();

    const expenseDtos = watch ? watch('expenseDtos') : undefined;

    const addExpenseToList = () =>
        setValue('expenseDtos', { ...expenseDtos, [createInstituteHash()]: {} });

    const removeExpenseFromList = (expenseHash: string) => {
        unregister(`expenseDtos.${expenseHash}`);
    };

    return (
        <Stack gap="24px">
            <DocumentForm errors={errors} documentStatus={documentStatus} />
            <Typography variant="h5">{settlementT('addDocument.itemTitle')}</Typography>
            {expenseDtos &&
                Object.keys(expenseDtos).map((id) => (
                    <ExpenseForm
                        key={id}
                        errors={errors}
                        id={id}
                        disabledRemove={Object.keys(expenseDtos).length <= 1}
                        onRemoveClick={removeExpenseFromList}
                    />
                ))}
            <AppButton variant="outlined" onClick={addExpenseToList}>
                {settlementT('addDocument.addNextExpense')}
            </AppButton>

            <TotalExpensesCard totalExpenses={getTotalExpensesFromExpenseDtos(expenseDtos)} />

            <Typography variant="h5">{settlementT('addDocument.addInvoiceTitle')}</Typography>
            <FileDropzone
                rules={{
                    validate: {
                        maxSize: maxFilesSizeValidator(settlementT('addDocument.errors.maxSize'))(
                            MAX_FILE_SIZE,
                        ),
                        maxAmount: maxFilesAmountValidator(
                            settlementT('addDocument.errors.maxAmount'),
                        )(MAX_FILES_AMOUNT),
                    },
                }}
                multiple
                name="attachments"
                loading={fileDropzoneLoading}
                setLoading={setFileDropzoneLoading}
                showSnackBar={showSnackBar}
                commonT={commonT}
                settlementT={settlementT}
            />

            <Stack direction="row" justifyContent="space-around">
                <ButtonWithWidth onClick={goBack} variant="outlined">
                    {commonT('button.cancel')}
                </ButtonWithWidth>
                <ButtonWithWidth type="submit" disabled={fileDropzoneLoading}>
                    {editMode ? commonT('button.saveChanges') : commonT('button.save')}
                </ButtonWithWidth>
            </Stack>
        </Stack>
    );
};
