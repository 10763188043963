import { DocCostCategoriesFilter } from '../utils/interfaces/document';

export const DOC_COST_CATEGORIES: DocCostCategoriesFilter = [
    {
        name: 'Dotacja oświatowa',
        value: 'EDUCATIONAL',
        selectedByDefault: true,
        subcategories: [
            {
                name: 'Dotacja podstawowa',
                value: 'EDUCATIONAL_HEALTHY_CHILDREN',
                selectedByDefault: true,
            },
            {
                name: 'Kształcenie specjalne',
                value: 'EDUCATIONAL_DISABLED_CHILDREN',
                selectedByDefault: true,
            },
        ],
    },
    {
        name: 'WWR',
        value: 'EARLY_DEVELOPMENT_SUPPORT',
    },
];
