import { createSelector } from '@reduxjs/toolkit';
import { selectState } from '../slice';
import { ReportsState } from '../state';

const selectGenerateReportState = createSelector(
    selectState,
    (state: ReportsState) => state.generateReportState,
);

export { selectGenerateReportState };
