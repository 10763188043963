import { FormControlLabel, styled, Typography } from '@mui/material';
import { colors } from 'consts/colors';

export const StyledCategoryTypography = styled(Typography)({
    fontWeight: 500,
});

export const StyledRadioLabelTypography = styled(Typography)({
    fontWeight: 400,
    fontFamily: 'DM Sans',
    fontSize: '15px',
    lineHeight: '22px',
    letterSpacing: '-0.4px',
    color: colors.black100,
});
export const StyledFormControlLabel = styled(FormControlLabel)({
    alignSelf: 'flex-start',
});
