import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FormHelperText } from '@mui/material';

type PasswordInputProps = {
    label: string;
    name: string;
    helperText?: string;
} & Pick<React.ComponentProps<typeof Controller>, 'rules' | 'shouldUnregister' | 'defaultValue'> &
    React.ComponentProps<typeof OutlinedInput>;

const PasswordInput: React.FC<PasswordInputProps> = ({
    label,
    name,
    rules,
    shouldUnregister,
    fullWidth,
    defaultValue = '',
    helperText,
    ...inputProps
}) => {
    const { control } = useFormContext();

    const [passwordVisible, setPasswordVisible] = useState(false);

    return (
        <FormControl variant="outlined" fullWidth={fullWidth}>
            <Controller
                name={name}
                rules={rules}
                render={(fieldProps) => (
                    <>
                        <InputLabel error={inputProps.error}>{label}</InputLabel>
                        <OutlinedInput
                            {...fieldProps.field}
                            {...inputProps}
                            type={passwordVisible ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        disabled={inputProps?.disabled}
                                        aria-label="toggle password visibility"
                                        onClick={() => setPasswordVisible(!passwordVisible)}
                                        edge="end"
                                    >
                                        {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label={label}
                        />
                        {helperText && <FormHelperText error>{helperText}</FormHelperText>}
                    </>
                )}
                defaultValue={defaultValue}
                control={control}
            />
        </FormControl>
    );
};

export { PasswordInput };
