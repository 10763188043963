import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Stack, styled, Typography } from '@mui/material';
import { useAppTranslation } from 'utils/hooks/translation';
import { useErrorTranslation } from 'utils/functions/error';
import Layout from 'components/Layout/Layout';
import { Institution } from 'utils/interfaces/institution';
import { MONTHS } from 'consts/date';
import { selectors } from 'redux/slices/app';
import { getYearsArrayForSelect } from 'utils/functions/dates';
import { selectors as settlementSelectors } from 'redux/slices/settlement';
import { AppButton, FormError, Input, Select } from '../common';
import { grantCategoryOptions } from 'utils/functions/options';
import { isNotGreaterThanNumberValidator } from 'utils/functions/validators';
import { useFormContext } from 'react-hook-form';

const ButtonWithWidth = styled(AppButton)`
    min-width: 40%;
`;

type Props = {
    errors: any;
    goBack: () => void;
    backendError: string | undefined;
    instituteList: Institution[] | null;
};

const createOptionsFromInstituteList = (instituteList: Institution[]) =>
    instituteList.map((institute) => ({ name: institute.name, value: institute.id }));

const yearsOptions = getYearsArrayForSelect();

const GrantForm = ({ errors, goBack, backendError, instituteList }: Props) => {
    const { t: settlementT } = useAppTranslation('settlement');
    const { t: commonT } = useAppTranslation('common');

    const { watch } = useFormContext();

    const { translateError } = useErrorTranslation('settlement:addGrant');
    const { year, month } = useSelector(selectors.selectDate);
    const selectedInstitution = useSelector(settlementSelectors.selectSelectedInstitution);

    const selectedCategory = watch('category');
    const grossAmount = watch('grossAmount');

    const instituteOptionsFactor = useMemo(
        () => instituteList?.length && createOptionsFromInstituteList(instituteList),
        [instituteList],
    );

    return (
        <Stack gap="24px">
            <Typography variant="h5">{settlementT('addGrant.subtitle')}</Typography>
            <Layout.Container>
                <Select
                    rules={{ required: commonT('form.required') }}
                    name="instituteId"
                    label={settlementT('addGrant.institution')}
                    options={instituteOptionsFactor || []}
                    error={errors?.instituteId}
                    helperText={errors?.instituteId?.message}
                    defaultValue={selectedInstitution}
                />
                <Select
                    rules={{ required: commonT('form.required') }}
                    name="year"
                    label={settlementT('addGrant.year')}
                    options={yearsOptions}
                    error={errors?.year}
                    helperText={errors?.year?.message}
                    defaultValue={year}
                />
                <Select
                    rules={{ required: commonT('form.required') }}
                    name="month"
                    label={settlementT('addGrant.month')}
                    options={MONTHS}
                    error={errors?.month}
                    helperText={errors?.month?.message}
                    defaultValue={month}
                />
                <Select
                    rules={{ required: commonT('form.required') }}
                    name="category.type"
                    label={settlementT('addGrant.category')}
                    options={grantCategoryOptions}
                    error={errors?.category?.type}
                    helperText={errors?.category?.type?.message}
                    defaultValue={grantCategoryOptions[0].value}
                />
                <Input
                    rules={{ required: commonT('form.required') }}
                    name="grossAmount"
                    label={settlementT('addGrant.grossAmount')}
                    error={!!errors?.grossAmount}
                    helperText={errors?.grossAmount?.message}
                    type="number"
                    lang="pl"
                />
                {selectedCategory?.type === 'FOR_EDUCATION' && (
                    <Input
                        rules={{
                            required: commonT('form.required'),
                            validate: {
                                isNotGreaterThanNumber: (value) =>
                                    isNotGreaterThanNumberValidator(
                                        Number(grossAmount),
                                        settlementT(
                                            'addGrant.errors.canNotBeGreaterThanAbsoluteGrossAmount',
                                        ),
                                    )(value),
                            },
                        }}
                        name="category.amount"
                        label={settlementT('addGrant.grossAmountForChildrenWithCertificate')}
                        error={!!errors?.category?.amount}
                        helperText={errors?.category?.amount?.message}
                        type="number"
                        lang="pl"
                    />
                )}
                <FormError error={backendError ? translateError(backendError) : null} />
            </Layout.Container>
            <Stack direction="row" justifyContent="space-around">
                <ButtonWithWidth onClick={goBack} variant="outlined">
                    {commonT('button.cancel')}
                </ButtonWithWidth>
                <ButtonWithWidth type="submit">{commonT('button.save')}</ButtonWithWidth>
            </Stack>
        </Stack>
    );
};

export { GrantForm };
