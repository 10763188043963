import i18next, { Resource, ResourceLanguage, InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import dayjs from 'dayjs';

import LanguageDetector from 'i18next-browser-languagedetector';
import { plAuth, plCommon, plReports, plSettings, plSettlement } from 'consts/locales/pl';

require('dayjs/locale/pl');

export const DEFAULT_NAMESPACE = 'common';

export interface LanguageResources extends ResourceLanguage {
    common: typeof plCommon;
    auth: typeof plAuth;
    settlement: typeof plSettlement;
    settings: typeof plSettings;
    reports: typeof plReports;
}

const plResources: LanguageResources = {
    common: plCommon,
    auth: plAuth,
    settlement: plSettlement,
    settings: plSettings,
    reports: plReports,
};

const resources: Resource = {
    pl: plResources,
};

export const translationNamespaces = [
    'common',
    'auth',
    'settlement',
    'settings',
    'reports',
] as const;

const initOptions: InitOptions = {
    compatibilityJSON: 'v3',
    fallbackLng: ['pl'],
    nonExplicitSupportedLngs: true,
    ns: translationNamespaces,
    defaultNS: DEFAULT_NAMESPACE,
    resources: resources,
    debug: false,
};

i18next.use(LanguageDetector).use(initReactI18next).init(initOptions);
dayjs.locale('pl');
