import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import CheckboxMui from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { Box, FormHelperText } from '@mui/material';

type Props = {
    name: string;
    label: React.ReactNode;
    rules: any;
    helperText?: string;
} & Pick<React.ComponentProps<typeof Controller>, 'rules' | 'shouldUnregister' | 'defaultValue'> &
    React.ComponentProps<typeof CheckboxMui>;

const Checkbox = ({
    name,
    label,
    rules,
    shouldUnregister,
    defaultValue,
    helperText,
    ...checkboxProps
}: Props) => {
    const { control } = useFormContext();

    return (
        <Box>
            <FormControlLabel
                label={<Typography variant="body2">{label}</Typography>}
                control={
                    <Controller
                        name={name}
                        control={control}
                        rules={rules}
                        shouldUnregister={shouldUnregister}
                        defaultValue={defaultValue}
                        render={({ field }) => (
                            <CheckboxMui
                                checked={field.value ?? false}
                                {...field}
                                {...checkboxProps}
                            />
                        )}
                    />
                }
            />
            {helperText && <FormHelperText error>{helperText}</FormHelperText>}
        </Box>
    );
};

export { Checkbox };
