import { createTheme, CSSInterpolation } from '@mui/material';
import { plPL } from '@mui/material/locale';
import { colors } from 'consts/colors';

declare module '@mui/material/styles' {
    interface Palette {
        text3: Palette['primary'];
    }

    interface PaletteOptions {
        text3?: PaletteOptions['primary'];
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        text3: true;
    }
    interface ButtonPropsVariantOverrides {
        inline: true;
    }
}

declare module '@mui/material/styles' {
    interface TypeText {
        tertiary: string;
        error: string;
        grey60: string;
        contrastText: string;
        grey100: string;
        contentBody: string;
    }

    interface Palette {
        table: {
            headerBg: string;
            title: string;
            contrastText: string;
            background: string;
            border: string;
        };
    }

    interface PaletteOptions {
        table: {
            headerBg: string;
            title: string;
            contrastText: string;
            background: string;
            border: string;
        };
    }
}

const basicButtonStyle: CSSInterpolation = {
    fontFamily: 'DM Sans',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    textTransform: 'none',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
};

const outlinedButtonStyle: CSSInterpolation = {
    ...basicButtonStyle,
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '-0.2px',
};

const inlineButtonStyle: CSSInterpolation = {
    padding: 0,
    lineHeight: 1,
    fontWeight: 700,
    textTransform: 'none',
};

const appTheme = createTheme(
    {
        palette: {
            primary: {
                main: colors.primary,
                light: 'rgba(121,123,137,0.05)',
                contrastText: colors.neutral,
            },
            secondary: {
                main: colors.secondary,
                contrastText: colors.neutral,
            },
            text3: {
                main: colors.text3,
                contrastText: colors.neutral,
            },
            text: {
                primary: '#1B1C20',
                grey60: 'rgba(23, 23, 23, 0.6)',
                secondary: '#797B89',
                tertiary: '#7D7D7D',
                error: '#fd6003',
                contrastText: '#ffffff',
                contentBody: '#383A47',
            },
            table: {
                title: '#8F92A1',
                headerBg: '#8F92A1',
                contrastText: '#171717',
                background: '#FFFFFF',
                border: '#C4C4C4',
            },
            error: {
                main: '#fd6003',
            },
        },
        components: {
            MuiButton: {
                variants: [
                    {
                        props: { variant: 'inline' },
                        style: inlineButtonStyle,
                    },
                ],
                styleOverrides: {
                    contained: basicButtonStyle,
                    outlined: outlinedButtonStyle,
                    text: { ...basicButtonStyle, fontWeight: 700, color: colors.secondary },
                },
            },
            MuiTypography: {
                styleOverrides: {
                    h2: ({ theme }) => ({
                        fontFamily: 'DM Sans',
                        fontWeight: 700,
                        fontSize: '30px',
                        lineHeight: '36px',
                        color: theme.palette.text.primary,
                        letterSpacing: '-0.4px',
                    }),
                    h3: ({ theme }) => ({
                        fontFamily: 'DM Sans',
                        fontWeight: 700,
                        fontSize: '30px',
                        lineHeight: '36px',
                        color: theme.palette.text.primary,
                    }),
                    h4: ({ theme }) => ({
                        fontFamily: 'DM Sans',
                        fontWeight: 700,
                        fontSize: '18px',
                        lineHeight: '26px',
                        color: theme.palette.text.tertiary,
                    }),
                    h5: ({ theme }) => ({
                        fontFamily: 'DM Sans',
                        fontWeight: 700,
                        fontSize: '16px',
                        lineHeight: '24px',
                        color: theme.palette.text.primary,
                    }),

                    body1: ({ theme }) => ({
                        fontFamily: 'Roboto',
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '21px',
                        color: theme.palette.text.secondary,
                        letterSpacing: '-0.2px',
                    }),
                    body2: ({ theme }) => ({
                        fontFamily: 'DM Sans',
                        fontWeight: 400,
                        fontSize: '12px',
                        lineHeight: '20px',
                        color: theme.palette.text.secondary,
                    }),
                },
            },
            MuiBackdrop: {
                styleOverrides: {
                    root: {
                        backgroundColor: colors.backdrop,
                        color: colors.primary,
                    },
                },
            },
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        maxWidth: '603px',
                    },
                },
            },
            MuiLink: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        color: theme.palette.primary.main,
                    }),
                },
            },
            MuiSelect: {
                styleOverrides: {
                    select: {
                        textTransform: 'capitalize',
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        textTransform: 'capitalize',
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        textTransform: 'capitalize',
                        lineHeight: '22px',
                        fontSize: '14px',
                        fontWeight: 400,
                        fontFamily: 'DM Sans',
                        color: colors.secondary,
                        '&.Mui-selected': {
                            fontWeight: 700,
                            lineHeight: '24px',
                            color: theme.palette.primary.main,
                        },
                    }),
                },
            },
            MuiTabs: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        '& .MuiTabs-indicator': {
                            backgroundColor: theme.palette.primary.main,
                        },
                    }),
                },
            },
        },
    },
    plPL,
);

export default appTheme;
